import React, { useEffect, useState, Fragment } from "react";

const ButtonUp = (props) => {
  const [pixelsToShowBtn, setPixelsToShowBtnp] = useState(300);
  const [isScrollBtnShown, setIsScrollBtnShown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (pixelsToShowBtn < window.scrollY) setIsScrollBtnShown(true);
      else setIsScrollBtnShown(false);
    };
    if (props.pixelsToShowBtn) setPixelsToShowBtnp(props.pixelsToShowBtn);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [pixelsToShowBtn, isScrollBtnShown, props.pixelsToShowBtn]);

  return (
    <div onClick={scrollToTop} className={`button-up-wrapper${!isScrollBtnShown ? " hide" : ""}`}>
      <i className="material-icons color-white mt-10">arrow_upward</i>
    </div>
  );
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export default ButtonUp;
