import React, { useState, Fragment, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button, MenuItem } from "@material-ui/core";
// import IframeComp from "./IframeComp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",

    marginLeft: "auto"
  },
  paper: {
    position: "absolute",
    color: "transparent",

    backgroundColor: "transparent",
    borderRadius: "none",
    outline: "none",
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function VideoManager(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#11cb5f",
          color: "white",
          height: "50px",
          fontSize: "20px"
        }}
        onClick={handleOpen}
      >
        <span style={{ marginLeft: "5px", marginTop: "5px" }}>{props.btn_logo}</span>
        {props.btn_title}
      </Button>

      <Modal
        open={open}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fragment>
          <div className={classes.paper}>
            <video
              className="how_to_video_frame"
              controls
              controlsList="nodownload"
              //   style={{marginRight: "100px"}}
              autoPlay={true}
            >
              <source src={props.video_link} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <IframeComp embedId={props.video_link} /> */}
          </div>
        </Fragment>
      </Modal>
    </div>
  );
}
