import React, { useState } from "react";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import { PlusOne, DeleteForever } from "@material-ui/icons";
import Swal from "sweetalert2";
import { removeAllNonNumberChars } from "../../../utils";

export default function AdditionalServices({ additionServices, updateAdditionServices }) {
  const [serviceName, setServiceName] = useState("");
  const [serviceValue, setServiceValue] = useState("");

  const onAdditionalServiceAdded = () => {
    if (!serviceName || !serviceValue) return Swal.fire("", "יש למלא שם שרות ועלות", "warning");

    const newAdditionServices = additionServices ? [...additionServices] : [];
    newAdditionServices.push({ name: serviceName, value: parseInt(serviceValue) });

    updateAdditionServices(newAdditionServices);
    setServiceName("");
    setServiceValue("");
  };

  const onAdditionalServiceRemoved = (index) => {
    const newAdditionServices = [...additionServices];
    newAdditionServices.splice(index, 1);

    updateAdditionServices(newAdditionServices);
  };

  return (
    <Grid item>
      <h3>שרותים \ תשלומים נוספים</h3>
      <Box className="popup-form-row">
        <TextField
          fullWidth
          variant="outlined"
          InputProps={{ style: { backgroundColor: "white" } }}
          label="שם השרות"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
        />
        <TextField
          fullWidth
          variant="outlined"
          InputProps={{ style: { backgroundColor: "white" } }}
          label="עלות"
          value={serviceValue}
          onChange={(e) => setServiceValue(removeAllNonNumberChars(e.target.value))}
        />
      </Box>
      <Box className="right">
        {additionServices.map((additionService, index) => {
          return (
            <div className="flex-reg mt-10" key={additionService.name + index}>
              <div>{additionService.name}</div>
              <div className="mr-10">,{additionService.value}</div>
              <DeleteForever onClick={() => onAdditionalServiceRemoved(index)} className="mr-10 pointer lightgray-brand" />
            </div>
          );
        })}
        <div>
          <IconButton onClick={onAdditionalServiceAdded}>
            <PlusOne />
          </IconButton>
        </div>
      </Box>
    </Grid>
  );
}
