import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";

const actionTypes = { UPDATE: "UPDATE", DELETE: "DELETE", NEW: "NEW" };

export default function MaterialTableComponent(props) {
  const [state, setState] = React.useState({
    columns: props.columns,
    data: props.data
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, data: props.data }));
  }, [props.data]);

  let neededActions = null;
  if (props.redirectToEditVisa || props.redirectToSpecificUser || props.performResendEmailConf || props.redirectToAgentsGroups) {
    neededActions = [];
    if (props.redirectToEditVisa) {
      neededActions.push((rowData) => ({
        icon: "edit",
        tooltip: "עריכה",
        onClick: (event, rowData) => {
          props.redirectToEditVisa(rowData, event);
        },
        hidden: rowData.status !== "AGENT_PAID_AND_FILLS"
      }));
    }
    if (props.redirectToSpecificUser) {
      neededActions.push({
        icon: "exit_to_app",
        tooltip: "כניסה",
        onClick: (event, rowData) => {
          props.redirectToSpecificUser(rowData, event);
        }
      });
    }
    if (props.performResendEmailConf) {
      neededActions.push({
        icon: "lock_open",
        tooltip: "שלח מייל לאיפוס סיסמה",
        onClick: (event, rowData) => {
          props.performResendEmailConf(rowData, event);
        }
      });
    }
    if (props.agentType === "group") {
      neededActions.push({
        icon: "exit_to_app",
        tooltip: "Groups",
        onClick: (event, rowData) => {
          props.redirectToAgentsGroups(rowData, event);
        }
      });
    }
  }

  return (
    <MaterialTable
      components={{ Pagination: PatchedPagination }}
      title={props.tableTitle}
      columns={state.columns}
      data={state.data}
      options={props.options ? props.options : {}}
      localization={{
        pagination: {
          labelDisplayedRows: "{from}-{to} מתוך {count}",
          labelRowsSelect: "כמות רשומות",
          firstTooltip: "עמוד ראשון",
          lastTooltip: "עמוד אחרון",
          nextTooltip: "עמוד הבא",
          previousTooltip: "עמוד קודם"
        },
        toolbar: {
          nRowsSelected: "{0} row(s) selected",
          searchPlaceholder: "חיפוש"
        },
        header: {
          actions: ""
        },
        body: {
          emptyDataSourceMessage: props.noRecordsYetMsg || "No records to display",
          filterRow: {
            filterTooltip: "Filter"
          },
          addTooltip: "הוסף",
          editRow: { deleteText: "למחוק?" }
        }
      }}
      onRowClick={(event, rowData, togglePanel) => {
        if (props.openPopup) props.openPopup(rowData);
      }}
      // localization={{ body: { editRow: { deleteText: "Customized Delete Message" } } }}
      actions={neededActions}
      editable={{
        onRowAdd: !props.disableAdding
          ? (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  props.onDataChanged(newData, actionTypes.NEW, props.agentType).then(
                    (newId) => {
                      resolve();
                      setState((prevState) => {
                        const data = [...prevState.data];
                        if (newId) newData["_id"] = newId;
                        data.push(newData);
                        return { ...prevState, data };
                      });
                    },
                    () => {
                      resolve();
                    }
                  );
                }, 600);
              })
          : null,

        onRowUpdate: props.onDataChanged
          ? (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  if (oldData) {
                    props.onDataChanged(newData, actionTypes.UPDATE).then(
                      () => {
                        resolve();
                        setState((prevState) => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      },
                      () => {
                        resolve();
                      }
                    );
                  }
                }, 600);
              })
          : null,
        onRowDelete: props.allowedDelete
          ? (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  props.onDataChanged(oldData, actionTypes.DELETE).then(
                    () => {
                      resolve();
                      setState((prevState) => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    },
                    () => {
                      resolve();
                    }
                  );
                }, 600);
              })
          : null
      }}
    />
  );
}

function PatchedPagination(props) {
  const { ActionsComponent, onChangePage, onChangeRowsPerPage, ...tablePaginationProps } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subProps) => {
        const { onPageChange, ...actionsComponentProps } = subProps;
        return <ActionsComponent {...actionsComponentProps} onChangePage={onPageChange} />;
      }}
    />
  );
}
