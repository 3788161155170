import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { Fragment } from "react";
import { setVisaForEdit } from "../../../Actions/loggedinActions";

function NewVisaPopup(props) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [toOpts, setToOpts] = useState(null);
  const [visaType, setType] = useState(null);
  const [visaTypeOpts, setVisaTypeOpts] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientPassportNum, setClientPassportNum] = useState(null);
  const [agentGivenName, setAgentGivenName] = useState(null);
  const [selectedVisa, setSelectedVisa] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [whoFills, setWhoFills] = useState(null);
  const [isExtraInfoOpen, setIsExtraInfoOpen] = useState(false);
  const [offline, setOffline] = useState(false);
  const [messengerInfo, setMessengerInfo] = useState({});

  useEffect(() => {
    setFrom(null);
    setTo(null);
    setType(null);
    setClientEmail(null);
    setClientPassportNum(null);
    setAgentGivenName(null);
    setWhoFills(null);
    setMessengerInfo({});
    setOffline(false);
  }, [props.isOpen]);

  return (
    <div>
      <Dialog open={props.isOpen} style={{ zIndex: 999 }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="primary-color">
          יצירת ויזה חדשה ללקוח
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            className="auto0 custom-autocomplete sm-90-per inline-block"
            disabled={toOpts && toOpts[0] ? false : true}
            options={toOpts || []}
            value={to}
            style={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label="יעד" variant="outlined" />}
            onChange={(event, newInputValue) => {
              if (newInputValue) {
                var visaTypeOnly = props.allFromTo.filter((item) => item.to === newInputValue && item.from === from);

                setVisaTypeOpts(visaTypeOnly);
              } else {
                setVisaTypeOpts([]);
              }
              setTo(newInputValue);
              setType(null);
              setSelectedVisa(null);
            }}
          />
          <div className="inline-block ml-10 remove-space-sm">
            <Autocomplete
              className="auto0 custom-autocomplete sm-90-per inline-block"
              options={props.fromOnly}
              style={{ width: 250 }}
              value={from}
              renderInput={(params) => <TextField {...params} label="מוצא" variant="outlined" />}
              onChange={(event, newInputValue) => {
                if (newInputValue) {
                  var toOnly = props.allFromTo
                    .filter((item) => item.from === newInputValue)
                    .filter((v, i, a) => a.findIndex((t) => t.to === v.to) === i)
                    .map((item) => item.to);

                  setToOpts(toOnly);
                } else {
                  setToOpts([]);
                }
                setFrom(newInputValue);
                setTo(null);
                setType(null);
                setSelectedVisa(null);
              }}
            />
          </div>

          <div className="left mt-20">
            <Autocomplete
              disabled={visaTypeOpts && visaTypeOpts[0] ? false : true}
              value={visaType}
              className="auto0  custom-autocomplete sm-90-per inline-block"
              options={visaTypeOpts || []}
              getOptionLabel={(option) => `${option.visaTitle} ${option.stayAllowance} ${option.visaEntries}`}
              style={{ verticalAlign: 54, width: 510 }}
              renderInput={(params) => <TextField {...params} label="סוג ויזה" variant="outlined" />}
              onChange={(event, newInputValue) => {
                if (newInputValue) {
                  var visaSelected = props.allFromTo.filter(
                    (item) => item.to === to && item.from === from && item.visaType === newInputValue.visaType
                  )[0];
                  setType(newInputValue);
                  setSelectedVisa(visaSelected);
                  if (visaSelected.downloadVisa) {
                    setOffline(true);
                  } else {
                    setOffline(false);
                  }
                } else {
                  setType(null);
                  setSelectedVisa(null);
                }
              }}
            />
          </div>

          {selectedVisa ? (
            <Fragment>
              {!offline ? (
                <>
                  <hr />
                  <div className="right rtl-layout mt-sm-20">
                    <FormControl className="remove-margin" component="fieldset">
                      <FormLabel component="legend">מי הולך למלא את נתוני הויזה?</FormLabel>
                      <RadioGroup row name="whoFills">
                        <FormControlLabel
                          className="inline-block"
                          value="agentFills"
                          control={<Radio />}
                          label="אתה ממלא את נתוני הויזה"
                          onChange={() => {
                            setWhoFills("agentFills");
                          }}
                        />
                        <FormControlLabel
                          className="inline-block"
                          value="clientFills"
                          control={<Radio />}
                          label="הלקוח שלך ממלא"
                          onChange={() => {
                            setWhoFills("clientFills");
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </>
              ) : (
                <>
                  <h4 className="center" style={{ color: "red" }}>
                    בבקשה להזין את כתובת המדויקת להזמנת שליח.
                  </h4>
                  <div className="flex mt-10 flex-wrap">
                    <TextField onChange={(e) => onChangeOfMessenger("city", e.target.value)} label="עיר" variant="outlined" />
                    <TextField
                      onChange={(e) => onChangeOfMessenger("houseNum", e.target.value)}
                      label="מספר בית"
                      type="number"
                      variant="outlined"
                      style={{ width: "100px" }}
                    />
                    <TextField onChange={(e) => onChangeOfMessenger("street", e.target.value)} label="רחוב" variant="outlined" />
                    <TextField
                      onChange={(e) => onChangeOfMessenger("phoneForMessenger", e.target.value)}
                      label="מספר טלפון"
                      variant="outlined"
                      style={{ marginTop: "20px" }}
                      type="number"
                    />
                  </div>
                </>
              )}

              {whoFills === "clientFills" ? (
                <div className="right mt-10 rtl-layout">
                  <hr />
                  <div className="client-fills-fields">
                    <TextField
                      onChange={(e) => {
                        setClientEmail(e.target.value);
                      }}
                      label='דוא"ל של הלקוח'
                    />
                  </div>
                  <div className="client-fills-fields">
                    <TextField
                      onChange={(e) => {
                        setClientPassportNum(e.target.value);
                      }}
                      label="מס דרכון לקוח"
                    />
                  </div>
                  <div className="client-fills-fields">
                    <TextField
                      onChange={(e) => {
                        setAgentGivenName(e.target.value);
                      }}
                      label="שם לקוח"
                    />
                  </div>
                </div>
              ) : null}
              <div className="mt-10 right rtl-layout">
                <hr />
                <div className="prices-con inline-block">
                  מחיר ויזה:
                  <b> {selectedVisa.visaPrice}</b>
                  <div className="vertical-sep"></div>
                  שירות:
                  <b> {selectedVisa.visaService}</b>
                </div>
                {/* {props.isShovarRequired ? ( */}
                <div className="inline-block">
                  <label htmlFor="file-input" className="upload-file-btn ml-10 mr-10">
                    העלאת שובר
                    <i className="material-icons attach-icon-align">attachment</i>
                  </label>
                  {selectedFile ? selectedFile.name : ""}
                  <input
                    id="file-input"
                    style={{
                      visibility: "hidden",
                      width: 0,
                      overflow: "hidden"
                    }}
                    type="file"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                  />
                </div>
                {/* ) : null} */}
                <div className="mt-20">
                  <Button
                    onClick={() => {
                      confirmAndClose();
                    }}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    {offline ? "הזמן שליח" : "ייצר ויזה"}
                    <i className="material-icons width40">check</i>
                  </Button>
                </div>

                <div className="center pointer primary-color">
                  <div onClick={() => setIsExtraInfoOpen(!isExtraInfoOpen)} className="inline-block">
                    מידע נוסף על הויזה
                    {isExtraInfoOpen ? <ArrowUpward /> : <ArrowDownward />}
                  </div>
                </div>
                {isExtraInfoOpen ? (
                  <div>
                    <hr className="mb-40" />
                    {ReactHtmlParser(selectedVisa.freeTxt)}
                  </div>
                ) : null}
              </div>
            </Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function onChangeOfMessenger(field, val) {
    const messengerAddress = { ...messengerInfo };
    messengerAddress[field] = val;
    setMessengerInfo(messengerAddress);
  }

  function confirmAndClose() {
    if (!from || !to || !visaType || (!whoFills && !offline)) return;
    // if (!selectedFile && props.isShovarRequired) {
    //   Swal.fire("לא הועלה שובר לויזה", "", "warning");
    //   return;
    // }
    if (from === to) return;
    if (whoFills === "clientFills" && (!clientPassportNum || !clientEmail || !agentGivenName)) {
      Swal.fire("הלקוח ממלא את נתוני הויזה", 'יש להכניס דרכון ודוא"ל של הלקוח', "warning");
      return;
    }

    var visaData = {
      from,
      to,
      visaType: visaType.visaType,
      selectedFile
    };
    if (offline) visaData.sendMessenger = true;
    if (whoFills) visaData.whoFills = whoFills;
    else {
      visaData = { ...visaData, ...messengerInfo };
      visaData.whoFills = "messenger";
    }
    if (whoFills === "clientFills") {
      visaData.clientPassportNum = clientPassportNum;
      visaData.clientEmail = clientEmail;
      visaData.agentGivenName = agentGivenName;
    }
    props.createNewVisa(visaData);
    setVisaForEdit({});
  }
}

export default NewVisaPopup;
