import React, { Component } from 'react';
import PDF from 'react-pdf-js';
import termsPdf from '../../assets/images/terms-and-conditions.pdf';
import { Button } from "@material-ui/core";

class TermsPDFView extends Component {
  state = { page: 1, pages: 0 }; // Pages are typically 1-indexed in user interfaces.

  onDocumentComplete = (pages) => {
    this.setState({ pages });
  };

  handlePrevious = () => {
    this.setState(prevState => ({ page: Math.max(1, prevState.page - 1) }));
  };

  handleNext = () => {
    this.setState(prevState => ({ page: Math.min(prevState.pages, prevState.page + 1) }));
  };

  renderPagination = (page, pages) => {
    const previousButton = <Button onClick={this.handlePrevious} disabled={page === 1} color='primary' style={{marginLeft:'10px', marginRight:'10px'}} variant='contained'>הקודם</Button>;
    const nextButton = <Button onClick={this.handleNext} disabled={page === pages} color='primary' variant='contained'>הבא</Button>;
    return (
      <nav>
        {previousButton}
        {nextButton}
      </nav>
    );
  };

  render() {
    const { page, pages } = this.state;

    return (
      <div onClick={(e)=> e.stopPropagation()} style={{width: 'max-content'}}>
        <PDF
          file={termsPdf}
          onDocumentComplete={this.onDocumentComplete}
          page={page}
        />
        {this.renderPagination(page, pages)}
      </div>
    );
  }
}

export default TermsPDFView;
