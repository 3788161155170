import React, { Component } from "react";
import ourStoryImg from "../../assets/images/ourStoryImg.png";
import config from "../../config";

class AboutUsStory extends Component {
  render() {
    return (
      <div className="our-story-con">
        <div className="our-story-img">
          <img src={ourStoryImg} />
        </div>
        <div className="our-story-text heb-bold">
          <h1>הסיפור שלנו</h1>
          {/* <h4>
            לורם איפסום הוא כינוי לטקסט חסר משמעות לחלוטין - הנקרא לפעמים גם דמי טקסט או ג'יבריש - ומיועד להיות ממוקם בסקיצות עיצוביות - של
            עלונים, מגזינים, מודעות, אתרי אינטרנט וכו' - במקום הטקסט האמיתי הסופי - עד שיהיה טקסט אמיתי.
          </h4> */}
          <h5 className="mt-30">
            לחברת {config.isWebsiteB2C ? "My Visae " : "VISA TO GO"} ניסיון של שנים בהנפקת כל סוגי הויזות לכלל סוגי הדרכונים ובפרט לדרכונים
            ישראליים. החברה עוסקת בטיפול בויזות לכ-30 המדינות אשר דורשות ויזת שהייה לדרכון ישראלי. אנו מתחייבים למתן שירות מקצועי, יעיל
            ואמין לכל אדם, לסודיות ולפרטיות. בנוסף, צוות החברה נמצא בקשר מתמיד עם הקונסוליות השונות על מנת לתת את השירות העדכני והטוב ביותר.
            אצלנו הקשר האישי עם הלקוח הוא מעל הכל – שירות אישי כולל משלוחים עד בית הלקוח.
          </h5>
        </div>
      </div>
    );
  }
}

export default AboutUsStory;
