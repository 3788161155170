import React from "react";
import bannerImg from "../../assets/images/aboutUsBanner.jpg";
import { Parallax } from "react-parallax";

function AboutUsParallax() {
  return (
    <div className="parallax">
      <Parallax bgImage={bannerImg} bgImageAlt="About Us" strength={200}>
        <div className="banner-text-con">
          <h1 className="text-in-center heb-bold">עלינו</h1>
        </div>
      </Parallax>
    </div>
  );
}

export default AboutUsParallax;
