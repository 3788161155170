import React, { Component } from 'react';
import TermsPDFView from './TermsPDFView';

class TermsAndConditions extends Component {
  render() {
    return (
      <div className="mt-40 center flex-con">
        <TermsPDFView />
      </div>
    );
  }
}

export default TermsAndConditions;
