import React, { Component, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countryList from "../../../assets/data/countriesList";

function AddNewFromTo(props) {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [visaType, setType] = useState("");

  return (
    <div>
      <Dialog open={props.isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="primary-color">
          New 'from' ~ 'to' Option
        </DialogTitle>
        <DialogContent>
          <div>
            <Autocomplete
              className="auto0"
              options={countryList}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="From" variant="outlined" />}
              onChange={(event, newInputValue) => {
                setFrom(newInputValue);
              }}
            />
          </div>
          <div className="mt-20">
            <Autocomplete
              className="auto0"
              options={countryList}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="To" variant="outlined" />}
              onChange={(event, newInputValue) => {
                setTo(newInputValue);
              }}
            />
          </div>
          <div className="mt-20">
            <TextField
              required
              label="Type"
              className="field-strech"
              onChange={(e) => {
                setType(e.target.value);
              }}
            />
          </div>
          <div className="mt-40">
            <Button
              className="mr-20 mt-20"
              onClick={() => {
                confirmAndClose(from, to, visaType);
              }}
              color="primary"
              variant="contained"
              size="small"
            >
              <i className="material-icons width40">check</i>
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function confirmAndClose(from, to, visaType) {
    if (!from || !to || !visaType) return;
    if (from === to) return;
    props.newFromTo(from, to, visaType);
  }
}

export default AddNewFromTo;
