import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { setLoaderOn, setLoaderOff } from "../../../Actions/appSettingActions";
import config from "../../../config";
import validationService from "../../../Services/validation/validationService";

import agentsService from "../../../Services/agentsService";

class ForgetPassword extends Component {
  state = {
    email: ""
  };

  constructor() {
    super();
    this.reRef = React.createRef();
  }

  render() {
    return (
      <div className="right">
        <ReCAPTCHA sitekey={config.reCaptchaKey} size="invisible" ref={this.reRef} />

        <div className="row mt-120">
          <div className="col-lg-3"></div>
          <div className="col-sm-12 col-lg-6">
            <Card>
              <CardContent className="login-card">
                <h4>
                  <b>לא זוכר את הסיסמה?</b>
                </h4>
                <div className="mb-10">
                  אל חשש! איפוס סיסמה הוא פשוט מאוד.
                  <br />
                  כל שעליך לעשות הוא להזין לשדה הבא את האימייל שלך
                </div>
                <div className="mb-20 mt-20 padding20">
                  <TextField required id="email" onChange={this.handleChange} label="מה המייל שלך?" type="email" />
                </div>
                <div className="mb-20">
                  <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                    לחץ לאיפוס
                  </Button>
                </div>
                <span className="d-block text-14">
                  זוכר את הסיסמה שלך? לחץ לכניסה
                  <Link to="/agents/login"> כאן </Link>
                </span>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      Swal.fire("", "יש להכניס אימייל", "warning");
      return;
    }

    var captchaToken = null;
    this.props.setLoaderOn();

    validationService.validateOneField("initEmail", this.state.email).then(
      async (validatedData) => {
        captchaToken = await this.reRef.current.executeAsync();
        this.reRef.current.reset();

        agentsService.resetUserPass(validatedData, captchaToken).then((res) => {
          if (res["generalErr"]) {
            Swal.fire("", "משהו לא הסתדר. בבקשה נסה שוב מאוחר יותר", "warning");
          } else if (!res["isFound"]) {
            Swal.fire("לא ניתן לשחזר סיסמה כרגע. במידה ובעיה זו ממשיכה בבקשה צור איתנו קשר", "יוזר לא קיים", "warning");
          } else if (res["success"]) {
            this.props.history.push("/agents/message/reset-pass-msg");
          }
        });
      },
      (validationErr) => {
        this.props.setLoaderOff();
        Swal.fire("", 'הדוא"ל שהוזן אינו תקין');
      }
    );
  };
}

const mapDispatchToProps = (dispatch, userLoginData) => {
  return {
    setLoaderOn: () => dispatch(setLoaderOn()),
    setLoaderOff: () => dispatch(setLoaderOff())
  };
};

export default connect(null, mapDispatchToProps)(ForgetPassword);
