import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import OnlyNotLoggedInRoute from "../../protectedRoutes/onlyNotLoggedInGuard";
import AgentGuard from "../../protectedRoutes/agentGuard";

import Login from "./userSessions/login";
import Register from "./userSessions/register";
import ForgetPassword from "./userSessions/forgotPass";
import TokenConf from "./userSessions/conf-token";
import MessagePage from "./userSessions/messagePage";
import SetNewPass from "./userSessions/setNewPass";
import AgentProfile from "./agentLoggedIn/agentProfile";
import "../../assets/css/sessions.css";
import "../../assets/css/generalAgent.css";

class AgentContainer extends Component {
  render() {
    return (
      <div>
        <div className="center container mt-30 mb-50">
          <Switch>
            <OnlyNotLoggedInRoute exact path="/agents/" component={Login} />
            <OnlyNotLoggedInRoute path="/agents/login" component={Login} />
            <OnlyNotLoggedInRoute exact path="/agents/register" component={Register} />
            <OnlyNotLoggedInRoute exact path="/agents/forgot-password" component={ForgetPassword} />
            <OnlyNotLoggedInRoute exact path="/agents/set-new-pass/:token" component={SetNewPass} />
            <OnlyNotLoggedInRoute path="/agents/conf-token/:token" component={TokenConf} />
            <OnlyNotLoggedInRoute exact path="/agents/message/:messageType" component={MessagePage} />

            <AgentGuard exact path="/agents/profile" component={AgentProfile} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default AgentContainer;
