import React, { Component } from "react";
import { TextField, Select, MenuItem } from "@material-ui/core";
import config from "../../../config";

class LangOptions extends Component {
  state = {};

  render() {
    var props = this.props;
    return (
      <div>
        {props.langsAmount
          ? Array.from(Array(props.langsAmount).keys()).map((index) => {
              return (
                <div key={index + "bo"} className="border">
                  <div className="mt-20">
                    lang key
                    <Select
                      className="select-default"
                      value={props.loopVals["langKey" + (index + 1)] || ""}
                      onChange={(e) => {
                        props.loopVarsChange({ target: { value: e.target.value, id: "langKey" + (index + 1) } });
                      }}
                      displayEmpty
                    >
                      <MenuItem value="he">he</MenuItem>
                      <MenuItem value="en">en</MenuItem>
                    </Select>
                  </div>
                  <div className="mt-10">
                    <TextField
                      id={"title" + (index + 1)}
                      label="Title"
                      onChange={props.loopVarsChange}
                      value={props.loopVals["title" + (index + 1)] || ""}
                    />
                  </div>
                  <div className="mt-10">
                    <TextField
                      id={"label" + (index + 1)}
                      label="Label"
                      onChange={props.loopVarsChange}
                      value={props.loopVals["label" + (index + 1)] || ""}
                    />
                  </div>

                  {this.typeHasOptions() ? (
                    <div className="mt-30">
                      <h6 className="primary-color">Options</h6>
                      <div className="mt-20">
                        Amount
                        <Select
                          id={"optionsAmount" + index}
                          value={props.loopVals["optionsAmount" + (index + 1)] || ""}
                          className="select-default"
                          //   onChange={(e) => {
                          //     this.handleChange({
                          //       target: { value: e.target.value, id: "optionsAmount" + (index + 1) }
                          //     });
                          //   }}
                          onChange={(e) => {
                            props.loopVarsChange({
                              target: { value: e.target.value, id: "optionsAmount" + (index + 1) }
                            });
                          }}
                          displayEmpty
                        >
                          {Array.from(Array(config.amountOptionsAllowed).keys()).map((item, index) => {
                            return (
                              <MenuItem key={index + "as2"} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>

                      {props.loopVals["optionsAmount" + (index + 1)]
                        ? Array.from(Array(props.loopVals["optionsAmount" + (index + 1)]).keys()).map(
                            (item, innerIndex) => {
                              return (
                                <div className="options-wrapper" key={"asd" + innerIndex}>
                                  <div className="mt-10">
                                    <p className="mt-20 lightgray-brand underline">Option {innerIndex + 1}</p>
                                    <TextField
                                      className="width45"
                                      id={"label" + (innerIndex + 1)}
                                      label={"Label" + (innerIndex + 1)}
                                      onChange={(e) => {
                                        this.handleOptionsChange(e, index, innerIndex, "label");
                                      }}
                                      value={this.getOptionData(index, innerIndex).label}
                                    />
                                    <TextField
                                      className="width45"
                                      id={"val" + (innerIndex + 1)}
                                      label={"Value" + (innerIndex + 1)}
                                      onChange={(e) => {
                                        this.handleOptionsChange(e, index, innerIndex, "val");
                                      }}
                                      value={this.getOptionData(index, innerIndex).val}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    );
  }

  getOptionData = (index, innerIndex) => {
    return this.props.loopVals["options" + (index + 1)][innerIndex] || "";
  };

  handleOptionsChange = (e, groupOptionsIndex, innerOptionsIndex, labelOrVal) => {
    var createNewOpts = [...this.props.loopVals["options" + (groupOptionsIndex + 1)]];
    var innerSingleOpt = { ...createNewOpts[innerOptionsIndex] };
    innerSingleOpt[labelOrVal] = e.target.value;
    createNewOpts[innerOptionsIndex] = innerSingleOpt;

    this.props.loopVarsChange({ target: { id: "options" + (groupOptionsIndex + 1), value: createNewOpts } });
  };

  typeHasOptions = (e) => {
    if (this.props.type === "radioBtns" || this.props.type === "radioCircles" || this.props.type === "autocomplete") return true;
    return false;
  };

  handleChange = (e) => {
    // this.setState({ [e.target.id]: e.target.value });
    this.props.handleChange(e);
  };
}

export default LangOptions;
