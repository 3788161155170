export const isValidPhone = (phone) => {
  if (!phone) return false;
  const phoneArr = phone.match(/\d/g);
  const allowedChar = phone.match(/-|\.|\d|\(|\)|\+| /g);
  if (phoneArr === null || phone.length !== allowedChar.length) {
    return false;
  }
  return phoneArr.length === 10 || phoneArr.length === 11 || phoneArr.length === 12;
};

export const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
  return re.test(email);
};

export const isValidName = (name) => {
  if (!name) return false;
  if (name.length < 2) return false;
  if (/\d/.test(name)) return false;
  return true;
};

export const isValidYear = (year) => {
  if (!year) return false;
  if (year.length != 4) return false;
  return /^\d+$/.test(year);
};
