import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import { TextField, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import validationService from "../../Services/validation/validationService";
import retrieveVisaSchema from "../../schemas/retrieveVisaSchema";
import "../../assets/css/retrieveVisa.css";
import { setVisaForEdit } from "../../Actions/loggedinActions";
import visaService from "../../Services/visaService";
import visaStatuses from "../../assets/data/visaStatuses";
import { Link } from "react-router-dom";

class RetrieveExistingVisa extends Component {
  state = {
    passportNum: "",
    visaIdOrMobile: "",
    validationErrors: {},
    linkVisa: false
  };

  constructor(props) {
    super(props);
    const { passportNum, visaId } = props.match.params;
    if (passportNum && visaId) {
      this.state.linkVisa = true;
      this.state.passportNum = passportNum;
      this.state.visaIdOrMobile = visaId;
    }
  }

  componentDidMount() {
    if (this.state.linkVisa) this.retrieveVisaDetails();
  }

  render() {
    return (
      <div className="mt-50 mb-50">
        <h3 className="primary-color center mt-50">
          <b>חיפוש ויזה קיימת</b>
        </h3>

        {this.state.linkVisa ? (
          <h1 className="center mt-50">
            <b>טוען את הויזה שלך...</b>
          </h1>
        ) : (
          <div className="card-width auto0">
            <Card>
              <CardContent className="login-card">
                <div className="center">
                  <TextField
                    id="passportNum"
                    label="מספר דרכון"
                    className="field-width"
                    value={this.state.passportNum}
                    onChange={this.handleChange}
                    helperText={this.state.validationErrors.passportNum ? this.state.validationErrors.passportNum : ""}
                    error={this.state.validationErrors.passportNum ? true : false}
                  />
                  <br />
                  <br />

                  <TextField
                    id="visaIdOrMobile"
                    value={this.state.visaIdOrMobile}
                    className="field-width"
                    label="מזהה ויזה או מספר נייד"
                    onChange={this.handleChange}
                    helperText={this.state.validationErrors.visaIdOrMobile ? this.state.validationErrors.visaIdOrMobile : ""}
                    error={this.state.validationErrors.visaIdOrMobile ? true : false}
                  />
                </div>

                <br />
                <div className="mt-50 center">
                  <Button className="btn-width160" onClick={this.retrieveVisaDetails} color="primary" variant="contained">
                    חיפוש
                    <i className="material-icons">search</i>
                  </Button>
                </div>

                {this.state.msgToShow}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    );
  }

  handleChange = (e) => {
    var validationErrors = { ...this.state.validationErrors };
    delete validationErrors[e.target.id];
    this.setState({
      [e.target.id]: e.target.value,
      validationErrors,
      msgToShow: null,
      visaData: null
    });
  };

  retrieveVisaDetails = async () => {
    var itemsToValidate = {
      passportNum: this.state.passportNum,
      visaIdOrMobile: this.state.visaIdOrMobile
    };

    validationService.validateSchema(retrieveVisaSchema, itemsToValidate).then(
      (validatedData) => {
        visaService.retrieveVisaDetails(validatedData).then((res) => {
          if (res.success) {
            const { visaData } = res;
            let msgToShow;
            if (!visaData) {
              msgToShow = (
                <div className="mt-30">
                  <Alert className="right" severity="warning">
                    לא קיימת ויזה כזאת במערכת
                  </Alert>
                </div>
              );
            } else if (
              visaData.status === visaStatuses.FILLING.statusKey ||
              visaData.status === visaStatuses.AGENT_PAID_CLIENT_FILLS.statusKey ||
              visaData.status === visaStatuses.AGENT_PAID_AND_FILLS.statusKey
            ) {
              const buildLink = `/questionnaire/${visaData.from}/${visaData.to}/${visaData.visaType}`;
              visaData.visaIdOrMobile = visaData._id;
              this.props.setVisaForEdit(visaData);
              if (this.state.linkVisa) {
                this.props.history.push(buildLink);
                return;
              }
              msgToShow = (
                <div className="mt-30">
                  <Alert className="right" severity="info">
                    <Link to={buildLink}>{visaStatuses.FILLING.statusMsg}</Link>
                  </Alert>
                </div>
              );
            } else {
              msgToShow = (
                <div className="mt-30">
                  <Alert className="right" severity="success">
                    {visaStatuses[visaData.status]?.statusMsg || "הויזה בטיפול"}
                  </Alert>
                </div>
              );
            }
            this.setState({ msgToShow, visaData, linkVisa: false });
          }
        });
      },
      (validationErrors) => {
        this.setState({ validationErrors, linkVisa: false });
      }
    );
  };
}

const mapDispatchToProps = (dispatch, visaData) => {
  return {
    setVisaForEdit: (visaData) => dispatch(setVisaForEdit(visaData))
  };
};

export default connect(null, mapDispatchToProps)(RetrieveExistingVisa);
