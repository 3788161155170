import React, { Fragment } from "react";
import checkiconImg from "../../../../assets/images/check_icon.png";

function WhyUsTxt(props) {
  return (
    <div className="whyus-con mt-10">
      <ul className="ul-whyus">
        <li className="align-whyus-text">
          <div className="whyus-text-check-img">
            <img className="checkicon-reduce" src={checkiconImg} />
          </div>
          <div className="whyus-text-inner-con">ידע רב וניסיון של שלושה עשורים בענף הויזות.</div>
        </li>
        <li className="align-whyus-text">
          <div className="whyus-text-check-img">
            <img className="checkicon-reduce" src={checkiconImg} />
          </div>
          <div className="whyus-text-inner-con">היכרות אישית ורבת שנים עם כלל הקונסוליות.</div>
        </li>

        <li className="align-whyus-text">
          <div className="whyus-text-check-img">
            <img className="checkicon-reduce" src={checkiconImg} />
          </div>
          <div className="whyus-text-inner-con">צוות מיומן ומסור שמכיר את כלל הפרטים הנדרשים להנפקת כל סוגי הויזות.</div>
        </li>
        <li className="align-whyus-text">
          <div className="whyus-text-check-img">
            <img className="checkicon-reduce" src={checkiconImg} />
          </div>
          <div className="whyus-text-inner-con">כפל בדיקה על ידינו לפני הגשת הויזה לנכונות ולדיוק הפרטים שנמסרו על ידי הלקוח.</div>
        </li>
        {!props.isDubai ? (
          <Fragment>
            <li className="align-whyus-text">
              <div className="whyus-text-check-img">
                <img className="checkicon-reduce" src={checkiconImg} />
              </div>
              <div className="whyus-text-inner-con">מניעת טעויות וחיסכון רב בזמן ובכסף.</div>
            </li>
            <li className="align-whyus-text">
              <div className="whyus-text-check-img">
                <img className="checkicon-reduce" src={checkiconImg} />
              </div>
              <div className="whyus-text-inner-con">
                אצלנו תקבלו שירות אישי מלא: תמיכה טלפונית, הכוונה והדרכה במילוי הטפסים ושירות אישי לכל אורך התהליך.
              </div>
            </li>
            <li className="align-whyus-text">
              <div className="whyus-text-check-img">
                <img className="checkicon-reduce" src={checkiconImg} />
              </div>
              <div className="whyus-text-inner-con">הדרכה במקרים בהם נדרש ראיון אישי לארה"ב בו תקבע הזכאות לויזה. </div>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className="align-whyus-text">
              <div className="whyus-text-check-img">
                <img className="checkicon-reduce" src={checkiconImg} />
              </div>
              <div className="whyus-text-inner-con">
                אצלינו תקבלו שירות אישי מלא: תמיכה טלפונית, הכוונה, הדרכה ושירות אישי לכל אורך התהליך
              </div>
            </li>
            <li className="align-whyus-text">
              <div className="whyus-text-check-img">
                <img className="checkicon-reduce" src={checkiconImg} />
              </div>
              <div className="whyus-text-inner-con">זמן טיפול בין 24 ועד 48 שעות</div>
            </li>
          </Fragment>
        )}
      </ul>
    </div>
  );
}

export default WhyUsTxt;
