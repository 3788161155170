import React, { Component } from "react";
import "../../assets/css/aboutUs.css";
import statsImg from "../../assets/images/stats-image.png";
import WhyUsTxt from "../home/b2b/whyUs/whyUsTxt";

class AboutUsStats extends Component {
  render() {
    return (
      <div className="our-stats-con">
        <div className="container">
          <div className="our-stats-info">
            <WhyUsTxt />
            {/* <div className="our-stats-text heb-bold">
              <h1>הסיפור שלנו</h1>
              <h4>
                לורם איפסום הוא כינוי לטקסט חסר משמעות לחלוטין - הנקרא לפעמים גם דמי טקסט או ג'יבריש - ומיועד להיות ממוקם בסקיצות עיצוביות
              </h4>
              <h6>
                לורם איפסום הוא טקסט חסר הקשר וחסר משמעות בכוונה - וזאת כדי שעין הקורא לא "תצוד" בזמן הרפרוף עליו, מילה שתסיח את דעתו. לורם
                איפסום תוכנן בקפידה כך שיחקה במרקם ובקצב שלו טקסט אמיתי בעברית.
              </h6>
            </div>
            <hr className="our-story-hr" />
            <div className="stats-bar-progress">
              <h2>שביעות רצון של לקוח</h2>
              <div className="border-progress">
                <div className="bar-progress"></div>
              </div>
            </div>
            <div className="stats-bar-progress">
              <h2>שביעות רצון של לקוח</h2>
              <div className="border-progress">
                <div className="bar-progress"></div>
              </div>
            </div>
            <div className="stats-bar-progress">
              <h2>שביעות רצון של לקוח</h2>
              <div className="border-progress">
                <div className="bar-progress"></div>
              </div>
            </div>
         */}
          </div>
          <div className="our-stats-img">
            <img src={statsImg} />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsStats;
