import React, { Component, Fragment } from "react";
import { Grid, ListItem, Button, ListItemIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import NavLinksOffline from "./navLinks/navOfflineLinks";

class SidebarMenu extends Component {
  state = {
    isOpen: false
  };
  render() {
    var CorrectLinks = this.props.correctLinks.type;
    return (
      <Fragment>
        <div id="side-bar-con">
          <i
            onClick={() => {
              this.setState({ isOpen: true });
            }}
            className="material-icons"
          >
            menu
          </i>
        </div>
        {this.state.isOpen ? (
          <div className="side-bar-full-screen">
            <div className="side-bar-color-part">
              <div className="menu-burger">
                <i
                  onClick={() => {
                    this.setState({ isOpen: false });
                  }}
                  className="material-icons"
                >
                  close
                </i>
              </div>
              <div className="mt-50 pl-30 side-bar-links-con">
                <CorrectLinks
                  closeSideBar={() => {
                    this.setState({ isOpen: false });
                  }}
                />
              </div>
            </div>
            <div
              onClick={() => {
                this.setState({ isOpen: false });
              }}
              className="dark-side"
            ></div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default SidebarMenu;
