import React from "react";
import Swal from "sweetalert2";
import logoReg from "../../assets/images/logo-color.png";
import b2cLogoBlue from "../../assets/images/logo-b2c-blue.svg";
import config from "../../config";

const logoImage = config.isWebsiteB2C ? b2cLogoBlue : logoReg;

function TopQuestionnaireNav(props) {
  return (
    <div className="custom-nav">
      <div className="custom-nav-item">
        <img alt="Visa-to-go" src={logoImage} />
      </div>

      <div
        onClick={() => {
          Swal.fire({
            title: "להפסיק את תהליך יצירת הויזה?",
            text: "האם את\\ה בטוח\\ה",
            icon: "warning",
            showCancelButton: true
          }).then((result) => {
            if (result.value) {
              props.history.push("/");
            }
          });
        }}
        className="custom-nav-item-abs-left"
      >
        <i className="material-icons ml-20">clear</i>
      </div>
    </div>
  );
}

export default TopQuestionnaireNav;
