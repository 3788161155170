import React from "react";
import visaService from "../../../Services/visaService";
import { Button } from "@material-ui/core";

function VisaSummary(props) {
  var viewingVisaOpt = props.viewingVisaOpt;
  return (
    <div className="center">
      <div className="summary-cons light-gray-background lightgray-brand">
        {visaService.upperCaseCountry(props.from)}
        <i className="material-icons primary-color from-to-con-icon">compare_arrows</i>
        {visaService.upperCaseCountry(props.to)}
      </div>

      <div className="mt-20 summary-cons light-gray-background lightgray-brand">
        <h5 className="center primary-color">
          <b>{viewingVisaOpt.visaTitle}</b>
        </h5>

        {!viewingVisaOpt.downloadVisa && <h6 className="center">אשרה אלקטרונית</h6>}

        <h6 className="center">{viewingVisaOpt.stayAllowance}</h6>

        <div className="padding20">
          <table className="width100">
            <tbody>
              <tr>
                <td className="right">מחיר אגרה</td>
                <td className="left">₪{props.visaActualPrice}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="right">דמי טיפול כולל מע״מ</td>

                <td className="left">₪{props.visaActualServicePrice}</td>
              </tr>
            </tbody>
          </table>
          <hr />

          <div className="primary-color">
            <table className="width100">
              <tbody>
                <tr>
                  <td className="right">
                    <b>מחיר סופי כולל מע״מ</b>
                  </td>
                  <td className="left">
                    <b>{props.visaActualPrice + props.visaActualServicePrice} ש"ח</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-30">
            {viewingVisaOpt.downloadVisa ? (
              <div>
                <h4 className="ltr-layout primary-color center">
                  <b>:הוראות ויזה</b>
                </h4>
                יש להוריד טופס ולמלא אותו. לאחר מיכן, יש להתקשר או לשלוח הודעת וואטסאפ למספר הבא:
                <br />
                <br />
                <b>
                  <a target="_blank" rel="noopener noreferrer" href="tel:+972037705660">
                    03-7705660
                    <i className="material-icons color-success vertical-7">phone</i>
                  </a>
                  <div className="mt-10">
                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+972502009192">
                      050-200-9192
                      <i className="material-icons color-success vertical-7">whatsapp</i>
                    </a>
                  </div>
                </b>
                <br />
                <a href={viewingVisaOpt.downloadableFile.location} target="_blank">
                  לחץ כאן להורדה
                </a>
              </div>
            ) : props.to === "taiwan (province of china)" ? (
              <div>
                <a href={"https://visawebapp.boca.gov.tw/BOCA_EVISA/MRV04FORM.do"} target="_blank">
                  יש למלא טופס בקישור ולשלוח לנו את הטופס אחר כך
                </a>
              </div>
            ) : (
              <Button onClick={props.visaTypeSelected} className="background-success color-white" variant="contained">
                להוצאת ויזה אונליין לחץ כאן
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisaSummary;
