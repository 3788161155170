import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";

function FinishedVisaPage(props) {
  const [fullName, setFullName] = useState("");
  const [passportNum, setPassportNum] = useState("");
  const [visaId, setVisaId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  useEffect(() => {
    setFullName(props.match.params.fullName);
    setPassportNum(props.match.params.passportNum);
    setVisaId(props.match.params.visaId);
    setFrom(props.match.params.from);
    setTo(props.match.params.to);
  }, []);

  return (
    <div>
      <div>
        <br />
        <br />
        <br />
        <Card className="mb-50 center visa-created-card-con">
          <CardContent className="padding40">
            <h3 className="primary-color mb-40 center">
              בקשת ויזה עבור
              <b> {fullName.toUpperCase()} </b>
              נוצרה בהצלחה
            </h3>

            <div className="mb-20">
              <i className="material-icons big-check color-success">check_circle_outline</i>
            </div>

            <div className="auto0">
              <div className="inline-block right">
                <table cellPadding="10">
                  <tbody>
                    <tr>
                      <td>מזהה הויזה שלך: </td>
                      <td>
                        <div className="txt-next-line">{visaId}</div>
                        <b></b>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>מספר דרכון:</td>
                      <td>
                        <b>{passportNum}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="right mt-50">
              <hr />
              <Link to={`/visa-type/${from}/${to}`}>לחץ כאן ליצירת ויזה נוספת</Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default FinishedVisaPage;
