import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import MaterialTableComponent from "../../widgets/materialTable";
import adminServices from "../../../Services/adminService";
import Swal from "sweetalert2";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import moment from "moment";
import { groupDestinations } from "../../destination/destinationUtils";

const actionTypes = { UPDATE: "UPDATE", DELETE: "DELETE", NEW: "NEW" };

const destinationRenderer = (data) => {
  if (groupDestinations.some((e) => e.value === data.destination)) {
    const place = groupDestinations.filter((e) => e.value === data.destination);
    return <span>{place[0].label}</span>;
  } else {
    return <span>{data.destination}</span>;
  }
};

const destinationEditor = (props) => {
  return (
    <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
      <InputLabel htmlFor="destination-select">נא לבחור יעד</InputLabel>
      <Select
        id="destination-select"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        label="נא לבחור יעד"
      >
        {groupDestinations.map((place) => (
          <MenuItem value={place.value}>{place.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const dateEditor = (props) => { 
  return (
    <input 
      className="date-input"
      type="date"
      max="9999-12-31"
      value={moment(props.rowData.startDate).format('YYYY-MM-DD')}
      onChange={e => props.onChange(e.target.value)}
    />)
}

function GroupsManager(props) {
  const { agentId } = useParams();
  const [groups, setGroups] = useState([]);
  const [agentInfo, setAgentInfo] = useState(null);
  const location = useLocation();

  const redirectToGroupInfo = (groupData, e) => {
    if (e.ctrlKey) window.open("/admin/group-info/" + groupData._id, "_blank");
    else props.history.push("/admin/group-info/" + groupData._id)
  };

  const alterGroup = async (groupData, actionType) => {
    let data;
    switch (actionType) {
      case actionTypes.NEW:
        data = await adminServices.addGroup(groupData, agentInfo);
        break;
      case actionTypes.DELETE:
        data = await adminServices.deleteGroupById(groupData._id);
        break;
      case actionTypes.UPDATE:
        data = await adminServices.updateGroupById(groupData);
        break;
      default:
        throw new Error("no type found. table update will not occur");
    }

    if (!data || data.err) {
      throw new Error("update did not occur will not update table");
    }

    Swal.fire(`${actionType} - בוצע`, "", "success");
    return data;
  };

  useEffect(() => {
    const initStates = async () => {
      const groups = await adminServices.getGroupsByAgentId(agentId);
      setGroups(groups);
      let agentInfo = location.state;
      if (!agentInfo) agentInfo = await adminServices.getAgentById(agentId);
      const { agentEmail, agentLastName, agentFirstName, agencyId, _id } = agentInfo;
      setAgentInfo({ agencyId, agentId: _id, agentFirstName, agentLastName, agentEmail });
    };

    initStates();
  }, [agentId, location.state]);
  
  const columns = [
    {
      title: "שם הארגון",
      field: "organizationName"
    },
    {
      title: "סוכן אחראי",
      field: "agentId",
      render: (_data) => <span>{`${agentInfo.agentFirstName} ${agentInfo.agentLastName}`}</span>,
      editable: "never",
      initialEditValue: agentId
    },
    {
      title: "תאריך יציאה",
      field: "startDate",
      render: (data) => <span>{data.startDate? moment(data.startDate).format('DD/MM/YYYY') : "לא נקבע"}</span>,
      editComponent: dateEditor,
      // TODO: Check if we want it settable on create or only editable
      editable: "onUpdate",
    },
    { 
      title: "יַעַד",
      field: "destination",
      render: destinationRenderer,
      editComponent: destinationEditor,
      editable: "onAdd",
      initialEditValue: "morocco"
     }
  ]

  return (
    <div className="mb-60">
     {agentInfo &&
      <MaterialTableComponent
        agentType="group"
        redirectToAgentsGroups={redirectToGroupInfo}
        options={{ pageSizeOptions: [5, 10, 20, 100] }}
        data={groups}
        columns={columns}
        onDataChanged={alterGroup}
        tableTitle="טבלת קבוצות"
        allowedDelete={true}
      />}
    </div>
  );
}

export default GroupsManager;
