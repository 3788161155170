import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Swal from "sweetalert2";

import agentsService from "../../../Services/agentsService";

export default class SetNewPass extends Component {
  state = {
    pass: "",
    repeatPass: "",
    token: ""
  };

  componentDidMount() {
    var token = this.props.match.params.token;
    this.setState({ token });
  }

  render() {
    return (
      <div className="row mt-120">
        <div className="col-lg-3"></div>
        <div className="col-sm-12 col-lg-6">
          <Card>
            <CardContent className="login-card">
              <div className=" mb-20">
                <h4>
                  <b>איפוס הסיסמה שלך</b>
                </h4>
                <form>
                  <div className="padding10">
                    <TextField required id="pass" onChange={this.handleChange} label="סיסמה" type="password" />
                  </div>
                  <div className="mb-30 padding10">
                    <TextField
                      id="repeatPass"
                      onChange={this.handleChange}
                      required
                      label="אישור סיסמה"
                      type="password"
                    />
                  </div>

                  <div className="mt-50">
                    <Button className="white-btn-txt" color="primary" variant="contained" onClick={this.handleSubmit}>
                      שמור
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.pass.length < 8) {
      Swal.fire("", "הסיסמה חייבת להיות בעלת 8 תוים לפחות", "warning");
      return;
    }
    if (this.state.pass !== this.state.repeatPass) {
      Swal.fire("", "הסיסמה ואישור הסיסמה שהוזנו אינם תואמות", "warning");
      return;
    } else if (!this.state.token) {
      Swal.fire("", "missing data", "warning");
      return;
    }
    var takeVars = this.state;
    agentsService.changePass(takeVars.pass, takeVars.token).then((res) => {
      if (!res["isTokenValid"]) {
        Swal.fire("", "הבקשה אינה בתוקף או שהמשתמש אינו קיים.", "warning");
      } else if (res["success"]) {
        Swal.fire("", "הסיסמה שונתה בהצלחה", "success").then(() => {
          this.props.history.push("/agents/login");
        });
      }
    });
  };
}
