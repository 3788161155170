//action types
import { SET_LOGGED_IN, LOGOUT, SET_VISA_FOR_EDIT } from "../Actions/actionTypes";

const INITIAL_STATE = {
  isLoggedIn: false,
  visaToEdit: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return { ...state, isLoggedIn: true };
    case LOGOUT:
      return { ...state, isLoggedIn: false };
    case SET_VISA_FOR_EDIT:
      return { ...state, visaToEdit: action.payload };

    default:
      return { ...state };
  }
};
