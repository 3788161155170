import React, { Component } from "react";
import homeBanner from "../../../assets/images/b2c-home-banner.png";

class B2CHomeBanner extends Component {
  render() {
    return (
      <div className="relative mt-minus-2">
        <img className="b2c-home-banner-img width100" src={homeBanner} alt="Banner" />
        <div className="fade-overlay" />

        <div className="b2c-banner-text center">
          <b className="color-success b2c-home-title">אל תאבד עוד זמן</b>

          <div className="b2c-home-subtitle-container">
            <b className="primary-color b2c-home-subtitle">הגישו בקשת הויזה האלקטרונית שלכם עכשיו והתחילו לתכנן את ההרפתקה שלכם</b>
          </div>
        </div>
      </div>
    );
  }
}

export default B2CHomeBanner;
