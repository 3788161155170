import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "./Header";
import TakeWebCamPicture from "./TakeWebCamPicture";
import { isValidEmail, isValidPhone, isValidName } from "../../Services/validation/validationUtiles";
import personIconPng from "../../assets/images/person_icon.png";
import passportIconPng from "../../assets/images/passport_icon.png";
import adminServices from "../../Services/adminService";
import visaService from "../../Services/visaService";
import config from "../../config";
import { MenuItem, TextField } from "@material-ui/core";
import { maritalStatus, photoPopUpHtml } from "./formUtils";
import { isFileImage, isObjectNotEmpty, capitalizeFirstLetter } from "../../utils";
import "../../assets/css/groupForm.css";

const formInputs = [
  { label: "אימייל (לא חובה)", name: "email" },
  { label: "טלפון", name: "phone" },
  { label: "שם פרטי", name: "firstName" },
  { label: "שם משפחה", name: "lastName" },
  { label: "כתובת מגורים", name: "streetAddress" },
  { label: "עיר מגורים", name: "currentCity" },
  { label: "עיר לידה", name: "birthCity" },
  { label: "ארץ לידה", name: "birthCountry" },
  { label: "שם האב", name: "fatherName" },
  { label: "עיר לידת האב", name: "fatherBirthCity" },
  { label: "ארץ לידת האב", name: "fatherBirthCoutry" },
  { label: "שם האם", name: "motherName" },
  { label: "עיר לידת האם", name: "motherBirthCity" },
  { label: "ארץ לידת האם", name: "motherBirthCoutry" },
  { label: "מצב משפחתי", name: "maritalStatus", select: true },
  { label: "שם בן/בת הזוג", name: "spouseName" },
  { label: "מקצוע", name: "occupation" },
  { label: "מעסיק / חברה", name: "employerName" },
  { label: "כתובת מעסיק / חברה", name: "employerAddress" },
  { label: "עיר מעסיק / חברה", name: "employerCity" },
  { label: "עיר כניסה להודו", name: "portOfEntry" }
];

const defaultFormData = {
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  streetAddress: "",
  currentCity: "",
  birthCity: "",
  birthCountry: "",
  fatherName: "",
  fatherBirthCity: "",
  fatherBirthCountry: "",
  motherName: "",
  motherBirthCity: "",
  motherBithCountry: "",
  maritalStatus: "single",
  spouseName: "",
  occupation: "",
  employerName: "",
  employerAddress: "",
  employerCity: "",
  portOfEntry: "", // <-- possible to set by default?
  pictureOfPerson: null,
  pictureOfPassport: null
};

const IndiaForm = () => {
  let { organizationName, destination, dateOfTrip, groupId, groupAgentName } = useParams();
  organizationName = decodeURIComponent(organizationName);
  destination = decodeURIComponent(destination);
  groupAgentName = decodeURIComponent(groupAgentName);

  const [formData, setFormData] = useState(defaultFormData);
  const [formatErrors, setFormatErrors] = useState({});
  const [takePictureType, setTakePictureType] = useState(null);
  const formQuestions = useRef();

  const fileInputs = [
    { label: "תמונת דרכון", icon: passportIconPng, name: "pictureOfPassport", ref: useRef() },
    { label: "תמונת פספורט", icon: personIconPng, name: "pictureOfPerson", ref: useRef() }
  ];

  const onChange = ({ target: { name, value } }) => {
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[name];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [name]: value });
  };

  const onChangeFile = ({ target: { name, files } }) => {
    if (!isFileImage(files[0])) {
      Swal.fire("אופס,", "PDF ניתן להעלות רק תמונות או קבצי", "error");
      return;
    }
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[name];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [name]: files[0] });
  };

  const savePictureFromCamera = (takePictureType, imgSrc) => {
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[takePictureType];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [takePictureType]: imgSrc });
  };

  const onFileInputClick = async (name, inputRef) => {
    const { isConfirmed } = await Swal.fire({
      html: photoPopUpHtml(name),
      confirmButtonText: "להמשיך",
      cancelButtonText: "ביטול",
      showCancelButton: true,
      showCloseButton: true
    });
    if (!isConfirmed) return;
    if (!config.isMobile) {
      const userResponse = await Swal.fire({
        icon: "question",
        text: "האם תרצ/י להתצטלם בעזרת המצלמה המחוברת למחשב/לפטופ או להעלות קובץ תמונה מהמחשב?",
        confirmButtonText: "לצלם תמונה",
        cancelButtonText: "להעלות תמונה",
        showCancelButton: true,
        showCloseButton: true
      });

      if (userResponse.value) {
        setTakePictureType(name);
      } else if (userResponse.dismiss === "cancel") {
        inputRef.current.click();
      }
    } else {
      inputRef.current.click();
    }
  };

  const validateForm = (data) => {
    const formatErrors = {};

    const { email, phone, firstName, lastName, pictureOfPerson, pictureOfPassport } = data;

    if (email && !isValidEmail(email)) {
      formatErrors.email = "אימייל לא תקין";
    }

    if (!isValidPhone(phone)) {
      formatErrors.phone = "מספר טלפון לא תקין";
    }

    if (!isValidName(firstName)) {
      formatErrors.firstName = "שם פרטי לא תקין";
    }

    if (!isValidName(lastName)) {
      formatErrors.lastName = "שם משפחה לא תקין";
    }

    formInputs.forEach((field) => {
      if (field.name === "email" || field.name === "spouseName") return;
      if (!data[field.name]) {
        formatErrors[field.name] = "נא למלא פרטים";
      }
    });

    if (!pictureOfPerson) {
      formatErrors.pictureOfPerson = true;
    }

    if (!pictureOfPassport) {
      formatErrors.pictureOfPassport = true;
    }

    setFormatErrors(formatErrors);
    return formatErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formatErrors = validateForm(formData);
    if (isObjectNotEmpty(formatErrors)) {
      formQuestions.current.scrollIntoView({ behavior: "smooth", block: "start" });
      return;
    }

    const form = new FormData();
    for (const file in formData) {
      form.append(file, formData[file]);
    }
    form.append("groupId", groupId);

    const addParticipantRes = await adminServices.addParticipantToGroup(form);
    const newGroupVisaRes = await visaService.createNewGroupVisa({
      visaData: {
        ...formData,
        destination: capitalizeFirstLetter(destination),
        pictureOfPassport: addParticipantRes.data.pictureOfPassportFileName,
        pictureOfPerson: addParticipantRes.data.pictureOfPersonFileName
      }
    });

    if (addParticipantRes?.success === true && newGroupVisaRes?.success === true) {
      setFormData(defaultFormData);
      Swal.fire("בוצע בהצלחה", "הטופס נשלח בהצלחה", "success");
    }
  };

  const getInputType = (name) => {
    if (name === "email" || name === "phone") return name;
    return "text";
  };

  return (
    <div>
      <Header groupAgentName={groupAgentName} organizationName={organizationName} dateOfTrip={dateOfTrip} destination={destination} />
      <form className="group-form-form" onSubmit={handleSubmit} ref={formQuestions}>
        <h2 className="group-form-title">הכל כמעט מוכן לבואכם</h2>

        <div className="group-form-inputs-container">
          {formInputs.map(({ label, name, select, required }) => (
            <label key={name} className="group-form-input-label">
              {label}:
              <TextField
                key={name}
                className="group-form-input-mui"
                value={formData[name]}
                name={name}
                type={getInputType(name)}
                placeholder={label}
                onChange={onChange}
                variant="outlined"
                size="small"
                select={select ? select : false}
                error={!!formatErrors[name]}
                helperText={formatErrors[name] && `* ${formatErrors[name]}`}
              >
                {name === "maritalStatus" &&
                  maritalStatus.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </label>
          ))}
        </div>

        <div className="group-form-inputs-container mb-20">
          {fileInputs.map(({ label, icon, name, ref }) => (
            <div key={name} className="group-form-file-input-container">
              <img src={icon} alt="icon" />

              <span className="group-form-file-input-text">
                <p className="group-form-file-input-text-label">{label}</p>
                {formData[name] ? (
                  <p className="group-form-file-input-text-success">מסמך צורף</p>
                ) : (
                  <p className={"group-form-file-input-text-falsy " + (formatErrors[name] ? "file-input-error" : "")}>שום מסמך לא צורף</p>
                )}
              </span>

              <button
                className="group-form-file-input-button"
                type="button"
                onClick={() => {
                  onFileInputClick(name, ref);
                }}
              >
                {formData[name] ? <>&#10004;</> : "צרף"}
              </button>

              <input hidden ref={ref} name={name} type="file" onChange={onChangeFile} />
            </div>
          ))}
        </div>
        <button className="submit-button" type="submit" value="Submit">
          שלח
        </button>
      </form>

      <TakeWebCamPicture
        takePictureType={takePictureType}
        setTakePictureType={setTakePictureType}
        savePictureFromCamera={savePictureFromCamera}
      />
    </div>
  );
};

export default IndiaForm;
