import React, { Component } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

import plusImg from "../../../assets/images/+_icon.png";
import faqImg from "../../../assets/images/FAQ_icon.png";
import faqImgB2C from "../../../assets/images/FAQ_icon_b2c.png";
import config from "../../../config";

class HomeFAQ extends Component {
  render() {
    return (
      <div className="container mt-40">
        <h3 className="primary-color heb-bold center mb-30">שאלות נפוצות</h3>

        <div className="faq-container auto0">
          {config.isWebsiteB2C ? (
            <div className="faq-img-b2c">
              <div className="faq-img-inner-b2c">
                <img src={faqImgB2C} className="imgFAQ-b2c" />
              </div>
            </div>
          ) : (
            <div className="faq-img">
              <div className="faq-img-inner">
                <img src={faqImg} />
              </div>
            </div>
          )}

          <div className="faq-questions">
            <Accordion>
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content">
                <img className="width40-height-40" src={plusImg} />
                <span className="align-faq-text heb-bold">אילו סוגי ויזה מונפקים באמצעותכם?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">שני סוגי ויזות: ויזות אלקטרוניות וויזות בדרכון לדרכונים ישראליים וליותר מ-30 מדינות.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content">
                <img className="width40-height-40" src={plusImg} />
                <span className="align-faq-text heb-bold">תוך כמה זמן מקבלים ויזה?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">תלוי בסוג הויזה וביעד המבוקש. אנו מתחייבים לפרק הזמן המינימלי ככל האפשר.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content">
                <img className="width40-height-40" src={plusImg} />
                <span className="align-faq-text heb-bold">מה קורה אם אני לא מסתדר?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">לחברה מענה טלפוני וצוות מקצועי אשר ישמח לתת מענה ושירות אישי לכל אורך הדרך</div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content">
                <img className="width40-height-40" src={plusImg} />
                <span className="align-faq-text heb-bold">האם יש שינוי במדיניות הויזות עקב הקורונה?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">
                  אכן יש מדינות מסוימות ששינו את מדיניות קבלת הויזה, החברה מעודכנת ומעדכנת את כלל לקוחותיה במדיניות החדשה.
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFAQ;
