import React from "react";
import { Link } from "react-router-dom";

import logoWhite from "../../assets/images/logo-white.png";
import logoWhiteB2C from "../../assets/images/logo-b2c-white.svg";
import "../../assets/css/layout/footer.css";
import config from "../../config";

const whiteLogoSrc = config.isWebsiteB2C ? logoWhiteB2C : logoWhite;

const Footer = () => (
  <div className="primary-background mt-70">
    <div className="width70 auto0 color-white">
      {config.isWebsiteB2C ? (
        <div>
          <div className="footer-top">
            <div className="footer-top-links-b2c">
              <ul>
                <li>
                  <Link to="/terms-and-conditions">תנאי שימוש</Link>
                </li>                
                <li>
                  <Link to="/about-us">מי אנחנו</Link>
                </li>
                <li>
                  <Link to="/retrieve-visa">איתור ויזה</Link>
                </li>
                <li>
                  <Link to="/contact-us">צור קשר</Link>
                </li>
                <li>
                  <Link to="/agents/login">סוכנים</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-middle-b2c">
            <div>
              <ul className="mb-0">
                <li>
                  <h5>
                    <b>צרו קשר</b>
                  </h5>
                </li>
                <li>סניף ראשי: {config.ourAddress}</li>
                <li>
                  טלפון משרד:{" "}
                  <a className="text-decoration-none color-white" href={"tel:" + config.supportNumber}>
                    {config.supportNumber}
                  </a>
                </li>
                {/* <li>
                  <a
                    className="text-decoration-none color-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/+972502009192"
                  >
                    וואטסאפ
                  </a>
                </li> */}
                <li>
                  <a className="color-white" href={`mailto:${config.supportEmail}`}>
                    דוא"ל: {config.supportEmail}
                  </a>
                </li>
                <li>
                  <div className="logo-con-b2c">
                    <Link to="/">
                      <img alt="Visa logo" src={whiteLogoSrc} />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>

            <div className="footer-text-middle-b2c">
              <ul>
                <li>
                  <h5>
                    <b>מדינות נפוצות</b>
                  </h5>
                </li>

                <>
                  <li>
                    <Link to="/visa-type/israel/canada" className="color-white">
                      קנדה
                    </Link>
                  </li>
                  <li>
                    <Link to="/visa-type/israel/china" className="color-white">
                      ויזה לסין
                    </Link>
                  </li>
                  <li>
                    <Link to="/visa-type/israel/australia" className="color-white">
                      ויזה לאוסטרליה
                    </Link>
                  </li>
                </>
              </ul>
            </div>

            <div className="footer-text-b2c">
              לחברת My Visae ניסיון של שנים בהנפקת כל סוגי הויזות לכלל סוגי הדרכונים ובפרט לדרכונים ישראליים. החברה עוסקת בטיפול בויזות
              לכ-30 המדינות אשר דורשות ויזת שהייה לדרכון ישראלי. אנו מתחייבים למתן שירות מקצועי, יעיל ואמין לכל אדם, לסודיות ולפרטיות.
              בנוסף, צוות החברה נמצא בקשר מתמיד עם הקונסוליות השונות על מנת לתת את השירות העדכני והטוב ביותר. אצלנו הקשר האישי עם הלקוח הוא
              מעל הכל – שירות אישי כולל משלוחים עד בית הלקוח.
            </div>
          </div>

          <div className="center pb-30 mt-50">כל הזכויות שמורות ל- עולם הויזות</div>
        </div>
      ) : (
        <div>
          <div className="footer-top">
            <div className="footer-top-links">
              <ul>
                <li>
                  <Link to="/terms-and-conditions">תנאי שימוש</Link>
                </li>                     
                <li>
                  <Link to="/about-us">מי אנחנו</Link>
                </li>
                <li>
                  <Link to="/retrieve-visa">איתור ויזה</Link>
                </li>
                <li>
                  <Link to="/contact-us">צור קשר</Link>
                </li>
                <li>
                  <Link to="/agents/login">סוכנים</Link>
                </li>
              </ul>
            </div>
            <div className="top-logo logo-con">
              <Link to="/">
                <img alt="Visa logo" src={whiteLogoSrc} />
              </Link>
            </div>
          </div>

          <div className="white-line-sep" />

          <div className="footer-middle">
            <div>
              <ul>
                <li>
                  <h5>
                    <b>צרו קשר</b>
                  </h5>
                </li>
                <li>סניף ראשי: {config.ourAddress}</li>
                <li>
                  טלפון משרד:{" "}
                  <a className="text-decoration-none color-white" href={"tel:" + config.supportNumber}>
                    {config.supportNumber}
                  </a>
                </li>
                {/* <li>
                  <a
                    className="text-decoration-none color-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wa.me/+972502009192"
                  >
                    וואטסאפ
                  </a>
                </li> */}
                <li>
                  <a className="color-white" href={`mailto:${config.supportEmail}`}>
                    דוא"ל: {config.supportEmail}
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <h5>
                    <b>מדינות נפוצות</b>
                  </h5>
                </li>

                <>
                  <li>
                    <Link to="/visa-type/israel/united%20states%20of%20america" className="color-white">
                      ויזה לארה"ב
                    </Link>
                  </li>
                  <li>
                    <Link to="/visa-type/israel/china" className="color-white">
                      ויזה לסין
                    </Link>
                  </li>
                  <li>
                    <Link to="/visa-type/israel/australia" className="color-white">
                      ויזה לאוסטרליה
                    </Link>
                  </li>

                  <li>
                    <Link to="/visa-type/israel/united%20arab%20emirates" className="color-white">
                      ויזה לדובאי
                    </Link>
                  </li>
                </>
              </ul>
            </div>

            <div style={{ fontSize: "13px" }}>
              לחברת VISA TO GO ניסיון של שנים בהנפקת כל סוגי הויזות לכלל סוגי הדרכונים ובפרט לדרכונים ישראליים. החברה עוסקת בטיפול בויזות
              לכ-30 המדינות אשר דורשות ויזת שהייה לדרכון ישראלי. אנו מתחייבים למתן שירות מקצועי, יעיל ואמין לכל אדם, לסודיות ולפרטיות.
              בנוסף, צוות החברה נמצא בקשר מתמיד עם הקונסוליות השונות על מנת לתת את השירות העדכני והטוב ביותר. אצלנו הקשר האישי עם הלקוח הוא
              מעל הכל – שירות אישי כולל משלוחים עד בית הלקוח.
            </div>
          </div>

          <div className="white-line-sep" />

          <div className="center pb-30">כל הזכויות שמורות ל- "מרכז הויזות"</div>
        </div>
      )}
    </div>
  </div>
);

export default Footer;
