import React from "react";

function VisaTypeCard(props) {
  return (
    <div onClick={props.onFocusChanged} className={`visa-opt-card${props.isFocused ? " visa-opt-card-focused" : ""}`}>
      <div className="pt-20">
        <b>{props.visaType.stayAllowance}</b>
      </div>
      <div className="mt-10">
        <h4 className="center">
          <b>{props.visaType.visaTitle}</b>
        </h4>
      </div>

      <div>
        <h6 className="center">{props.visaType.visaEntries}</h6>
      </div>

      <div className="lightgray-brand mt-25">
        <h6 className="center">מחיר אגרה</h6>
        <h6 className="center mt-minus10">
          <b className="font-25">{props.visaActualPrice}</b> ש"ח (לאדם)
        </h6>
      </div>
    </div>
  );
}

export default VisaTypeCard;
