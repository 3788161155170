import React from "react";
import config from "../../config";
import B2CHomeContainer from "./b2c/B2cHomeContainer";
import B2bHomeContainer from "./b2b/B2bHomeContainer";
import { useHistory } from "react-router-dom";

export default function HomeContainer() {
  const history = useHistory();

  if (config.isWebsiteB2C) {
    return <B2CHomeContainer history={history} />;
  } else {
    return <B2bHomeContainer history={history} />;
  }
}
