import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";

const isWebsiteB2C = config.isWebsiteB2C;
const generalErrMsg = "There appears to be a problem right now. Please try again later.";

const interceptors = {
  setupInterceptors: (history, toggleLoader) => {
    axios.interceptors.request.use((requestConfig) => {
      if (!requestConfig.preventLoading) toggleLoader(true);
      const token = localStorage.getItem("token");
      const preferredLang = localStorage.getItem("currentLang");
      if (token) requestConfig.headers.authorization = `Bearer ${token}`;
      if (preferredLang) requestConfig.headers.preferredLang = preferredLang;
      if (isWebsiteB2C) requestConfig.headers.iswebsiteb2c = true;

      return requestConfig;
    });

    axios.interceptors.response.use(
      (response) => {
        toggleLoader(false);

        if (response && response.data) {
          if (response.data.needsLogin) {
            var currentLang = localStorage.getItem("currentLang");
            var langIcon = localStorage.getItem("langIcon");
            var layoutDir = localStorage.getItem("layoutDir");
            localStorage.clear();
            localStorage.setItem("currentLang", currentLang);
            localStorage.setItem("langIcon", langIcon);
            localStorage.setItem("layoutDir", layoutDir);
            document.location.href = "/agents/login";
            return;
          } else if (response.data.err) {
            Swal.fire("", generalErrMsg, "warning");
          }
        }

        return response;
      },
      (error) => {
        toggleLoader(false);

        if (error.request && error.request.status === 429) {
          Swal.fire("", "Too many requests made", "warning").then(() => {
            window.location.href = "/";
          });
        } else {
          Swal.fire("", generalErrMsg, "warning");
        }
        return error;

        // localStorage.clear();
        // document.location.href = "/guest/sign-in";
        // if (error.response.status === 404) {
        // history.push("/not-found");
        // }
      }
    );
  }
};

export default interceptors;
