import React, { Component, Fragment } from "react";
// import PageTitle from "../../globalComponents/widgets/PageTitle.js";
import Grid from "@material-ui/core/Grid";
import ContactForm from "./contactForm";
import HomeBanner from "../home/b2b/homeBanner";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import visaService from "../../Services/visaService";
import config from "../../config";
import B2CHomeBanner from "../home/b2c/b2cHomeBanner";

class ContactUs extends Component {
  state = { pageTxt: {} };

  render() {
    const { pageTxt } = this.state;
    return (
      <Fragment>
        {config.isWebsiteB2C ? <B2CHomeBanner /> : <HomeBanner />}

        <div>
          <div className="container relative mt-minus100">
            <Grid container spacing={4}>
              <Grid item lg={5}>
                <div className="mb-30">
                  <h1 className="mb-60">מידע ליצירת קשר</h1>
                  <h5 className="font-italic mb-20">
                    אם יש לך שאלות, הצעות או פידבק אז בבקשה תרגיש חופשי ליצור איתנו קשר. בכל שעליך לעשות זה לבחור את דרך ההתקשרות איתנו. אנו
                    נשמח מאוד לשמוע ממך
                  </h5>
                </div>

                <div className="mb-20 right">
                  <h3>דוא"ל ליצירת קשר</h3>
                  <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
                </div>

                <div className="mb-20 right">
                  <h3 className="mb-10">מספר טלפון</h3>
                  <a className="text-decoration-none black-color" href={"tel:" + config.supportNumber}>
                    03-7705660
                  </a>
                </div>

                <div className="mb-20 right">
                  <h3>כתובת</h3>
                  <p> {config.ourAddress}</p>
                </div>

                {/* <div className="mb-20 right">
                  <h3>וואטסאפ</h3>
                  <p>
                    <a
                      className="text-decoration-none black-color"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/+972502009192"
                    >
                      0502009192
                    </a>
                  </p>
                </div> */}
              </Grid>
              <Grid item lg={7} xs={11}>
                <div className="contact-form-inner">
                  <div className="mb-50">
                    <h1>כתוב לנו</h1>
                  </div>
                  <ContactForm pageTxt={pageTxt} onSubmit={this.onSubmit} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fragment>
    );
  }

  onSubmit = (formData, callback) => {
    visaService.saveNewContactUsReq(formData).then((res) => {
      if (res["success"]) {
        Swal.fire("תודה שפנית אלינו", "אנחנו הולכים להיות איתך בקשר בקרוב", "success").then(callback);
      } else {
        Swal.fire("", "משהו השתבש...", "warning");
      }
    });
  };
}

const mapStateToProps = (state) => {
  return {
    currentLang: state.appSettingsReducer.currentLang
  };
};

export default connect(mapStateToProps)(ContactUs);
