export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isObjectNotEmpty = (obj) => Object.keys(obj).length;

export const removeAllNonNumberChars = (inputString) => inputString.replace(/[^0-9]/g, "");

export const isFileImage = (file) => file && (file.type.split("/")[0] === "image" || file.type === "application/pdf");

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str[0].toUpperCase() + str.substring(1);
};

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
