import React from "react";

function ShowPaymentResult(props) {
  const tranzillaParams = props.match.params.tranzillaParams;
  const tranzilaRes = props.match.params.tranzilaRes;

  console.log(tranzilaRes, tranzillaParams);
  if (tranzilaRes === "success" && tranzillaParams) {
    window.parent.postMessage({ isSuccessPayment: true, customPayment: true }, "*");
  } else {
    window.parent.postMessage({ isSuccessPayment: false, customPayment: true }, "*");
  }

  return <div></div>;
}

export default ShowPaymentResult;
