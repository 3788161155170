import React, { Component, Fragment } from "react";
import { Card, CardContent, TextField, Button, CardHeader } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../../config";
import adminService from "../../../Services/adminService";
import AddNewFromTo from "./addNewFromTo";
import "../../../assets/css/fromToManager.css";
import Swal from "sweetalert2";

class ExistingFromToOptFields extends Component {
  state = {
    includedFields: [],
    excludedFields: [],
    includedFieldsCopy: [],
    excludedFieldsCopy: [],
    includedFilter: "",
    excludedFilter: "",
    selectedFile: null
  };

  componentDidMount() {
    var excludedFields = [];
    var includedFields = this.props.selectedFromToTypeOpt.fields;

    for (const field of this.props.allPossibleFields) {
      if (!includedFields.includes(field)) excludedFields.push(field);
    }

    var excludedFieldsCopy = [...excludedFields];
    var includedFieldsCopy = [...includedFields];

    this.setState({ includedFields, excludedFields, includedFieldsCopy, excludedFieldsCopy });
  }

  render() {
    var selectedFromToTypeOpt = this.props.selectedFromToTypeOpt;
    return (
      <div className="mt-20">
        {selectedFromToTypeOpt.downloadVisa ? (
          <Card className="min-height-400">
            <CardContent>
              <div className="arrows-separators">
                <div onClick={this.handleFinalChanges} className="save-fields-wrapper mt-10">
                  <i className="material-icons primary-color mt-10">check</i>
                </div>
              </div>
              <div>
                <div className="mt-70">
                  <label htmlFor="file-input" className="upload-file-btn ml-10 mr-10">
                    Upload File
                    <i className="material-icons attach-icon-align">attachment</i>
                  </label>
                  <div>
                    {this.state.selectedFile
                      ? this.state.selectedFile.name
                      : selectedFromToTypeOpt.downloadableFile && selectedFromToTypeOpt.downloadableFile.originalname
                      ? selectedFromToTypeOpt.downloadableFile.originalname
                      : ""}
                  </div>
                </div>

                <input id="file-input" style={{ visibility: "hidden" }} type="file" onChange={this.onFileSelected}></input>

                {this.state.selectedFile ? (
                  <Fragment>
                    <div>
                      <Button
                        onClick={() => {
                          this.props.attachFileForDownload(this.state.selectedFile);
                        }}
                        className="btn-width"
                        color="primary"
                        variant="contained"
                      >
                        Confirm
                      </Button>
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </CardContent>
          </Card>
        ) : (
          <Fragment>
            <div className="width40">
              <Card className="min-height-400">
                <CardContent>
                  <TextField id="excludedFilter" value={this.state.excludedFilter} onChange={this.handleChange} placeholder="Search..." />
                  <hr />
                  {this.state.excludedFields
                    ? this.state.excludedFields.map((field, index) => {
                        return (
                          <div key={index + "aa"} className="inline-block padding5">
                            <Button
                              onClick={() => {
                                this.clickedBtnExcludeOrInclude("include", field);
                              }}
                              variant="contained"
                              className="excluded-fields"
                            >
                              {field}
                            </Button>
                          </div>
                        );
                      })
                    : null}
                </CardContent>
              </Card>
            </div>
            <div className="arrows-separators min-height-400">
              <div onClick={this.handleFinalChanges} className="save-fields-wrapper mt-10">
                <i className="material-icons primary-color mt-10">check</i>
              </div>

              <div className="actual-arrows-place">
                <i className="material-icons primary-color">compare_arrows</i>
              </div>
            </div>
            <div className="width40">
              <Card className="min-height-400">
                <CardContent>
                  <TextField id="includedFilter" value={this.state.includedFilter} onChange={this.handleChange} placeholder="Search..." />

                  <hr />
                  {this.state.includedFields
                    ? this.state.includedFields.map((field, index) => {
                        return (
                          <div key={index + "ab"} className="inline-block padding5">
                            <Button
                              onClick={() => {
                                this.clickedBtnExcludeOrInclude("exclude", field);
                              }}
                              variant="contained"
                              className="included-fields"
                            >
                              {field}
                            </Button>
                          </div>
                        );
                      })
                    : null}
                </CardContent>
              </Card>
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  onFileSelected = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  };

  handleChange = (e) => {
    var newState = { [e.target.id]: e.target.value };

    if (e.target.id === "excludedFilter") {
      var excludedFields;
      if (e.target.value) {
        excludedFields = this.state.excludedFieldsCopy.filter((item) => item.toLowerCase().includes(e.target.value));
      } else {
        excludedFields = [...this.state.excludedFieldsCopy];
      }
      newState["excludedFields"] = excludedFields;
    } else if (e.target.id === "includedFilter") {
      var includedFields;
      if (e.target.value) {
        includedFields = this.state.includedFieldsCopy.filter((item) => item.toLowerCase().includes(e.target.value));
      } else {
        includedFields = [...this.state.includedFieldsCopy];
      }
      newState["includedFields"] = includedFields;
    }
    this.setState(newState);
  };

  clickedBtnExcludeOrInclude = (actionToDo, fieldKey) => {
    var state = this.state;
    var includedFieldsCopy = [...state.includedFieldsCopy];
    var excludedFieldsCopy = [...state.excludedFieldsCopy];
    var excludedFields = [...state.excludedFields];
    var includedFields = [...state.includedFields];

    if (actionToDo === "include") {
      includedFieldsCopy.unshift(fieldKey);
      includedFields.unshift(fieldKey);
      excludedFieldsCopy = excludedFieldsCopy.filter((item) => item !== fieldKey);
      excludedFields = excludedFields.filter((item) => item !== fieldKey);
    } else {
      // exclude
      excludedFieldsCopy.unshift(fieldKey);
      excludedFields.unshift(fieldKey);
      includedFieldsCopy = includedFieldsCopy.filter((item) => item !== fieldKey);
      includedFields = includedFields.filter((item) => item !== fieldKey);
    }

    this.setState({ includedFieldsCopy, excludedFieldsCopy, excludedFields, includedFields });
  };

  handleFinalChanges = () => {
    this.props.handleFinalChanges(this.state.includedFieldsCopy);
  };
}

export default ExistingFromToOptFields;
