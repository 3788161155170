import axios from "axios";
import config from "../config";

const services = {
  createNewFromToOpt: function (from, to, visaType) {
    return new Promise((resolve) => {
      axios.post(config.createNewFromToOptEndpoint, { from, to, visaType }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateFromToVisaTypeFields: function (data) {
    return new Promise((resolve) => {
      axios.post(config.newFieldsFromToOptEndpoint, data).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllVisas: (query) => {
    return new Promise((resolve) => {
      axios.get(config.processorGetAllVisasEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  handleVisasChanges: (updates) => {
    return new Promise((resolve) => {
      axios.post(config.handleVisasChangesEndpoint, { updates }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteFromToVisaTypeOpt: function (from, to, visaType) {
    return new Promise((resolve) => {
      axios.post(config.deleteFromToVisaTypeOptEndpoint, { from, to, visaType }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  saveFieldChanges: function (fieldChanges) {
    return new Promise((resolve) => {
      axios.post(config.processorFieldChangesEndpoint, { fieldChanges }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  deleteField: function (fieldKey) {
    return new Promise((resolve) => {
      axios.delete(config.deleteFieldKeyEndpoint + "?fieldKey=" + fieldKey).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  handleAgencyChanges: (updates, action) => {
    return new Promise((resolve) => {
      const { agencyName, agencyStatus, agencyContact, agencyMobile, agencyEmail, _id, discount, isShovarRequired } = updates;
      axios
        .post(config.handleAgencyChangesEndpoint, {
          agencyName,
          agencyStatus,
          agencyContact,
          agencyMobile,
          agencyEmail,
          action,
          discount,
          isShovarRequired,
          agencyId: _id
        })
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          resolve({ err: "Problem with the request" });
        });
    });
  },

  performResendEmailConf: (agentEmail) => {
    return new Promise((resolve) => {
      axios.get(config.processorResendEmailConfEndpoint + "?email=" + agentEmail).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllEmployees: () => {
    return new Promise((resolve) => {
      axios.get(config.getAllEmployeesEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  handleAgentChanges: (updates, action, agencyId, agentType) => {
    return new Promise((resolve) => {
      const { agentStatus, agentFirstName, agentLastName, agentMobile, agentEmail, _id } = updates;
      axios
        .post(config.handleAgentChangesEndpoint, {
          agentStatus,
          agentFirstName,
          agentLastName,
          agentMobile,
          agentEmail,
          action,
          agentId: _id,
          agencyId,
          agentType
        })
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          resolve({ err: "Problem with the request" });
        });
    });
  },

  attachFileForDownload: (from, to, visaType, selectedFile) => {
    return new Promise((resolve) => {
      var fd = new FormData();
      fd.append("file", selectedFile, selectedFile.name);

      var buildLink = `${config.attachFileForDownloadEndpoint}?from=${from}&to=${to}&visaType=${visaType}`;
      axios.post(buildLink, fd).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAgencyReport: (data) => {
    return new Promise((resolve) => {
      axios.post(config.getAgencyReportEndpoint, data).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAgencyVouchers: (data) => {
    return new Promise((resolve) => {
      axios({
        url: config.getAgencyVouchersEndpoint,
        method: "post",
        responseType: "blob",
        data
      }).then((response) => {
        if (!response || !response.data) return resolve({ err: "Problem with request" });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "קבצים.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
        return resolve({ success: true });
      });
    });
  },

  exportExcelClientSide(data, overrideHeaders = {}, isInForceOnlyInputsFromHeaders, titleAddedToTop) {
    const csvRows = [];

    let headers;
    if (isInForceOnlyInputsFromHeaders) {
      // Take only specified rows
      headers = Object.keys(overrideHeaders);
    } else {
      // Detect rows alone if not specified
      headers = Object.keys(data[0]);
    }
    const displayHeaders = headers.map((header) => overrideHeaders[header] || header);

    if (titleAddedToTop) {
      const excelTitle = titleAddedToTop + Array.from({ length: headers.length - 1 }, () => ",");
      csvRows.push(excelTitle);
    }

    csvRows.push(displayHeaders.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        if (escaped === "undefined" || escaped === "null" || !escaped) return "";
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const csvFormat = csvRows.join("\n");
    // this BOM lets the file be hebrew
    var BOM = "\uFEFF";
    const blob = new Blob([BOM + csvFormat], { type: "text/csv" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.setAttribute("href", url);
    link.setAttribute("download", titleAddedToTop || new Date() + ".csv");
    link.click();
  },

  getAllStatuses() {
    return [
      { key: "AGENT_PAID_AND_FILLS", label: "ממתין לטיפולך" },
      { key: "AGENT_PAID_CLIENT_FILLS", label: "ממתין לטיפול הלקוח שלך" },
      { key: "WAITING_PROCESSOR_PAID", label: "אנו מטפלים בבקשה" },
      { key: "WAITING_PROCESSOR_NOT_PAID", label: " אנו מטפלים בבקשה לא שולם" },
      { key: "WAITING_IN_OFFICE", label: "נמצא במשרד" },
      { key: "GOT_INTO_EMBASSY", label: "נכנס לקונסוליה" },
      { key: "WAITING_FOR_DOCUMENTS", label: "ממתינים למסמכים נוספים" },
      { key: "ACCES_GRANTED", label: "קיבל אשרה" },
      { key: "WITHOUT_TREATMENT", label: "ללא טיפול" },
      { key: "DENIED", label: "סירוב" },
      { key: "REFUND", label: "החזר כספי" }
    ];
  },

  async getGroupsByAgentId(agentId) {
    const res = await axios.get(config.getAgentGroups + agentId);
    return res.data;
  },

  async addGroup(groupData, agentInfo) {
    const body = { groupData: { ...groupData, ...agentInfo } };
    const res = await axios.post(config.addGroup, body);
    return res.data.groupId;
  },

  async getAgentById(agentId) {
    const res = await axios.get(config.getAgentById + agentId);
    return res.data;
  },

  async deleteGroupById(groupId) {
    const res = await axios.delete(config.deleteGroupById, { data: { groupId } });
    return res.data;
  },

  async updateGroupById(groupUpdates) {
    const { _id: groupId, ...updates } = groupUpdates;
    const body = { groupUpdateInfo: { groupId, updates } };
    const res = await axios.put(config.updateGroupById, body);
    return res.data;
  },

  async getGroupById(groupId) {
    const res = await axios.get(config.getGroupById + groupId);
    return res.data;
  },

  async getGroupParticipants(groupId) {
    const res = await axios.get(config.getGroupParticipants + groupId);
    return res.data;
  },

  async addParticipantToGroup(form) {
    const res = await axios.post(config.addParticipantToGroup, form, { headers: { "Content-Type": "multipart/form-data" } });
    return res.data;
  },

  async updateParticipantById(userId, updates) {
    const res = await axios.put(config.updateParticipantById, { userUpdateInfo: { userId, updates } });
    return res.data;
  },

  async agentDownloadUserFile(fileName) {
    const res = await axios.get(config.agentDownloadUserFileEndpoint + fileName);
    return res.data;
  },

  async agentDownloadUserFileFromSubfolder(fileName, subfolder) {
    const res = await axios.get(config.agentDownloadUserFileFromSubfolderEndpoint + subfolder + "/" + fileName);
    return res.data;
  },

  async attachVisaFilesMulti(files, fileKeys, visaId, passportNum) {
    const fd = new FormData();
    for (let i = 0; i < files.length; i++) {
      const currentFile = files[i];
      const fileSuffix = currentFile.name.substring(currentFile.name.lastIndexOf("."));
      fd.append("files", currentFile, fileKeys[i] + fileSuffix);
    }

    const res = await axios.post(`${config.attachVisaFilesMultiEndpoint}?visaId=${visaId}&passportNum=${passportNum}`, fd);
    return res.data;
  }
};

export default services;
