import React, { Component } from "react";
import B2CPopularCard from "./b2cPopularDestinations/b2cPopularCard";
import destinationIcon from "../../../assets/images/destination-icon.svg";
import formChoiceImage from "../../../assets/images/form-choice.png";
import travelIcon from "../../../assets/images/travel-icon.svg";
import planeImage from "../../../assets/images/plane-image.png";
import { BeachAccessOutlined, BusinessCenterOutlined } from "@material-ui/icons";

const onlineSteps = ["ממלאים טופס אונליין", "מקבלים את הויזה ישירות לאימייל", "נכנסים בבטחה ליעד המבוקש"];
const offlineSteps = [
  "ממלאים את הטפסים ומצרפים את יתר הדרישות",
  "שולחים את הטפסים עם הדרכון",
  "מקבלים את הדרכון עם הויזה שהונפקה באמצעות שליח"
];

class B2CVisaSteps extends Component {
  state = {
    isOnlineVisa: true,
    currentDisplayedSteps: onlineSteps
  };

  render() {
    return (
      <div className="mt-50 pt-50 pb-50">
        <h3 className="primary-color center heb-bold mb-40">
          <b>איך מקליקים ומנפיקים ויזה בקלות</b>
        </h3>

        <div className="container">
          <div className="b2c-step-container mt-50">
            <div className="b2c-step-image relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="338"
                height="344"
                viewBox="0 0 338 344"
                fill="none"
                className="absolute b2c-step-background"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M159.422 0.859437C196.228 1.95142 230.042 17.8392 260.251 38.8947C290.995 60.3232 321.406 85.6436 332.445 121.456C343.406 157.017 334.173 195.545 319.012 229.529C304.996 260.944 279.043 283.795 251.08 303.832C223.297 323.74 193.586 344.882 159.422 343.886C125.663 342.901 98.7291 318.668 71.5686 298.594C44.6503 278.699 12.8393 260.488 3.20717 228.432C-6.35689 196.602 13.4248 165.122 21.7992 132.958C30.8629 98.1481 28.1987 57.6894 54.1495 32.7801C81.267 6.7509 121.85 -0.255244 159.422 0.859437Z"
                  fill="#D1E6FF"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="76"
                height="122"
                viewBox="0 0 76 122"
                fill="none"
                className="absolute b2c-step-path-one"
              >
                <path
                  d="M0.586182 119.981C0.586182 119.981 79.81 97.0949 73.3866 55.6288C66.9633 14.1626 0.586182 2.31476 0.586182 2.31476"
                  stroke="#73C92B"
                  strokeWidth="4"
                  strokeDasharray="12 12"
                />
              </svg>
              <div className="b2c-step-icon-container b2c-step-destination-icon background-success absolute">
                <img src={destinationIcon} alt="destination Icon" className="b2c-step-icon" />
              </div>
              <div className="b2c-step-card">
                <B2CPopularCard cardData={{ to: "australia" }} history={this.props.history} />
              </div>
            </div>
            <div className="b2c-step-content">
              <h3 className="b2c-step-title primary-color b2c-weight600">בחר את היעד שאתה רוצה לבקר בו</h3>
              <p className="b2c-step-description b2c-weight600">
                בחר את המדינה שאליה תרצה לנסוע. הקפד לבדוק את דרישות הוויזה הספציפיות עבור יעד זה
              </p>
              <p className="b2c-step-link primary-color b2c-weight600 mt-minus10" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>בחר את היעד שלך כאן ←</p>
            </div>
          </div>

          <div className="b2c-step-container mt-50">
            <div className="b2c-step-content">
              <h3 className="b2c-step-title primary-color b2c-weight600">בחרו את סוג הויזה המתאים ומלאו את הטופס</h3>
              <p className="b2c-step-description b2c-weight600">
                בחרו את סוג הויזה המתאים למטרת הנסיעה שלכם (תיירות, עסקים, לימודים וכו') ומלאו את הטופס להורדה
              </p>
            </div>
            <div className="b2c-step-image relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="337" height="304" viewBox="0 0 337 304" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M187.904 0.180664C267.184 0.180664 336.707 58.9953 336.707 138.275C336.707 225.229 274.858 303.911 187.904 303.911C90.1174 303.911 0.117187 236.062 0.117188 138.275C0.117188 47.2452 96.8741 0.180664 187.904 0.180664Z"
                  fill="#D1E6FF"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="97"
                viewBox="0 0 125 97"
                fill="none"
                className="absolute b2c-step-path-two"
              >
                <path
                  d="M1.40796 31.996L89.9739 2.474C152.81 32.1968 110.102 100.433 89.9739 84.3305C69.8453 68.2276 1.40796 95.0657 1.40796 95.0657"
                  stroke="#73C92B"
                  strokeWidth="4"
                  strokeDasharray="12 12"
                />
              </svg>
              <div className="b2c-step-icon-container b2c-step-business-icon background-success absolute">
                <BusinessCenterOutlined className="b2c-step-icon color-white" />
              </div>
              <div className="b2c-step-icon-container b2c-step-umbrella-icon background-success absolute">
                <BeachAccessOutlined className="b2c-step-icon color-white" />
              </div>
              <div className="b2c-step-illustration">
                <img src={formChoiceImage} alt="Illustration of a form choice" />
              </div>
            </div>
          </div>

          <div className="b2c-step-container mt-50">
            <div className="b2c-step-image relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="338"
                height="344"
                viewBox="0 0 338 344"
                fill="none"
                className="absolute b2c-step-background"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M159.422 0.859437C196.228 1.95142 230.042 17.8392 260.251 38.8947C290.995 60.3232 321.406 85.6436 332.445 121.456C343.406 157.017 334.173 195.545 319.012 229.529C304.996 260.944 279.043 283.795 251.08 303.832C223.297 323.74 193.586 344.882 159.422 343.886C125.663 342.901 98.7291 318.668 71.5686 298.594C44.6503 278.699 12.8393 260.488 3.20717 228.432C-6.35689 196.602 13.4248 165.122 21.7992 132.958C30.8629 98.1481 28.1987 57.6894 54.1495 32.7801C81.267 6.7509 121.85 -0.255244 159.422 0.859437Z"
                  fill="#D1E6FF"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="387"
                height="247"
                viewBox="0 0 387 247"
                fill="none"
                className="absolute b2c-step-path-three"
              >
                <path
                  d="M308.152 49.6252C381.886 114.624 403.378 166.971 367.38 217.091C331.383 267.212 143.1 235.805 117.865 220.191C92.63 204.577 -91.0289 98.5567 63.3606 28.9764C217.75 -40.604 303.346 46.651 303.346 46.651"
                  stroke="#73C92B"
                  strokeWidth="4"
                  strokeDasharray="12 12"
                />
              </svg>
              <div className="b2c-step-icon-container b2c-step-travel-icon background-success absolute">
                <img src={travelIcon} alt="destination Icon" className="b2c-step-icon" />
              </div>
              <div className="b2c-step-plane-image">
                <img src={planeImage} alt="Plane" />
              </div>
            </div>
            <div className="b2c-step-content">
              <h3 className="b2c-step-title primary-color b2c-weight600">קבלו את הוויזה האלקטרונית במייל וסעו ליעד</h3>
              <p className="b2c-step-description b2c-weight600">
                לאחר הגשת בקשתכם וביצעתם את התשלום המתאים, תקבלו אישור על הויזה האלקטרונית במייל. הדפס עותק של הוויזה האלקטרונית וקח איתך את
                כל המסמכים הדרושים במהלך הטיול שלך.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleVisaStepsType = (type) => {
    if (type === "online") {
      this.setState({ isOnlineVisa: true, currentDisplayedSteps: onlineSteps });
    } else {
      // offline
      this.setState({ isOnlineVisa: false, currentDisplayedSteps: offlineSteps });
    }
  };
}

export default B2CVisaSteps;
