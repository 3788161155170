import React, { useEffect, useState } from "react";
import B2CHomeBanner from "./b2cHomeBanner";
import B2COriginDestination from "./b2cOriginDestination";
import B2CPopularContainer from "./b2cPopularDestinations/b2cPopularContainer";
import B2CVisaSteps from "./b2cVisaSteps";
import B2CHomeFAQ from "./b2cHomeFAQ";

import visaService from "../../../Services/visaService";

import "../../../assets/css/b2cHome.css";

const B2CHomeContainer = ({ history }) => {
  const [allFromTo, setAllFromTo] = useState(null);
  const [fromOnly, setFromOnly] = useState(null);

  useEffect(() => {
    visaService.getAllFromToOpts().then((res) => {
      if (res["success"]) {
        const getAllFromTo = res["allFromTo"] || [];
        setAllFromTo(getAllFromTo);
        setFromOnly(getAllFromTo.filter((v, i, a) => a.findIndex((t) => t.from === v.from) === i));
      }
    });
  }, []);

  return (
    <div className="mb-40">
      <B2CHomeBanner />
      {allFromTo && fromOnly && (
        <>
          <B2COriginDestination allFromTo={allFromTo} fromOnly={fromOnly} history={history} />
          <B2CPopularContainer allFromTo={allFromTo} history={history} />
        </>
      )}

      <B2CVisaSteps history={history} />

      <B2CHomeFAQ />
    </div>
  );
};

export default B2CHomeContainer;
