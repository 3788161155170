import React from "react";
import { Switch } from "react-router-dom";

import AdminNav from "../layout/adminNav";
import FieldsManager from "./fieldManager/fieldManager";
import FromToManager from "./fromToManager/fromToManager";
import AgenciesManager from "./agentsManager/agenciesManager";
import AgentsManager from "./agentsManager/agentsManager";
import GroupsManager from "./agentsManager/groupsManager";
import GroupInfo from "./agentsManager/groupInfo";

import ProcessorGuard from "../../protectedRoutes/processorGuard";
import "../../assets/css/generalAdmin.css";
import VisaManager from "./visaManager/VisaManager";

const AdminContainer = () => {
    return (
      <div>
        <AdminNav />
        <div className="center container mt-30">
          <Switch>
            <ProcessorGuard path="/admin/fields-manager" component={FieldsManager} />
            <ProcessorGuard path="/admin/from-to" component={FromToManager} />
            <ProcessorGuard path="/admin/agencies" component={AgenciesManager} />
            <ProcessorGuard path="/admin/visaManager" component={VisaManager} />
            <ProcessorGuard path="/admin/specific-agency/:agencyId/:agencyName" component={AgentsManager} />
            <ProcessorGuard path="/admin/agent-groups/:agentId" component={GroupsManager} />
            <ProcessorGuard path="/admin/group-info/:groupId" component={GroupInfo} />
          </Switch>
        </div>
      </div>
    );
}

export default AdminContainer;
