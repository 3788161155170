import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProcessorGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const role = localStorage.getItem("role");
        if (role === "processor" || role === "admin" || role === "employee") {
          return <Component {...props} role={role} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loggedinReducer.isLoggedIn
  };
};

export default connect(mapStateToProps)(ProcessorGuard);
