const yup = require("yup");
const customMsgs = require("./validationCustomMsgs");
const moment = require("moment");

var defaultMinDate = -200;
var defaultMaxDate = 200;
var defaultMinStringLength = 2;
var defaultMaxStringLength = 200;

export default function (key, validations) {
  var yupObject;
  if (validations.validationType === "dateType") {
    let { dateStart, dateStartToday, dateEnd, dateEndToday } = validations;
    if (dateStart) dateStart = new Date(dateStart);
    if (dateEnd) dateEnd = new Date(dateEnd);
    var dateValidations = getDate(dateStart, dateEnd, dateStartToday, dateEndToday);
    yupObject = yup
      .object()
      .shape({ [key]: dateValidations })
      .required();
  } else if (validations.validationType === "txtType") {
    yupObject = yup.object().shape({
      [key]: txt(validations.min, validations.max, validations.trim, validations.lowercase, validations.email).required()
    });
  } else if (validations.validationType === "numberType") {
    yupObject = yup.object().shape({
      [key]: yup
        .number()
        .min(parseInt(validations.min), customMsgs.smallNumErr.replace("%replace%", validations.min))
        .max(parseInt(validations.max), customMsgs.bigNumErr.replace("%replace%", validations.max))
    });
  }
  return yupObject;
}

function txt(min, max, isTrim, isLowerCase, isEmail) {
  var textLaws = yup
    .string()
    .min(min ? parseInt(min) : defaultMinStringLength, customMsgs.minErr.replace("%replace%", min))
    .max(max ? parseInt(max) : defaultMaxStringLength, customMsgs.maxErr.replace("%replace%", max));
  if (isTrim) textLaws = textLaws.trim();
  if (isLowerCase) textLaws = textLaws.lowercase();
  if (isEmail) textLaws = textLaws.email(customMsgs.emailErr);
  return textLaws;
  // return textLaws.matches(/^[\x00-\x7F]*$/, customMsgs.onlyEngErr);
}

function getDate(start = getYears(defaultMinDate), end = getYears(defaultMaxDate), startToday, endToday) {
  if (startToday) start = new Date();
  if (endToday) end = new Date();
  return yup
    .date()
    .transform((dateFormat, stringFormat) => new Date(stringFormat))
    .min(start, customMsgs.tooEarlyDateErr.replace("%replace%", moment(start).format("DD/MM/YYYY")))
    .max(end, customMsgs.tooLateDateErr.replace("%replace%", moment(end).format("DD/MM/YYYY")));
}

function getYears(yearsToSum) {
  var currentDate = new Date();
  return new Date(currentDate.setFullYear(currentDate.getFullYear() + yearsToSum));
}
