import React, { Component, Fragment } from "react";
import visaService from "../../../Services/visaService";
import config from "../../../config";
import { Card, CardContent, FormControlLabel, TextField, Button, Select, Checkbox, MenuItem, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LangOptions from "./langOptions";
import FieldValidation from "./fieldValidation";

import "../../../assets/css/fileManager.css";
import adminService from "../../../Services/adminService";
import Swal from "sweetalert2";

class FieldsManager extends Component {
  state = {
    listOfFields: [],
    curFieldLangs: [],
    existingKeyInWork: "",
    isResettingList: false,
    langsAmount: "",
    optionsAmount: "",
    key: "",
    type: "",
    isOtherDepended: false,
    otherKeyDepended: "",
    dependedOn: "",
    dependedOnNot: "",
    dontSave: "",
    notAllowedBack: "",
    skipOnContinue: "",
    loopVals: {},
    validation: {}
  };

  componentDidMount() {
    visaService.getVisaFields().then((res) => {
      if (res["success"]) {
        this.setState({ listOfFields: res["neededFields"] });
      }
    });
  }

  render() {
    return (
      <div className="center width100 ltr-layout mb-30">
        <Card>
          <CardContent>
            <h4 className="yellow-color">שדות</h4>

            <div className="width50 inline-block">
              <h5>שדה בעבודה</h5>
              <div className="mt-20 relative">
                {this.state.existingKeyInWork ? (
                  <div style={{ position: "absolute", left: "0px" }}>
                    <Button onClick={this.deleteField} variant="contained">
                      Delete
                    </Button>
                  </div>
                ) : null}
                <div>
                  <TextField
                    id="key"
                    label="Key"
                    onChange={this.handleChange}
                    disabled={this.state.existingKeyInWork ? true : false}
                    value={this.state.key}
                  />
                </div>
                <div className="mt-10">
                  <TextField id="fieldWeight" label="Weight" onChange={this.handleChange} value={this.state.fieldWeight || ""} />
                </div>
                <div className="mt-20">
                  Type
                  <Select
                    id="type"
                    className="select-default"
                    value={this.state.type}
                    onChange={(e) => {
                      this.handleChange({ target: { value: e.target.value, id: "type" } });
                    }}
                    displayEmpty
                  >
                    <MenuItem value="txt">txt</MenuItem>
                    <MenuItem value="date">date</MenuItem>
                    <MenuItem value="number">number</MenuItem>
                    <MenuItem value="radioBtns">radioBtns</MenuItem>
                    <MenuItem value="radioCircles">radioCircles</MenuItem>
                    <MenuItem value="scroller">scroller</MenuItem>
                    <MenuItem value="msgToClient">msgToClient</MenuItem>
                    <MenuItem value="autocomplete">Autocomplete</MenuItem>
                    <MenuItem value="countriesAutocomplete">Country list autocomplete</MenuItem>
                    <MenuItem value="uploadFile">uploadFile</MenuItem>
                  </Select>
                </div>

                <div className="mt-20">
                  langs
                  <Select
                    id="langsAmount"
                    value={this.state.langsAmount}
                    className="select-default"
                    onChange={(e) => {
                      this.handleChange({ target: { value: e.target.value, id: "langsAmount" } });
                    }}
                    displayEmpty
                  >
                    {Array.from(Array(config.amountLangsSupported).keys()).map((item) => {
                      return (
                        <MenuItem key={item + "as"} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <LangOptions
                  langsAmount={this.state.langsAmount}
                  optionsAmount={this.state.optionsAmount}
                  handleChange={this.handleChange}
                  loopVarsChange={this.loopVarsChange}
                  type={this.state.type}
                  loopVals={this.state.loopVals}
                />

                <div className="mt-20">
                  <FormControlLabel
                    checked={this.state.isOtherDepended ? true : false}
                    control={<Checkbox value="isOtherDepended" color="primary" />}
                    label="Field depended"
                    onChange={(e) => {
                      this.handleChange({ target: { value: e.target.checked, id: "isOtherDepended" } });
                    }}
                  />
                </div>
                {this.state.isOtherDepended ? (
                  <div>
                    <TextField
                      id="otherKeyDepended"
                      label="Other key depended"
                      onChange={this.handleChange}
                      value={this.state.otherKeyDepended}
                    />

                    <div className="mt-10">
                      <TextField id="dependedOn" label="Depended on value" onChange={this.handleChange} value={this.state.dependedOn} />
                    </div>

                    <div className="mt-10">
                      <TextField
                        id="dependedOnNot"
                        label="Depended on not value"
                        onChange={this.handleChange}
                        value={this.state.dependedOnNot}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="mt-20">
                  <div className="width140">
                    <div className="left">
                      More:
                      <br />
                      <Tooltip
                        title="ex => firstname,lastname,email and passport NOTE - it does save on a later field being saved"
                        aria-label="add"
                      >
                        <FormControlLabel
                          checked={this.state.dontSave ? true : false}
                          control={<Checkbox value="dontSave" color="primary" />}
                          label="dontSave"
                          onChange={(e) => {
                            this.handleChange({ target: { value: e.target.checked, id: "dontSave" } });
                          }}
                        />
                      </Tooltip>
                      <br />
                      <Tooltip title="ex => first question or after visa creation" aria-label="add">
                        <FormControlLabel
                          checked={this.state.notAllowedBack ? true : false}
                          control={<Checkbox value="notAllowedBack" color="primary" />}
                          label="notAllowedBack"
                          onChange={(e) => {
                            this.handleChange({ target: { value: e.target.checked, id: "notAllowedBack" } });
                          }}
                        />
                      </Tooltip>
                      <br />
                      <Tooltip title="will not ever save the key in the DB - usually will be used to show a msg" aria-label="add">
                        <FormControlLabel
                          checked={this.state.skipOnContinue ? true : false}
                          control={<Checkbox value="skipOnContinue" color="primary" />}
                          label="skipOnContinue"
                          onChange={(e) => {
                            this.handleChange({ target: { value: e.target.checked, id: "skipOnContinue" } });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.type ? (
                <FieldValidation type={this.state.type} validation={this.state.validation} handleChange={this.handleChange} />
              ) : null}

              <div className="mt-30">
                <Button onClick={this.applyFieldChange} color="primary" variant="contained">
                  Apply Changes
                </Button>
              </div>
            </div>

            <div className="width50 inline-block">
              <h5>רשימת שדות קיימים</h5>
              <div className="mt-20">
                {this.state.isResettingList ? null : (
                  <Autocomplete
                    className="custom-autocomplete"
                    options={this.state.listOfFields}
                    getOptionLabel={(option) => option.key}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="שדות שקיימים במערכת" variant="outlined" />}
                    onChange={(event, newInputValue) => {
                      if (newInputValue && newInputValue.key) this.showSpecificFields(newInputValue.key);
                    }}
                  />
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  deleteField = () => {
    Swal.fire({
      title: "האם אתה בטוח?",
      text: "למחוק שדה " + this.state.existingKeyInWork,
      icon: "warning",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        adminService.deleteField(this.state.existingKeyInWork).then((res) => {
          if (res.success) {
            let listOfFields = [...this.state.listOfFields];
            listOfFields = listOfFields.filter((item) => item.key !== this.state.existingKeyInWork);

            this.setState(
              {
                curFieldLangs: [],
                existingKeyInWork: "",
                listOfFields,
                fieldWeight: "",
                langsAmount: "",
                optionsAmount: "",
                key: "",
                type: "",
                isOtherDepended: false,
                otherKeyDepended: "",
                dependedOn: "",
                dependedOnNot: "",
                dontSave: "",
                notAllowedBack: "",
                skipOnContinue: "",
                loopVals: {},
                validation: {},
                isResettingList: true
              },
              () => {
                this.setState({ isResettingList: false });
              }
            );
          } else if (res.notAllowedKeysForRemoval) {
            Swal.fire("Not allowed", "You tried to remove forbidden fields for removal", "warning");
          }
        });
      }
    });
  };

  handleChange = (e) => {
    var newState = { [e.target.id]: e.target.value };
    if (e.target.id === "langsAmount") {
      var curFieldLangs = new Array(parseInt(e.target.value));
      newState["curFieldLangs"] = curFieldLangs;
    }
    this.setState(newState);
  };

  loopVarsChange = (e) => {
    var newState = {};
    var loopVals = { ...this.state.loopVals };
    loopVals[e.target.id] = e.target.value;
    if (e.target.id.includes("optionsAmount")) {
      var correctListNum = this.getCorrectKeyCode(e.target.id, true).split("-")[1]; // format - 'key-num'
      var optionsList = [];
      for (let i = 0; i < e.target.value; i++) {
        optionsList.push({ label: "", val: "" });
      }
      loopVals["options" + correctListNum] = optionsList;
    } else if (e.target.id.includes("langKey")) {
      var correctListNum = this.getCorrectKeyCode(e.target.id, true).split("-")[1]; // format - 'key-num'
      correctListNum = parseInt(correctListNum) - 1;
      var curFieldLangs = [...this.state.curFieldLangs];
      curFieldLangs[correctListNum] = e.target.value;
      newState["curFieldLangs"] = curFieldLangs;
    }
    newState["loopVals"] = loopVals;

    this.setState(newState);
  };

  showSpecificFields = (newInputValue) => {
    this.setState({
      curFieldLangs: [],
      existingKeyInWork: "",
      fieldWeight: "",
      langsAmount: "",
      optionsAmount: "",
      key: "",
      type: "",
      isOtherDepended: false,
      otherKeyDepended: "",
      dependedOn: "",
      dependedOnNot: "",
      dontSave: "",
      notAllowedBack: "",
      skipOnContinue: "",
      loopVals: {},
      validation: {}
    });
    if (!newInputValue) return;

    var listOfDetectedLangs = [];
    var fieldData = this.state.listOfFields.filter((item) => item.key === newInputValue)[0];
    var newState = { existingKeyInWork: newInputValue, loopVals: {} };

    for (const key in fieldData) {
      if (key.includes("-")) {
        var split = key.split("-");
        var langKey = split[1];
        var currentTitle = split[0];

        var getIndex = this.getLangIndex(listOfDetectedLangs, langKey);

        if (currentTitle === "options") {
          var optsArr = fieldData[key];
          var optionsAmount = optsArr.length;
          newState.loopVals["options" + getIndex] = optsArr;
          newState.loopVals["optionsAmount" + getIndex] = optionsAmount;
        } else {
          newState.loopVals["langKey" + getIndex] = langKey;
          newState.loopVals[currentTitle + getIndex] = fieldData[key];
        }
      } else {
        newState[key] = fieldData[key];
      }
    }

    newState["langsAmount"] = listOfDetectedLangs.length;
    newState["curFieldLangs"] = listOfDetectedLangs;

    this.setState(newState);
  };

  getLangIndex = (arr, langKey) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === langKey) return i + 1;
    }
    arr.push(langKey);
    return arr.length;
  };

  applyFieldChange = () => {
    var field = this.getFieldData();
    if (!field) return;
    adminService.saveFieldChanges(field).then((res) => {
      if (res["success"]) {
        Swal.fire("Success", "", "success").then(() => {
          window.location.reload();
        });
      } else if (res["keyExists"]) {
        Swal.fire("", "Key already exists. if you wish you edit that key then please choose it from the list on the right side", "warning");
      }
    });
  };

  getFieldData = () => {
    var state = this.state;
    var field = {};
    if (!state.key || !state.type) {
      Swal.fire("", "Key and Type are mandatory");
      return null;
    }

    field["key"] = state.key;
    if (state.fieldWeight) field["fieldWeight"] = state.fieldWeight;
    field["type"] = state.type;
    field["langsAmount"] = state.langsAmount;

    field["isOtherDepended"] = state.isOtherDepended; // yes or no
    field["otherKeyDepended"] = state.otherKeyDepended; // what is the other key that we depend on
    field["dependedOn"] = state.dependedOn; // a specific value
    field["dependedOnNot"] = state.dependedOnNot; // a specific not value
    field["dontSave"] = state.dontSave;
    field["notAllowedBack"] = state.notAllowedBack;
    field["skipOnContinue"] = state.skipOnContinue;

    for (const key in state.loopVals) {
      if (key.includes("langKey")) continue;
      var currentKey = this.getCorrectKeyCode(key);
      field[currentKey] = state.loopVals[key];
    }

    // validation
    // TODO - make sure this works
    if (state.validation.validationType) {
      field["validation"] = state.validation;
      if (state.validation.validationType === "dateType") {
        delete field["validation"]["max"];
        delete field["validation"]["min"];

        if (field["validation"]["dateEndToday"]) delete field["validation"]["dateEnd"];
        if (field["validation"]["dateStartToday"]) delete field["validation"]["dateStart"];
      }
    }

    field.isNewField = !state.existingKeyInWork ? true : null;

    return field;
  };

  // isNumSuffix - can be langKey or a number
  getCorrectKeyCode = function (key, isNumSuffix) {
    var getSuffixNum = 1;
    if (this.isDigitCode(key[key.length - 2])) getSuffixNum = 2;
    var langIndex = parseInt(key.substring(key.length - getSuffixNum)) - 1;
    var baseKey = key.substring(0, key.length - getSuffixNum);

    var lang = isNumSuffix ? langIndex + 1 : this.state.curFieldLangs[langIndex];

    return baseKey + "-" + lang;
  };

  isDigitCode = function (n) {
    return n >= "0".charCodeAt(0) && n <= "9".charCodeAt(0);
  };
}

export default FieldsManager;
