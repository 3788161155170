import React, { Component } from "react";
import { Card, CardContent } from "@material-ui/core";
import computerImg from "../../../assets/images/computer_icon.png";
import windowsImg from "../../../assets/images/windows_icon.png";
import worldImg from "../../../assets/images/world_icon.png";
import config from "../../../config";
import iconFilled from "../../../assets/images/icon_visa_filled.png"
import checkboxIcon from "../../../assets/images/check-box.png"
import laptopPlane from "../../../assets/images/laptop-plane.png"
import passportPlane from "../../../assets/images/passport-plane.png"


const onlineSteps = ["ממלאים טופס אונליין", "מקבלים את הויזה ישירות לאימייל", "נכנסים בבטחה ליעד המבוקש"];
const offlineSteps = [
  "ממלאים את הטפסים ומצרפים את יתר הדרישות",
  "שולחים את הטפסים עם הדרכון",
  "מקבלים את הדרכון עם הויזה שהונפקה באמצעות שליח"
];

class VisaSteps extends Component {
  state = {
    isOnlineVisa: true,
    currentDisplayedSteps: onlineSteps
  };

  render() {
    return (
      <div className="light-gray-background mt-50 pt-50 pb-50">
        <h3 className="primary-color center heb-bold mb-40">איך מקליקים ומנפיקים ויזה בקלות</h3>

        <div className="container">
          <div className="width70 auto0 center lightgray-brand">
            <div
              onClick={() => this.handleVisaStepsType("online")}
              className={`visa-type-btn-con${this.state.isOnlineVisa ? " visa-type-btn-selected" : ""}`}
            >
              ויזה אלקטרונית
            </div>
            <div
              onClick={() => this.handleVisaStepsType("offline")}
              className={`visa-type-btn-con${!this.state.isOnlineVisa ? " visa-type-btn-selected" : ""}`}
            >
              ויזה בדרכון
            </div>
          </div>

          {config.isWebsiteB2C ? (
            <div className="flex flex-break-770 home-width-90 auto0 mt-50">
            <div className="step-big-con">
              <Card className="general-shadow">
                <CardContent>
                  <div className="relative center">
                    <div className="abs-light-gray-background" />

                    <div className="circle-step-b2c">
                      <div className="second-border-radius-b2c">
                        <div className="step-text-b2c">שלב 1</div>
                        <img src={iconFilled} alt="logo" className="iconImgFilled-b2c" />
                      </div>
                    </div>

                    <div className="z-index-10 pt-20">
                      <img className="step-image-b2c" src={checkboxIcon} alt="step1" />
                      <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[0]}</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="step-big-con">
              <Card className="general-shadow">
                <CardContent>
                  <div className="relative center">
                    <div className="abs-light-gray-background" />

                    <div className="circle-step-b2c">
                      <div className="second-border-radius-b2c">
                        <div className="step-text-b2c">שלב 2</div>
                        <img src={iconFilled} alt="logo" className="iconImgFilled-b2c" />
                      </div>
                    </div>

                    <div className="z-index-10 pt-20">
                      <img className="step-image-b2c" src={laptopPlane} alt="step2" />
                      <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[1]}</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="step-big-con">
              <Card className="general-shadow">
                <CardContent>
                  <div className="relative center">
                    <div className="abs-light-gray-background" />

                    <div className="circle-step-b2c">
                      <div className="second-border-radius-b2c">
                        <div className="step-text-b2c">שלב 3</div>
                        <img src={iconFilled} alt="logo" className="iconImgFilled-b2c" />
                      </div>
                    </div>

                    <div className="z-index-10 pt-20">
                      <img className="step-image-b2c" src={passportPlane} alt="step3" />
                      <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[2]}</h6>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          ) : (
            <div className="flex flex-break-770 home-width-90 auto0 mt-50">
              <div className="step-big-con">
                <Card className="general-shadow">
                  <CardContent>
                    <div className="relative center">
                      <div className="abs-light-gray-background" />

                      <div className="circle-step background-success">
                        <div className="second-border-radius">
                          <h3 className="color-white center">1</h3>
                        </div>
                      </div>

                      <div className="z-index-10 pt-20">
                        <h4 className="black-color center heb-bold mb-10">שלב ראשון</h4>
                        <img className="step-image" src={computerImg} alt="step1" />
                        <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[0]}</h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="step-big-con">
                <Card className="general-shadow">
                  <CardContent>
                    <div className="relative center">
                      <div className="abs-light-gray-background" />

                      <div className="circle-step background-success">
                        <div className="second-border-radius">
                          <h3 className="color-white center">2</h3>
                        </div>
                      </div>

                      <div className="z-index-10 pt-20">
                        <h4 className="black-color center heb-bold mb-10">שלב שני</h4>
                        <img className="step-image" src={windowsImg} alt="step2" />
                        <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[1]}</h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="step-big-con">
                <Card className="general-shadow">
                  <CardContent>
                    <div className="relative center">
                      <div className="abs-light-gray-background" />

                      <div className="circle-step background-success">
                        <div className="second-border-radius">
                          <h3 className="color-white center">3</h3>
                        </div>
                      </div>

                      <div className="z-index-10 pt-20">
                        <h4 className="black-color center heb-bold mb-10">שלב שלישי</h4>
                        <img className="step-image" src={worldImg} alt="step3" />
                        <h6 className="black-color center mt-20 mb-20">{this.state.currentDisplayedSteps[2]}</h6>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  handleVisaStepsType = (type) => {
    if (type === "online") {
      this.setState({ isOnlineVisa: true, currentDisplayedSteps: onlineSteps });
    } else {
      // offline
      this.setState({ isOnlineVisa: false, currentDisplayedSteps: offlineSteps });
    }
  };
}

export default VisaSteps;
