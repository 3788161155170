import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button } from "@material-ui/core";
import { Language, LocationOn } from "@material-ui/icons";

class B2COriginDestination extends Component {
  state = { fromSelected: { from: "israel", displayNameOrigin: "Israel / ישראל" }, toOnly: [] };

  componentDidMount() {
    this.handleChange({
      target: {
        id: "fromSelected",
        value: {
          from: "israel",
          displayNameOrigin: "Israel / ישראל"
        }
      }
    });
  }

  render() {
    return (
      <div className="container">
        <div className="home-width-90 auto0 relative align-banner-height">
          <div className="b2c-origin-dest-wrapper secundary-background">
            <div className="b2c-origin-dest">
              <div className="b2c-icon-flights-container b2c-world-icon background-success">
                <Language className="color-white" />
              </div>
              <div className="inline-block b2c-origin-field">
                <p className="right primary-color b2c-selection-tag">אזרחות</p>
                <Autocomplete
                  // defaultValue={this.state.fromSelected || null}
                  value={this.state.fromSelected}
                  className="autocomplete-white"
                  options={this.props.fromOnly}
                  getOptionLabel={(option) => option.displayNameOrigin}
                  renderInput={(params) => <TextField {...params} label="" size="small" variant="outlined" />}
                  onChange={(event, newInputValue) => {
                    this.handleChange({ target: { id: "fromSelected", value: newInputValue } });
                  }}
                />
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="46"
              viewBox="0 0 31 46"
              fill="none"
              className="b2c-destination-arrow"
            >
              <g clipPath="url(#clip0_324_45)">
                <path
                  d="M14.6891 22.7254L30.1891 38.1425L22.6912 45.2876L0.386719 23.1024L23.1952 0.35376L30.3141 7.37248L14.6891 22.7254Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_324_45">
                  <rect width="30" height="45" fill="white" transform="translate(0.346436 0.309631)" />
                </clipPath>
              </defs>
            </svg>
            <div className="b2c-origin-dest">
              <div className="b2c-icon-flights-container b2c-location-icon">
                <LocationOn className="color-success" fontSize="large" />
              </div>
              <div className="inline-block b2c-origin-field">
                <p className="right primary-color b2c-selection-tag">יעד</p>
                <Autocomplete
                  value={this.state.toSelected || null}
                  className="autocomplete-white"
                  options={this.state.toOnly}
                  getOptionLabel={(option) => option.displayNameDestination || option.to}
                  disabled={this.state.fromSelected ? false : true}
                  renderInput={(params) => <TextField {...params} label="" size="small" variant="outlined" />}
                  onChange={(event, newInputValue) => {
                    this.handleChange({ target: { id: "toSelected", value: newInputValue } });
                  }}
                />
              </div>
            </div>
            <div className="inline-block b2c-vertical-7">
              <Button className="background-success color-white btn-search" onClick={this.navigateToFromToPage}>
                חפש
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  navigateToFromToPage = () => {
    // this.props.history.push(`/agents/login`);
    if (this.state.fromSelected && this.state.toSelected) {
      this.props.history.push(`/visa-type/${this.state.fromSelected.from}/${this.state.toSelected.to}`);
    }
  };

  handleChange = (e) => {
    var newState = { [e.target.id]: e.target.value };
    if (e.target.id === "fromSelected") {
      if (e.target.value) {
        // i need to filter toOnly
        const toOnly = this.props.allFromTo
          .filter((item) => item.from === e.target.value.from)
          .filter((v, i, a) => a.findIndex((t) => t.to === v.to) === i);

        toOnly.sort((a, b) => (a.to > b.to ? 1 : -1));

        newState["toOnly"] = toOnly;
      } else {
        newState["toSelected"] = "";
      }
    }

    this.setState(newState);
  };
}

export default B2COriginDestination;
