import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import RetrieveExistingVisa from "./retrieveVisa/retrieveExistingVisa";
import FinishedVisaPage from "./finishedVisaPage/finishedVisaPage";
import AboutUs from "./about/aboutUs";
import ChooseVisaType from "../components/oneByOneQuestionnaire/chooseVisaType/chooseVisaType";
import ContactUs from "./contact/contactUs";
import AdminContainer from "./admin/adminContainer";
import AgentContainer from "./agents/agentsContainer";
import Navbar from "../components/layout/navBar";
import Footer from "../components/layout/footer";
import OnlyNotLoggedInRoute from "../protectedRoutes/onlyNotLoggedInGuard";
import HomeContainer from "./home/HomeContainer";
import B2CHomeContainer from "./home/b2c/B2cHomeContainer";
import { connect } from "react-redux";
import TermsAndConditions from "./termsAndConditions/TermsAndConditions";

class AppContainer extends Component {
  render() {
    return (
      <div className={`${this.props.currentLang.layoutDir === "rtl" ? "body-rtl" : ""}`}>
        <Navbar />

        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route path="/b2c" component={B2CHomeContainer} />

          <Route path="/agents" component={AgentContainer} />
          <Route path="/admin" component={AdminContainer} />
          <Route path="/visa-type/:from/:to/" component={ChooseVisaType} />
          <Route path="/retrieve-visa/:passportNum?/:visaId?" component={RetrieveExistingVisa} />
          <OnlyNotLoggedInRoute path="/finished-visa/:visaId/:passportNum/:fullName/:from/:to" component={FinishedVisaPage} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLang: state.appSettingsReducer.currentLang
  };
};

export default connect(mapStateToProps)(AppContainer);
