import React, { Component } from "react";
import AboutUsParallax from "./aboutUsParallax";
import AboutUsStats from "./aboutUsStats";
import AboutUsStory from "./aboutUsStory";
import AboutUsTestimonial from "./aboutUsTestimonial";
import "../../assets/css/aboutUs.css";

class AboutUs extends Component {
  state = {
    testimonyIndex: 0,
    testimonies: [
      {
        name: "שלומי",
        testimony:
          "אני מאוד מרוצה מהשירות המצויין שקיבלתי מהאתר. המידע היה מעודכן והביקורות היו מועילות לי במיוחד. אני ממליץ בחום להשתמש בשירותים של האתר",
        imageKey: "1"
      },
      {
        name: "מרגלית",
        testimony:
          "חוויה מופלאה! השירות המצויין והמידע השימושי באתר הפכו את החוויה שלי למהנה ופשוטה. ממליצה בחום לכל מי שמחפש מידע ושרות טוב.",
        imageKey: "2"
      },
      {
        name: "מעיין",
        testimony: "דברתי בשרות לקוחות עם אפרת והיא היתה פשוט מקסימה! היו לי המון שאלות והיא ענתה על הכל עם הידע הרב שלה! ממליצה בחום!",
        imageKey: "3"
      },
      { name: "מוטי", testimony: "עזרו לנו להוציא ויזות במהירות!", imageKey: "4" }
    ]
  };

  componentDidMount() {
    setInterval(() => {
      var newTestimonyIndex;
      if (this.state.testimonyIndex === 3) {
        newTestimonyIndex = 0;
      } else {
        newTestimonyIndex = this.state.testimonyIndex + 1;
      }
      this.setState({
        testimonyIndex: newTestimonyIndex
      });
    }, 5000);
  }

  render() {
    return (
      <div>
        <AboutUsParallax />
        <AboutUsStory />
        <AboutUsStats />

        <AboutUsTestimonial currentTestimony={this.state.testimonies[this.state.testimonyIndex]} />
      </div>
    );
  }
}

export default AboutUs;
