import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Reducers/rootReducer";

const getStoreInitialState = () => {
  var storeState = {};
  let token = localStorage.getItem("token");
  let langKey = localStorage.getItem("currentLang");
  let layoutDir = localStorage.getItem("layoutDir");
  let langIcon = localStorage.getItem("langIcon");

  var currentLang;
  if (langKey && layoutDir && langIcon) {
    currentLang = { langKey, layoutDir, langIcon };
  }

  if (token) storeState["loggedinReducer"] = { isLoggedIn: true };
  if (currentLang) storeState["appSettingsReducer"] = { currentLang };

  return storeState;
};

const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(rootReducer, getStoreInitialState(), composeEnhancers(applyMiddleware(thunk)));

export default store;
