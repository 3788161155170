import React from "react";
import { Link } from "react-router-dom";

const AdminNav = () => {
  const pathname = window.location.pathname;
  const role = localStorage.getItem("role");
  const permission = localStorage.getItem("permission");

  return (
    <div className="admin-nav-con">
      {role !== "employee" && (
        <>
          <Link to="/admin/fields-manager" className={pathname.includes("fields-manager") ? "active-admin-link" : ""}>
            שדות
          </Link>
          <Link to="/admin/from-to" className={pathname.includes("from-to") ? "active-admin-link" : ""}>
            מוצא ~ יעד
          </Link>
        </>
      )}
      <Link to="/admin/visaManager" className={pathname.includes("visaManager") ? "active-admin-link" : ""}>
        ויזות
      </Link>

      {(permission === "b2b" || role === "admin") && (
        <Link to="/admin/agencies" className={pathname.includes("agencies") ? "active-admin-link" : ""}>
          סוכנויות
        </Link>
      )}
    </div>
  );
};

export default AdminNav;
