import React, { Component } from "react";
import Navbar from "../layout/navBar";
import validationService from "../../Services/validation/validationService";
import visaService from "../../Services/visaService";

import dubaiMD from "../../assets/images/dubai-md.jpg";
import dubaiSM from "../../assets/images/dubai-sm-fade.jpg";

import WhyUsTxt from "../home/b2b/whyUs/whyUsTxt";
import "../../assets/css/dubaiCss.css";
import { TextField, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import config from "../../config";

class DubaiSpecific extends Component {
  state = {
    fullName: "",
    mobile: "",
    email: ""
  };

  render() {
    return (
      <div>
        <Navbar isDubai={true} />
        <div className="relative">
          <img className="home-banner-img dubai-banner" src={dubaiMD} />
          <img className="home-banner-img-sm dubai-banner" src={dubaiSM} />
        </div>

        <div className="container dubai-big-con">
          <h2 className="center primary-color">
            <b>ויזה לדובאי ללא מילוי טפסים!</b>
          </h2>

          <div className="mt-50">
            <div className="call-now-or-whatsapp">
              <h3 className="center primary-color">
                <b>חייגו עכשיו!</b>
              </h3>

              <div className="mb-25">
                <h3 className="mb-10"></h3>
              </div>

              <div>
                <h5 className="center">
                  <b>ניתן לשלוח לנו הודעת וואטסאפ או ישירות להתקשר למספר הזה:</b>
                </h5>
              </div>

              <div>
                <h4 className="center">
                  <a target="_blank" rel="noopener noreferrer" href={"tel:" + config.supportNumber}>
                    03-7705660
                    <i className="material-icons color-success vertical-7">phone</i>
                  </a>
                </h4>
              </div>

              {/* <div className="mt-10">
                <h4 className="center">
                  <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+9725502009192">
                    050-200-9192
                    <i className="material-icons color-success vertical-7">whatsapp</i>
                  </a>
                </h4>
              </div> */}

              <div className="line-sep">
                <div className="line-sep-txt primary-color">
                  <b>או</b>
                </div>
              </div>

              <div className="mt-50">
                <div className="mb-10">
                  <TextField
                    id="fullName"
                    value={this.state.fullName}
                    onChange={this.handleChange}
                    className="width100 background-white"
                    label="שם"
                    variant="outlined"
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div className="mb-10">
                  <TextField
                    id="mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    className="width100 background-white"
                    label="טלפון"
                    variant="outlined"
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div>
                  <TextField
                    id="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="width100 background-white"
                    label='דוא"ל'
                    variant="outlined"
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div className="mt-20">
                  <Button onClick={this.submitDataToServer} className="btn-width160" color="primary" variant="contained">
                    שלח
                  </Button>
                </div>
              </div>
            </div>

            <div className="why-us">
              <WhyUsTxt isDubai={true} />
              {/* <div className="center mt-30">
                <h3 className="center">
                  <b>עלות ויזה כוללת - 370 ש"ח</b>
                </h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.submitDataToServer();
    }
  };

  submitDataToServer = () => {
    var state = this.state;
    if (!state.fullName || !state.mobile || !state.email) {
      Swal.fire("חסר מידע", "יש להכניס את כל הנתונים", "warning");
      return;
    }

    if (state.mobile.length < 7) {
      Swal.fire("", "מספר טלפון שהוזן אינו חוקי", "warning");
      return;
    }

    if (state.fullName.length < 2) {
      Swal.fire("", "שם לא חוקי", "warning");
      return;
    }

    validationService.validateOneField("initEmail", state.email).then(
      (res) => {
        visaService.newDubaiLead(state.fullName, state.email, state.mobile).then((res) => {
          if (res["success"]) {
            this.setState({ fullName: "", mobile: "", email: "" });
            Swal.fire("קבלנו את הבקשה שלך!", "ניצור איתך קשר בקרוב", "success");
          }
        });
      },
      (err) => {
        Swal.fire('דוא"ל לא תקין', "", "warning");
      }
    );
  };
}

export default DubaiSpecific;
