import React, { Component } from "react";
import agentsService from "../../../Services/agentsService";
import { Card, CardContent, Button, TextField } from "@material-ui/core";

import Swal from "sweetalert2";

export default class TokenConf extends Component {
  state = {
    password: "",
    repeatPass: ""
  };

  componentDidMount() {
    var token = this.props.match.params.token;
    if (!token) {
      return;
    }

    this.setState({ token });
  }

  render() {
    return (
      <div className="container mt-50">
        <div className="max-width-500 auto0">
          <Card>
            <CardContent className="login-card">
              <h4 className="primary-color mb-40">אישור אימייל</h4>

              <h6 className="lightgray-brand">כמובן שנרצה שהויזות שלך יהיו מאובטחות! בוא\י נבחר סיסמה!</h6>

              <div className="mt-30">
                <TextField
                  required
                  label="סיסמה"
                  className="field-strech"
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                  value={this.state.password || ""}
                />
              </div>
              <div className="mb-40 mt-20">
                <TextField
                  required
                  className="field-strech"
                  label="אישור סיסמה"
                  type="password"
                  id="repeatPass"
                  onChange={this.handleChange}
                  value={this.state.repeatPass || ""}
                  onKeyPress={(e) => {
                    this.handleKeyPress(e.key);
                  }}
                />
              </div>

              <div className="right mt-20">
                <Button onClick={this.tryConfirmUserEmail} color="primary" variant="contained" size="small">
                  התחבר
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  handleKeyPress = function (keyClicked) {
    if (keyClicked === "Enter") this.tryConfirmUserEmail();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  tryConfirmUserEmail = () => {
    if (!this.state.password || !this.state.repeatPass) {
      return;
    }

    if (this.state.password.length < 8) {
      Swal.fire("", "סיסמה לפחות 8 תוים", "warning");
      return;
    }

    if (this.state.password !== this.state.repeatPass) {
      Swal.fire("", "סיסמאות אינן תואמות", "warning");
      return;
    }

    // if its a user that tries to confirm his/her email
    agentsService.tryConfEmailToken(this.state.token, this.state.password).then((res) => {
      if (res["success"]) {
        Swal.fire("אושר", "אימייל אושר בהצלחה", "success").then(() => {
          this.props.history.push("/agents/login");
        });
      } else {
        Swal.fire("", "אישור מייל לא תקין", "warning");
      }
    });
  };
}
