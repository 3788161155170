import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Grid, IconButton, Typography, Collapse, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { AttachFileRounded, CloudDownloadOutlined, DeleteOutline, HighlightOff, PlusOne } from "@material-ui/icons";
import adminService from "../../../Services/adminService";
import { Autocomplete } from "@material-ui/lab";
import { parseJwt, removeAllNonNumberChars, validateEmail } from "../../../utils";
import Swal from "sweetalert2";
import moment from "moment";
import visaService from "../../../Services/visaService";
import adminServices from "../../../Services/adminService";
import { Fragment } from "react";
import employeeServices from "../../../Services/employeeService";
import AdditionalServices from "./AdditionalServices";

const urgencyLevels = ["רגיל", "דחוף", "במקום"];

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: "20px"
  },
  closeButton: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    textAlign: "center"
  }
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <HighlightOff />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    direction: "rtl",
    backgroundColor: "#F5F5F5"
  }
}))(MuiDialogContent);

const VisaAddPopup = ({ isOpen, onClose, visaToEdit, agencies, destinations, allItineraries, reloadVisas, role, employeeList }) => {
  const [isAgencyVisa, setIsAgencyVisa] = useState(false);
  const [visaValues, setVisaValues] = useState({});
  const [agentList, setAgentList] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [formatErrors, setFormatErrors] = useState({});
  const [processor, setProcessor] = useState({});
  const [visaUploadFileFields, setVisaUploadFileFields] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileKeysHelper, setFileKeysHelper] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const data = parseJwt(token);
    const procInfo = {
      processorName: data.agentFullName,
      processorId: data._id
    };
    setProcessor(procInfo);
  }, []);

  useEffect(() => {
    if (!visaToEdit) return;
    const getIsAgencyVisa = visaToEdit.belongToAgencyId ? true : false;
    setIsAgencyVisa(getIsAgencyVisa);
    setVisaValues({ ...visaToEdit });
    employeeServices.getVisaFieldsAsEmployee("israel", visaToEdit.to, visaToEdit.visaType).then((fields) => {
      if (!fields.success) return;
      const fileUploadFields = fields.neededFields.filter((field) => field.type === "uploadFile");
      if (getIsAgencyVisa) {
        fileUploadFields.push({
          key: "voucher",
          "title-he": "Voucher"
        });
      }
      setVisaUploadFileFields(fileUploadFields);
    });
    getVisaTypes(visaToEdit.to);
    return () => {
      setIsAgencyVisa(false);
      setVisaValues({});
      setVisaUploadFileFields([]);
    };
  }, [visaToEdit]);

  // add voucher upload field on toggling of agency visa
  useEffect(() => {
    const voucherFieldIndex = visaUploadFileFields.findIndex((field) => field.key === "voucher");
    if (isAgencyVisa && voucherFieldIndex === -1) {
      const fields = [...visaUploadFileFields];
      fields.push({
        key: "voucher",
        "title-he": "Voucher"
      });
      setVisaUploadFileFields(fields);
    }
    if (!isAgencyVisa && voucherFieldIndex > -1) {
      const fields = [...visaUploadFileFields];
      fields.splice(voucherFieldIndex, 1);
      setVisaUploadFileFields(fields);
    }
  }, [isAgencyVisa]);

  useEffect(() => {
    if (!visaValues.belongToAgencyId) return;
    const getAgents = async (val) => {
      const agents = await employeeServices.getAgents(val);
      if (agents.agentsList) setAgentList(agents.agentsList);
    };
    getAgents(visaValues.belongToAgencyId);
  }, [visaValues.belongToAgencyId]);

  // reset visa type when changing destinations
  useEffect(() => {
    if (visaToEdit && visaToEdit.to === visaValues.to) return;
    const newValues = { ...visaValues };
    delete newValues.visaType;
    setVisaValues(newValues);
  }, [visaValues.to]);

  useEffect(() => {
    // load visa prices
    const data = allItineraries.filter((itinerary) => itinerary.to === visaValues.to && itinerary.visaType === visaValues.visaType)[0];
    if (!data) return;
    if (visaToEdit && visaToEdit.to === data.to && visaToEdit.visaType === data.visaType) return; // don't overwrite beginning visa data
    const newValues = { ...visaValues };
    newValues.visaPrice = data.visaPrice;
    newValues.visaService = data.visaService;
    setVisaValues(newValues);

    // get visa fields
    if (!visaValues.visaType) return;
    employeeServices.getVisaFieldsAsEmployee("israel", visaValues.to, visaValues.visaType).then((fields) => {
      if (!fields.success) return;
      const fileUploadFields = fields.neededFields.filter((field) => field.type === "uploadFile");

      if (isAgencyVisa) {
        fileUploadFields.push({
          key: "voucher",
          "title-he": "Voucher"
        });
      }
      setVisaUploadFileFields(fileUploadFields);
    });
  }, [allItineraries, visaValues.to, visaValues.visaType]);

  const handleChange = (field, value) => {
    const errors = { ...formatErrors };
    delete errors[field];
    setFormatErrors(errors);
    const newValues = { ...visaValues };
    if (field === "phoneNumber") {
      if (newValues.fields) newValues.fields.phoneNumber = value;
      else {
        newValues.fields = { phoneNumber: value };
      }
    } else {
      newValues[field] = value;
    }
    setVisaValues(newValues);
  };

  const handleClose = () => {
    setVisaValues({});
    setVisaUploadFileFields([]);
    setVisaTypes([]);
    setFormatErrors({});
    setFiles([]);
    setFileKeysHelper([]);
    onClose();
  };

  const handleUpload = (file, newKey) => {
    let newFiles = [...files];
    let newFileKeysHelper = [...fileKeysHelper];

    const getFileIndex = fileKeysHelper.indexOf(newKey);
    if (getFileIndex !== -1) {
      newFiles[getFileIndex] = file;
      newFileKeysHelper[getFileIndex] = newKey;
    } else {
      newFiles.push(file);
      newFileKeysHelper.push(newKey);
    }

    setFileKeysHelper(newFileKeysHelper);
    setFiles(newFiles);
  };

  const handleDeleteFile = async (key) => {
    if (!visaToEdit || !key) return;
    const result = await Swal.fire({
      title: "למחוק את הקובץ?",
      showCancelButton: true,
      confirmButtonText: "כן, למחוק",
      cancelButtonText: "בטל"
    });
    if (!result.isConfirmed) return;
    const newValues = { ...visaValues };
    const fileList = { ...visaValues.files };
    delete fileList[key];
    newValues.files = fileList;
    setVisaValues(newValues);
  };

  const getVisaTypes = (newVal) => {
    const visaTypeList = allItineraries.filter((item) => item.to === newVal).map((x) => x.visaType);
    setVisaTypes(visaTypeList);
  };

  const fieldError = (list) => {
    const curFormatErrors = { ...formatErrors };
    let isError = false;
    if (!isAgencyVisa) {
      if (!validateEmail(list.email)) {
        curFormatErrors.email = "!שדה חסר או פורמט שגוי";
        isError = true;
      }
    }
    if (!list.from) {
      curFormatErrors.from = "!זהו שדה חובה ";
      isError = true;
    }
    if (!list.to) {
      curFormatErrors.to = "!זהו שדה חובה";
      isError = true;
    }
    if (!list.status) {
      curFormatErrors.status = "!נדרש סטטוס ";
      isError = true;
    }
    if (!list.visaType) {
      curFormatErrors.visaType = "!נדרש סוג ויזה ";
      isError = true;
    }
    if (!list.lastName) {
      curFormatErrors.lastName = "!נדרש שם משפחה ";
      isError = true;
    }
    if (!list.firstName) {
      curFormatErrors.firstName = " !נדרש שם פרטי ";
      isError = true;
    }

    if (list.passportNum && list.passportNum.length <= 5) {
      curFormatErrors.passportNum = "!יש צורך במינימום 5 ספרות";
      isError = true;
    }
    if (!list.passportNum) {
      curFormatErrors.passportNum = "!יש צורך במספר דרכון";
      isError = true;
    }
    if (!list.urgencyLevel) {
      curFormatErrors.urgencyLevel = "נא לבחור רמת דחיפות.";
      isError = true;
    }

    if (!list.fields?.phoneNumber) {
      curFormatErrors.phoneNumber = "חובה מספר טלפון";
      isError = true;
    }

    setFormatErrors(curFormatErrors);
    if (isError) return false;
    else return true;
  };

  const handleDataChange = async (updates) => {
    const swalRes = await Swal.fire({
      title: "האם אתה בטוח?",
      text: "?לשמור את כל השינויים שנעשו לויזה",
      icon: "warning",
      showCancelButton: true
    });
    if (swalRes.isConfirmed) {
      if (updates?.fields?.phoneNumber) updates.fields.phoneNumber = parseInt(updates.fields.phoneNumber);
      const res = await adminService.handleVisasChanges(updates);
      if (res.success) {
        return true;
      }
    }
    return false;
  };

  const onVisaSaveBtnClicked = async () => {
    const newVisaToSave = { ...visaValues };

    if (!visaToEdit) {
      newVisaToSave.createdByProcessorId = processor.processorId;
      newVisaToSave.createdByProcessorName = processor.processorName;
    } else {
      newVisaToSave.lastEditByProcessorId = processor.processorId;
      newVisaToSave.lastEditByProcessorName = processor.processorName;
    }

    if (role === "employee" && !visaToEdit) newVisaToSave.managedBy = processor.processorId;

    // prevent overwrite
    if (visaToEdit && newVisaToSave.dateCreated) delete newVisaToSave.dateCreated;
    if (visaToEdit && newVisaToSave.dateClientSubmitted) delete newVisaToSave.dateClientSubmitted;

    if (!newVisaToSave.from) newVisaToSave.from = "israel";
    if (!fieldError(newVisaToSave)) {
      return Swal.fire("שדות חסרים", "יש למלא את כל השדות הנדרשים על מנת ליצור ויזה חדשה!", "warning");
    }

    if (isAgencyVisa && !visaToEdit && newVisaToSave.createdByAgent) {
      const agent = agentList.filter((agent) => agent._id === newVisaToSave.createdByAgent)[0];
      newVisaToSave.agentFirstName = agent.agentFirstName;
      newVisaToSave.agentLastName = agent.agentLastName;
    }

    if (!visaToEdit) {
      const existingVisaForPassport = await employeeServices.getVisasBySearch(
        "",
        { start: moment().subtract(4, "months").toDate(), end: new Date() },
        { passportNum: newVisaToSave.passportNum },
        isAgencyVisa ? "agency" : "regular"
      );
      if (existingVisaForPassport.visaList && existingVisaForPassport.visaList[0]) {
        const userAnsForExistingSimilarVisa = await Swal.fire({
          title: "Warning",
          text:
            "A visa already has been created on" +
            " " +
            moment(existingVisaForPassport.visaList[0].dateCreated).format("DD/MM/YYYY") +
            " " +
            "with this passport number. Do you still want to proceed?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#11cb5f",
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "No, cancel it!"
        });
        if (!userAnsForExistingSimilarVisa.isConfirmed) return;
      }
    }

    let visaId;

    if (visaToEdit) {
      visaId = newVisaToSave._id;
      const shouldContinue = await handleDataChange(newVisaToSave);
      if (!shouldContinue) return;
    } else {
      const visaCreate = await visaService.createNewVisa(newVisaToSave);
      if (visaCreate.success) {
        visaId = visaCreate.visaId;
      } else {
        Swal.fire("היתה בעיה ביצירת הויזה", "", "warning");
        return;
      }
    }

    if (files.length) {
      // Files upload
      await adminService.attachVisaFilesMulti(files, fileKeysHelper, visaId, newVisaToSave.passportNum);
    }

    Swal.fire("!בוצע", "ויזה נוצרה בהצלחה", "success");

    reloadVisas();
    handleClose();
  };

  const b64toBlob = async (base64, type = "application/octet-stream") => (await fetch(`data:${type};base64,${base64}`)).blob();

  const downloadFile = async (filename, userId) => {
    const fileBase64String = await adminServices.agentDownloadUserFileFromSubfolder(filename, userId);
    if (!fileBase64String.err) {
      const blob = await b64toBlob(fileBase64String);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
    }
  };

  const getFileLabel = (fileKey) => {
    if (!visaUploadFileFields.length || !fileKey) return;
    const field = visaUploadFileFields.find((el) => el.key === fileKey);
    if (!field) return fileKey;
    return field["title-he"] || "";
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth="md">
      <DialogTitle onClose={handleClose}>{visaToEdit ? "עריכת ויזה קיימת" : "ויזה חדשה"}</DialogTitle>

      {!visaToEdit && (
        <>
          <Box className="visas-subtitle visas-popup-subtitle mb-20">בחר סוג ויזה</Box>

          <Box className="visas-popup-buttons">
            <Button
              onClick={() => setIsAgencyVisa(false)}
              id={isAgencyVisa ? "base-visa-type-button" : "active-visa-type-button"}
              size="large"
            >
              רגיל
            </Button>
            <Button
              onClick={() => setIsAgencyVisa(true)}
              id={isAgencyVisa ? "active-visa-type-button" : "base-visa-type-button"}
              size="large"
            >
              סוכן
            </Button>
          </Box>
        </>
      )}
      <DialogContent>
        <Collapse in={isAgencyVisa}>
          <Box className="agency-visas-extra-fields">
            <h4 className="white">פרטי הסוכנות</h4>
            <Box className="popup-form-row">
              <TextField
                fullWidth
                variant="outlined"
                InputProps={{ style: { backgroundColor: "white" } }}
                InputLabelProps={{
                  style: {
                    backgroundColor: "white",
                    padding: "3px",
                    borderRadius: "3px"
                  }
                }}
                label="לבחור סוכנות"
                select
                onChange={(e) => handleChange("belongToAgencyId", e.target.value)}
                value={visaValues.belongToAgencyId || ""}
              >
                {agencies.map((agency) => (
                  <MenuItem key={agency._id} value={agency._id}>
                    {agency.agencyName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="outlined"
                InputProps={{ style: { backgroundColor: "white" } }}
                InputLabelProps={{
                  style: {
                    backgroundColor: "white",
                    padding: "3px",
                    borderRadius: "3px"
                  }
                }}
                label="סוכנים"
                select
                disabled={!visaValues.belongToAgencyId}
                value={visaValues.createdByAgent || ""}
                onChange={(e) => handleChange("createdByAgent", e.target.value)}
                helperText={formatErrors.createdByAgent ? formatErrors.createdByAgent : null}
                error={formatErrors.createdByAgent ? true : false}
              >
                {agentList[0] ? (
                  agentList.map((agent) => (
                    <MenuItem key={agent._id} value={agent._id}>
                      {agent.agentFirstName + " " + agent.agentLastName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>אין סוכנים רשומים בסוכנות</MenuItem>
                )}
              </TextField>
            </Box>
          </Box>
        </Collapse>
        <Box className="visas-popup-main-body">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h3>פרטים אישיים</h3>
              <Box className="popup-form-row">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="שם פרטי"
                  value={visaValues.firstName || ""}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  helperText={formatErrors.firstName ? formatErrors.firstName : null}
                  error={formatErrors.firstName ? true : false}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="שם משפחה"
                  value={visaValues.lastName || ""}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  helperText={formatErrors.lastName ? formatErrors.lastName : null}
                  error={formatErrors.lastName ? true : false}
                />
              </Box>
              <Box className="popup-form-row">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="מספר הדרכון"
                  value={visaValues.passportNum || ""}
                  onChange={(e) => handleChange("passportNum", e.target.value)}
                  helperText={formatErrors.passportNum ? formatErrors.passportNum : null}
                  error={formatErrors.passportNum ? true : false}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="כתובת דוא״ל"
                  value={visaValues.email || ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  helperText={formatErrors.email ? formatErrors.email : null}
                  error={formatErrors.email ? true : false}
                />
              </Box>
              <Box className="popup-form-row">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="מספר טלפון"
                  value={visaValues.fields?.phoneNumber || ""}
                  onChange={(e) => handleChange("phoneNumber", removeAllNonNumberChars(e.target.value))}
                  helperText={formatErrors.phoneNumber ? formatErrors.phoneNumber : null}
                  error={formatErrors.phoneNumber ? true : false}
                />
              </Box>
            </Grid>
            <Grid item>
              <h3>פרטי ויזה</h3>
              <Box className="popup-form-row">
                <TextField fullWidth variant="outlined" label="מוצא" value="ישראל" disabled InputLabelProps={{ shrink: true }} />
                <Autocomplete
                  options={destinations}
                  fullWidth
                  value={visaValues.to || ""}
                  onChange={(e, val) => {
                    handleChange("to", val);
                    getVisaTypes(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="יעד"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                      helperText={formatErrors.to ? formatErrors.to : null}
                      error={formatErrors.to ? true : false}
                    />
                  )}
                />
              </Box>
              <Box className="popup-form-row">
                <Autocomplete
                  fullWidth
                  label="סוג ויזה"
                  options={visaTypes}
                  value={visaValues.visaType || ""}
                  onChange={(e, val) => handleChange("visaType", val)}
                  disabled={!visaTypes.length && !visaValues.visaType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="סוג ויזה"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                      helperText={formatErrors.visaType ? formatErrors.visaType : null}
                      error={formatErrors.visaType ? true : false}
                    />
                  )}
                />
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="סטטוס הויזה"
                  value={visaValues.status || ""}
                  onChange={(e) => handleChange("status", e.target.value)}
                  helperText={formatErrors.status ? formatErrors.status : null}
                  error={formatErrors.status ? true : false}
                >
                  {adminService.getAllStatuses().map((stat) => {
                    return (
                      <MenuItem label={stat.label} value={stat.key} key={stat.label}>
                        {stat.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid item>
              <h3>מידע נוסף</h3>
              <Box className="popup-form-row">
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="רמת הדחיפות"
                  value={visaValues.urgencyLevel || ""}
                  onChange={(e) => handleChange("urgencyLevel", e.target.value)}
                  helperText={formatErrors.urgencyLevel ? formatErrors.urgencyLevel : null}
                  error={formatErrors.urgencyLevel ? true : false}
                >
                  {urgencyLevels.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </TextField>
                {isAgencyVisa && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    InputProps={{ style: { backgroundColor: "white" } }}
                    label="מספר שובר"
                    value={visaValues.shovarNumber || ""}
                    onChange={(e) => handleChange("shovarNumber", e.target.value)}
                  />
                )}
              </Box>
              <Box className="popup-form-row">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="מחיר ויזה(₪)"
                  value={visaValues.visaPrice || ""}
                  onChange={(e) => handleChange("visaPrice", removeAllNonNumberChars(e.target.value))}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { backgroundColor: "white" } }}
                  label="מחיר שירות"
                  value={visaValues.visaService || ""}
                  onChange={(e) => handleChange("visaService", removeAllNonNumberChars(e.target.value))}
                />
              </Box>
              {role !== "employee" && (
                <Box className="popup-form-row">
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    InputProps={{ style: { backgroundColor: "white" } }}
                    label="מנוהל על ידי:"
                    value={visaValues.managedBy || ""}
                    onChange={(e) => handleChange("managedBy", e.target.value)}
                    helperText={formatErrors.managedBy ? formatErrors.managedBy : null}
                    error={formatErrors.managedBy ? true : false}
                  >
                    {employeeList.map((employee) => (
                      <MenuItem key={employee._id + "manager"} value={employee._id}>
                        {`${employee.agentFirstName} ${employee.agentLastName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </Grid>
            <AdditionalServices
              additionServices={visaValues.additionServices || []}
              updateAdditionServices={(newAdditionServices) => setVisaValues({ ...visaValues, additionServices: newAdditionServices })}
            />
            <Grid item>
              <h3>קבצים מצורפים</h3>
              {visaValues.files && (
                <Box
                  sx={{
                    backgroundColor: "#E3EFFD",
                    color: "#004598",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "2px 1rem",
                    gap: "1rem"
                  }}
                >
                  <Box>תאריך יצירה</Box>
                  <Box sx={{ width: "30%", direction: "rtl" }}>תיאור קובץ והורדה</Box>
                </Box>
              )}
              <Box sx={{ backgroundColor: "white" }}>
                {visaUploadFileFields.length === 0 ? (
                  <Typography>אין צורך להעלות קבצים</Typography>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: "column nowrap",
                      gap: "1rem",
                      padding: "1rem"
                    }}
                  >
                    {visaUploadFileFields.length ? (
                      visaUploadFileFields.map((field, i) => (
                        <Fragment key={field.key}>
                          {visaValues.files && visaValues.files[field.key] ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "row nowrap",
                                gap: "1rem",
                                alignItems: "center"
                              }}
                            >
                              <Box>{moment(visaValues.files[field.key].dateCreated).format("DD[.]MM[.]YYYY")}</Box>
                              <Box sx={{ width: "30%", direction: "rtl" }}>{getFileLabel(field.key)}</Box>
                              <IconButton onClick={() => downloadFile(visaValues.files[field.key].nameWithExtension, visaValues._id)}>
                                <CloudDownloadOutlined />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteFile(field.key)}>
                                <DeleteOutline />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "row nowrap",
                                gap: "1rem",
                                alignItems: "center"
                              }}
                            >
                              <Box
                                sx={{
                                  width: "30%",
                                  display: "flex",
                                  justifyContent: "flex-start"
                                }}
                              >
                                <Button endIcon={<AttachFileRounded />} variant="contained" component="label">
                                  {field["title-he"]}
                                  <span style={{ width: "12px" }} />
                                  <input
                                    type="file"
                                    accept={["image/*", "application/pdf"]}
                                    hidden
                                    onChange={(e) => handleUpload(e.target.files[0], field.key)}
                                    name={field.key}
                                  />
                                </Button>
                              </Box>
                              <Box sx={{ direction: "ltr" }}>
                                {fileKeysHelper.includes(field.key) ? files[fileKeysHelper.indexOf(field.key)].name : "No file selected"}
                              </Box>
                            </Box>
                          )}
                        </Fragment>
                      ))
                    ) : (
                      <>אין קבצים דרושים לסוג הויזה הזאת</>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item>
              <h3>הערות</h3>
              <TextField
                fullWidth
                variant="outlined"
                InputProps={{ style: { backgroundColor: "white" } }}
                multiline
                value={visaValues.comments || ""}
                onChange={(e) => handleChange("comments", e.target.value)}
              />
            </Grid>
            <Box className="visas-popup-buttons mt-20">
              <Button variant="contained" className="light-button" onClick={handleClose}>
                בטל
              </Button>
              <Button variant="contained" className="green-button" onClick={onVisaSaveBtnClicked}>
                שמור
              </Button>
            </Box>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VisaAddPopup;
