import React, { Component } from "react";
import { TextField, Radio, RadioGroup, FormControlLabel, Checkbox } from "@material-ui/core";

class FieldValidation extends Component {
  state = {};

  render() {
    var props = this.props;
    return (
      <div className="mt-20">
        <h4 className="yellow-color">Validations</h4>
        {this.typeHasOptions() ? (
          <TextField value="From Options" disabled={true} />
        ) : (
          <div>
            <div className="width140">
              <RadioGroup
                value={props.validation.validationType || ""}
                className="flex-direction-row"
                onChange={(e) => {
                  this.handleChange({ target: { id: "validationType", value: e.target.value } });
                }}
              >
                <FormControlLabel control={<Radio value="numberType" color="primary" />} label="Number" />
                <FormControlLabel control={<Radio value="txtType" color="primary" />} label="Text" />
                <FormControlLabel control={<Radio value="dateType" color="primary" />} label="Date" />
                <FormControlLabel control={<Radio value="fileType" color="primary" />} label="File" />
              </RadioGroup>
              <hr />
            </div>

            {this.showExtraValidationOpts(props.validation.validationType)}
          </div>
        )}
      </div>
    );
  }

  showExtraValidationOpts = (validationType) => {
    var validation = this.props.validation;
    if (validationType === "numberType") {
      return (
        <div className="options-wrapper">
          <TextField id="min" value={validation.min || ""} onChange={this.handleChange} label="Min" />
          <TextField id="max" value={validation.max || ""} onChange={this.handleChange} label="Max" />
        </div>
      );
    } else if (validationType === "txtType") {
      return (
        <div className="options-wrapper">
          <FormControlLabel
            onChange={(e) => {
              this.handleChange({ target: { value: e.target.checked, id: "lowercase" } });
            }}
            control={<Checkbox color="primary" value="lowercase" />}
            label="Lowercase"
            checked={validation.lowercase ? true : false}
          />
          <FormControlLabel
            onChange={(e) => {
              this.handleChange({ target: { value: e.target.checked, id: "trim" } });
            }}
            control={<Checkbox color="primary" value="trim" />}
            label="Trim"
            checked={validation.trim ? true : false}
          />
          <FormControlLabel
            onChange={(e) => {
              this.handleChange({ target: { value: e.target.checked, id: "email" } });
            }}
            control={<Checkbox color="primary" value="email" />}
            label="Email"
            checked={validation.email ? true : false}
          />
          <br />
          <TextField id="min" value={validation.min || ""} onChange={this.handleChange} label="Min" />
          <TextField id="max" value={validation.max || ""} onChange={this.handleChange} label="Max" />
        </div>
      );
    } else if (validationType === "dateType") {
      // date
      return (
        <div>
          <div>
            Start
            <br />
            <FormControlLabel
              onChange={(e) => {
                this.handleChange({ target: { value: e.target.checked, id: "dateStartToday" } });
              }}
              control={<Checkbox color="primary" value="dateStartToday" />}
              label="Today"
              checked={validation.dateStartToday ? true : false}
            />
            <input
              id="dateStart"
              disabled={validation.dateStartToday}
              onChange={this.handleChange}
              type="date"
              value={validation.dateStart || ""}
            />
          </div>
          End
          <br />
          <FormControlLabel
            onChange={(e) => {
              this.handleChange({ target: { value: e.target.checked, id: "dateEndToday" } });
            }}
            control={<Checkbox color="primary" value="dateEndToday" />}
            label="Today"
            checked={validation.dateEndToday ? true : false}
          />
          <input
            id="dateEnd"
            disabled={validation.dateEndToday}
            onChange={this.handleChange}
            type="date"
            value={validation.dateEnd || ""}
          />
        </div>
      );
    }

    return null;
  };

  typeHasOptions = (e) => {
    if (this.props.type === "radioBtns" || this.props.type === "radioCircles" || this.props.type === "autocomplete") return true;
    return false;
  };

  handleChange = (e) => {
    var updatedValidation = { ...this.props.validation };
    updatedValidation[e.target.id] = e.target.value;
    this.props.handleChange({ target: { id: "validation", value: updatedValidation } });
  };
}

export default FieldValidation;
