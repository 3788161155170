import React, {Component, Fragment} from "react";
import {TextField, Button, FormControlLabel, Checkbox, Modal} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import PaymentIframe from "./paymentIframe";
import config from "../../config";
import moment from "moment";
import countriesList from "../../assets/data/countriesList";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";

var fieldTypes = require("./fieldEnums").default;

class QuestionDisplayer extends Component {
  state = {
    partnerAdded: false,
    isTermsPopupOpen: false,
    translations: null,
    agreedToTerms: false,
  };

  render() {
    var question = this.props.question;
    var val = this.props.val ? this.props.val : "";
    var validationErr = this.props.validationErr
      ? this.props.validationErr
      : "";
    if (question.type === "numberWithCommas") val = this.numberWithCommas(val);
    if (question.type === "date") val = this.reformatDateFormatForUI(val);
    if (question.type === "checkbox") val = val.split(":");
    if (["yes", "no", "don't know", "female", "male"].includes(val))
      val = val[0].toUpperCase() + val.substring(1);

    var renderedUI = this.getRenderedUI(
      question,
      val,
      validationErr,
      this.props.confirmPassVal
    );
    var getBoldTitleText =
      question.titleBold && question.titleBold[0] ? question.titleBold : [""];
    var regTextList =
      question.title && question.title[0]
        ? question.title.split("%BOLD%")
        : [""];
    return (
      <Fragment>
        <div
          className={`question-title${
            this.state.translations && this.state.translations.dir === "rtl"
              ? " rtl-layout"
              : ""
          }`}
        >
          <h3>
            {regTextList.map((text, index) => {
              return (
                <span key={"asdsa" + index}>
                  {text}
                  <b>{getBoldTitleText[index]}</b>
                </span>
              );
            })}
          </h3>
          <h6>{question.elaborationTxt || ""}</h6>
        </div>
        {renderedUI}
      </Fragment>
    );
  }

  handleCheckboxChange = (e) => {
    this.props.handleCheckboxChange(
      e.target.name,
      e.target.value,
      e.target.checked
    );
  };

  validateNum = (e) => {
    var value = e.target.value.replace(/,/g, "");
    if (isNaN(value)) return;
    value = {target: {value}};
    this.props.handleFieldChange(value, e.target.id);
  };

  numberWithCommas = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  reformatDateFormatForUI = function (x) {
    if (typeof x === "object") x = moment(x).format("YYYY-MM-DD");
    return x; //`${x[2]}-${x[1]}-${x[0]}`;
  };

  getRenderedUI = function (question, val, validationErr, confirmPassVal) {
    switch (question.type) {
      case fieldTypes.txt:
        return this.getTxt(question, val, validationErr);
      case fieldTypes.password:
        return this.getPassword(question, val, validationErr, confirmPassVal);
      case fieldTypes.date:
        return this.getDate(question, val, validationErr);
      case fieldTypes.number:
        return this.getNumber(question, val, validationErr);
      case fieldTypes.numberWithCommas:
        return this.getNumberWithCommas(question, val, validationErr);
      case fieldTypes.radioBtns:
        return this.getRadioBtns(question, val, validationErr);
      case fieldTypes.radioCircles:
        return this.getRadioCircles(question, val, validationErr);
      case fieldTypes.scroller:
        return this.getScroller(question, val, validationErr);

      // case fieldTypes.agreeToTerms:
      //   return this.getAgreeToTerms(question);
      case fieldTypes.autocomplete:
        return this.getAutocomplete(question, val);
      case fieldTypes.countriesAutocomplete:
        return this.getAutocomplete(question, val, true);        
      case fieldTypes.msgToClient:
        return this.getMsgToClient(question);
      case fieldTypes.uploadFile:
        return this.getUploadFile(question);
      case fieldTypes.payment:
        return this.getPayment();
      case fieldTypes.termsForPayment:
        return this.getTermsForPayment();
      
      default:
        return <div></div>;
    }
  };

  getTxt = function (question, val, validationErr) {
    return (
      <Fragment>
        <div className="question-input-con">
          <TextField
            className="full-width"
            id={question.key}
            label={question.label}
            variant="outlined"
            helperText={validationErr ? validationErr : ""}
            error={validationErr ? true : false}
            type={question.key === "password" ? "password" : "text"}
            disabled={question.isDisabled}
            value={val}
            autoFocus
            onChange={(e) => {
              const isEnglishLettersAndAllowedChars = /^[A-Za-z\s.,!?"'-@]+$/;

              if (isEnglishLettersAndAllowedChars.test(e.target.value) || e.target.value === '')
                this.props.handleFieldChange(e, question.key);
              }
            }
          />
        </div>
        <div className="center mt-20">
          <Button
            onClick={() => {
              this.props.onContinue(question, val);
            }}
            color="primary"
            variant="contained"
          >
            המשך
          </Button>
        </div>
      </Fragment>
    );
  };

  getPassword = function (question, val, validationErr, confirmPassVal) {
    return (
      <Fragment>
        <div className="question-input-con">
          <TextField
            className="full-width"
            id={question.key}
            label={question.label}
            variant="outlined"
            helperText={validationErr ? validationErr : ""}
            error={validationErr ? true : false}
            type="password"
            value={val}
            autoFocus
            onChange={(e) => {
              this.props.handleFieldChange(e, question.key);
            }}
          />
        </div>
        <div className="question-input-con mt-30">
          <TextField
            className="full-width"
            label={question.confirmPassLabel}
            variant="outlined"
            helperText={validationErr ? validationErr : ""}
            error={validationErr ? true : false}
            type="password"
            value={confirmPassVal}
            onChange={(e) => {
              this.props.handleFieldChange(e, question.confirmPass);
            }}
          />
        </div>
        <div className="center mt-20">
          <Button
            onClick={() => {
              this.props.onContinue(question, val, confirmPassVal);
            }}
            color="primary"
            variant="contained"
          >
            המשך
          </Button>
        </div>
      </Fragment>
    );
  };

  getDate = function (question, val, validationErr) {
    return (
      <Fragment>
        <div className="question-input-con">
          <div className="relative">
            <div className="date-title">
              <span>{question.label}</span>
            </div>
            <input
              id={question.key}
              onChange={(e) => {
                this.props.handleFieldChange(e, question.key);
              }}
              className="date-field"
              type="date"
              value={val}
              autoFocus
            />
            {validationErr ? (
              <div>
                <span className="err-msg">{validationErr}</span>
              </div>
            ) : null}
          </div>
          <div className="center mt-40">
            <Button
              onClick={() => {
                this.props.onContinue(question, val);
              }}
              color="primary"
              variant="contained"
            >
              המשך
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  getNumber = function (question, val, validationErr) {
    return (
      <div className="question-input-con">
        <div className="questionnaire-field-wrapper">
          <div>
            <TextField
              className="full-width"
              id={question.key}
              label={question.label}
              variant="outlined"
              value={val}
              helperText={validationErr ? validationErr : ""}
              error={validationErr ? true : false}
              onChange={this.validateNum}
              autoFocus
            />
          </div>
        </div>
        <div className="center mt-40">
          <Button
            onClick={() => {
              this.props.onContinue(question, val);
            }}
            color="primary"
            variant="contained"
          >
            המשך
          </Button>
        </div>
      </div>
    );
  };

  // Not working
  getNumberWithCommas = function (question, val) {
    return (
      <div className="questionnaire-field-wrapper">
        <div>
          <TextField
            className="full-width"
            id={question.key}
            label={question.label}
            variant="outlined"
            value={val}
            onChange={this.validateNum}
            autoFocus
            onBlur={() => {
              this.props.onContinue();
            }}
          />
        </div>
      </div>
    );
  };

  getScroller = function (question, val) {
    return (
      <div className="question-input-con">
        <div
          className={`slider-con${
            this.state.translations && this.state.translations.dir === "rtl"
              ? " rtl-layout"
              : ""
          }`}
        >
          <span className="primary-color">{question.label}</span>
          <h2 className="center mb-60">{`${val || question.startRange} ${
            question.signOfMeasurement
          }`}</h2>
          <Slider
            value={val || question.startRange}
            onChange={(e, newValue) => {
              e["target"]["value"] = newValue;
              this.props.handleFieldChange(e, question.key);
            }}
            aria-labelledby="discrete-slider-custom"
            step={1}
            min={question.startRange}
            max={question.endRange}
            valueLabelDisplay="auto"
            marks={question.marks}
          />
          <div className="center mt-60">
            <Button
              onClick={() => {
                this.props.onContinue(question, val || question.startRange);
              }}
              className="btn-width"
              color="primary"
              variant="contained"
            >
              המשך
            </Button>
          </div>
        </div>
      </div>
    );
  };

  getAutocomplete = function (question, val, countriesAutocomplete) {
    const options = countriesAutocomplete ? countriesList : question.options;
    const getLabel = (option) => (typeof option === 'string' ? option : option.label);
    const getValue = (option) => (typeof option === 'string' ? option : option.val);

    return (
      <div className="question-input-con">
        <Autocomplete
          id={question.title}
          options={options}
          value={options.find((option) => getValue(option) === val) || null}
          blurOnSelect
          openOnFocus
          filterOptions={createFilterOptions({
            matchFrom: 'start',
          })}
          getOptionLabel={getLabel}
          isOptionEqualToValue={(option, value) => getValue(option) === value}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={question.label} />
          )}
          onChange={(event, newValue) => {
            this.props.handleFieldChange(
              { target: { value: newValue ? getValue(newValue) : '' } },
              question.key
            );
          }}
        />
        <div className="center mt-20">
          <Button
            onClick={() => {
              this.props.onContinue(question, val);
            }}
            className="btn-width"
            color="primary"
            variant="contained"
          >
            המשך
          </Button>
        </div>
      </div>
    );
  };

  getMsgToClient = function (question) {
    return (
      <div className="center mt-60">
        <Button
          onClick={() => {
            this.props.onContinue(question);
          }}
          className="btn-width"
          color="primary"
          variant="contained"
        >
          המשך
        </Button>
      </div>
    );
  };

  getRadioBtns = function (question, val) {
    return (
      <div className="center radio-con">
        <br />
        {question.options.map((option, index) => {
          return (
            <div key={option.label} className="inline-block margin10 radio-btn">
              <Button
                onClick={() => {
                  this.props.onContinue(question, option.val);
                }}
                className="btn-width"
                color="primary"
                variant="contained"
              >
                {option.label}
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  getRadioCircles = function (question, val) {
    return (
      <div
        className={`center radio-con${
          this.state.translations.dir === "rtl" ? " rtl-layout" : ""
        }`}
      >
        {question.options.map((option, index) => {
          return (
            <div
              key={option.label}
              onClick={() => {
                this.props.onContinue(question, option.val);
              }}
              className="inline-block margin10 circle-opt"
            >
              {option.label}
            </div>
          );
        })}
      </div>
    );
  };

  getUploadFile = function (question, val) {
    if (question.selectedFile && !this.state.imgSrc) {
      this.setState({
        imgSrc: URL.createObjectURL(question.selectedFile),
        selectedFile: question.selectedFile,
      });
    }
    return (
      <div className="question-input-con">
        <label htmlFor="file-input" className="upload-file-btn ml-10 mr-10">
          {question.label}
          <i className="material-icons attach-icon-align">attachment</i>
        </label>
        {this.state.selectedFile ? this.state.selectedFile.name : ""}

        <input
          id="file-input"
          style={{visibility: "hidden"}}
          type="file"
          onChange={(e) => {
            this.setState({
              selectedFile: e.target.files[0],
              imgSrc: URL.createObjectURL(e.target.files[0]),
            });
          }}
        />
        {this.state.selectedFile ? (
          <Fragment>
            {config.imagesFormatToShow.includes(
              this.state.selectedFile.name.substring(
                this.state.selectedFile.name.lastIndexOf(".")
              )
            ) ? (
              <img className="img-selected" src={this.state.imgSrc} />
            ) : null}
            <div className="mt-30">
              <Button
                onClick={() => {
                  if (this.state.selectedFile) {
                    question.selectedFile = this.state.selectedFile;
                    this.props.onContinue(question, this.state.selectedFile);
                  }
                }}
                className="btn-width"
                color="primary"
                variant="contained"
              >
                המשך
              </Button>
            </div>
          </Fragment>
        ) : null}
      </div>
    );
  };

  getPayment = function () {
    return (
      <div className="mt-20">
        <PaymentIframe
          notifyFinishFlow={this.props.notifyFinishFlow}
          visaService={this.props.visaService}
          visaPrice={this.props.visaPrice}
          visaId={this.props.visaId}
          passportNum={this.props.passportNum}
          to={this.props.to}
          visaType={this.props.visaType}
          fieldVals={this.props.fieldVals}
        />
      </div>
    );
  };

  getTermsForPayment = () => {
    return (
    <>
      {this.state.isTermsPopupOpen && (
          <Modal 
            open={this.state.isTermsPopupOpen}
            onClose={() => this.setState({ isTermsPopupOpen: false })}
            style={{ overflowY: 'auto', maxHeight: '90vh' }}
            onClick={() => this.setState({ isTermsPopupOpen: false })}
            >
            <TermsAndConditions />
          </Modal>
      )}
      <div className="center mt-60">
        <h3 style={{ textAlign: 'center' }}>האם אתה מסכים לתנאי השימוש?</h3>
        <div className="mt-30">
          <FormControlLabel
            style={{margin: 0}}
            control={<Checkbox checked={this.state.agreedToTerms} onChange={(e)=> this.setState({ agreedToTerms: e.target.checked })} color="primary"/>}
            label={
              <span>
                  קראתי ואני מסכים{' '}  
                <a onClick={() => this.setState({ isTermsPopupOpen: !this.state.isTermsPopupOpen })} style={{color: 'blue', textDecoration:'underline'}}>
                  לתנאי השימוש
                </a>
              </span>
            }
          />
        <div className='mt-20'>
          <Button
            onClick={() => {
              if (this.state.agreedToTerms) {
                this.props.onContinue();
              } else {
                alert('עליך לקרוא ולהסכים לתנאי השימוש כדי להמשיך');
              }
            }}
            className="btn-width"
            color="primary"
            variant="contained"
            disabled={!this.state.agreedToTerms}
          >
            המשך
          </Button>
        </div>  
        </div>
      </div>
    </>
    );
  };
}

export default QuestionDisplayer;
