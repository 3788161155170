import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

var msgTypes = {
  "sent-lawyer": { title: "תודה לך", subTitle: "ניתן לסגור את העמוד.", txt: "כל הנתונים נשלחו לעורך הדין" },
  "reset-pass-msg": {
    title: "בקשתך לאיפוס סיסמה התקבלה בהצלחה",
    subTitle: "שלחנו לך אימייל עם הוראות נוספות",
    txt: "",
    linkForRedirection: "/agents/login",
    linkForRedirectionTxt: "לחץ להתחברות"
  }
};

class MessagePage extends Component {
  render() {
    var msgData = msgTypes[this.props.match.params.messageType];
    return (
      <div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <br />
          <br />
          <br />
          <Card>
            <CardContent className="login-card">
              <h4 className="primary-color mb-40 center">{msgData.title}</h4>

              <p>
                {msgData.subTitle}
                <br />
                {msgData.txt}
              </p>

              {msgData.linkForRedirection ? (
                <div className="mt-120">
                  <hr />
                  <Link to={msgData.linkForRedirection}>{msgData.linkForRedirectionTxt}</Link>
                </div>
              ) : null}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

export default MessagePage;
