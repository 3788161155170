import React, { Component } from "react";
import visaService from "../../../../Services/visaService";
import windowImg from "../../../../assets/images/airplain-window.png";
import { Button } from "@material-ui/core";
import config from "../../../../config";

class PopularCard extends Component {
  render() {
    return (
      <div className="window-con">
        <div className="country-name-con">
          <h5 className="center primary-color">
            <b>{visaService.upperCaseCountry(this.props.cardData.to)}</b>
          </h5>
        </div>

        <div className="country-btn-con">
          <Button onClick={this.redirectUser} className="background-success color-white btn-search">
            הזמן
          </Button>
        </div>
        <img className="window-img" src={windowImg} alt="window" />

        <div className="flag-con">
          <img className="country-flag" src={config.baseFlagUrl + this.props.cardData.to.replace(/ /g, "-") + "-flag.png"} />
        </div>
      </div>
    );
  }

  redirectUser = () => {
    this.props.history.push(`/visa-type/israel/${this.props.cardData.to}`);
  };
}

export default PopularCard;
