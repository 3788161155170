import React, { useEffect, useState } from "react";
import "../../../assets/css/visaManager.css";
import { Box, Button, Chip, IconButton, TextField } from "@material-ui/core";
import { Search, FilterListRounded, BackspaceOutlined, AddRounded, HighlightOff, CloudDownloadOutlined } from "@material-ui/icons";
import PrettyTable from "../../widgets/PrettyTable";
import adminService from "../../../Services/adminService";
import VisaAddPopup from "./VisaAddPopup";
import FilterPopup from "./FilterPopup";
import Swal from "sweetalert2";
import employeeServices from "../../../Services/employeeService";

const baseColumns = [
  { title: "תאריך היצירה", field: "dateCreated" },
  { title: "מספר דרכון", field: "passportNum" },
  { title: "שם מלא", field: "agentGivenName" }, //, editable: "onAdd"
  { title: 'דוא"ל', field: "email" },
  { title: "יעד", field: "to" },
  {
    title: "סטטוס",
    field: "status",
    lookup: {
      AGENT_PAID_AND_FILLS: "ממתין לטיפולך",
      AGENT_PAID_CLIENT_FILLS: "ממתין לטיפול הלקוח שלך",
      WAITING_PROCESSOR_PAID: "אנו מטפלים בבקשה",
      WAITING_PROCESSOR_NOT_PAID: " אנו מטפלים בבקשה לא שולם",
      WAITING_IN_OFFICE: "נמצא במשרד",
      GOT_INTO_EMBASSY: "נכנס לקונסוליה",
      WAITING_FOR_DOCUMENTS: "ממתינים למסמכים נוספים",
      ACCES_GRANTED: "קיבל אשרה",
      WITHOUT_TREATMENT: "ללא טיפול",
      DENIED: "סירוב",
      REFUND: "החזר כספי"
    }
  },
  { title: " ", field: "..." }
];

const agencyColumns = [
  { title: "סוכן", field: "agentFullName" },
  { title: "סוכנות", field: "belongToAgencyId" }
];

const regularColumns = [{ title: "נוצר ע״י", field: "createdByProcessorName" }];
const role = localStorage.getItem("role");
const permission = localStorage.getItem("permission");
const initVisaTab = role !== "employee" || permission === "b2c" ? "regular" : "agency";

const VisaManager = ({ role }) => {
  const [visasList, setVisasList] = useState([]);
  const [visasToDisplay, setVisasToDisplay] = useState([]);
  const [visasTypeTab, setVisasTypeTab] = useState(initVisaTab); // "regular" || "agency"
  const [columns, setColumns] = useState([]);
  const [isNewVisaPopupOpen, setIsNewVisaPopupOpen] = useState(false);
  const [isFiltersPopupOpen, setIsFiltersPopupOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [agenciesList, setAgenciesList] = useState([]);
  const [visaToEdit, setVisaToEdit] = useState(null);
  const [filters, setFilters] = useState({});
  const [searchDates, setSearchDates] = useState({});
  const [destinations, setDestinations] = useState([]);
  const [filterChips, setFilterChips] = useState([]);
  const [allItineraries, setAllItineraries] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const getVisas = async () => {
    let serverRes;
    if (role === "employee") {
      serverRes = await employeeServices.getOwnEmployeeManagedVisas();
    } else {
      serverRes = await adminService.getAllVisas();
    }
    if (serverRes.success) {
      const allVisas = serverRes.visaList.map((item) => {
        if (item.agentGivenName) return item;
        if (item.firstName) item.agentGivenName = item.firstName;
        if (item.firstName && item.lastName) item.agentGivenName += " " + item.lastName;
        return item;
      });

      setVisasList(allVisas);
      setVisasToDisplay(allVisas);
    }
  };

  useEffect(() => {
    getVisas();

    if (role !== "employee" || permission === "b2b") {
      employeeServices.getAllAgencies("").then((res) => {
        if (res.success) {
          const dataFromDB = res.agenciesList;
          setAgenciesList(dataFromDB);
        }
      });
    }

    employeeServices.getAllFromToOptsAndFields().then((res) => {
      if (res.success) {
        const allFromTo = res.allFromTo;
        const onlyTos = Array.from(new Set(allFromTo.map((item) => item.to)));
        setDestinations(onlyTos);
        setAllItineraries(allFromTo);
      }
    });

    const getEmployees = async () => {
      const employees = await adminService.getAllEmployees();
      if (employees.employeesList) setEmployeeList(employees.employeesList);
    };
    if (role !== "employee") getEmployees();
  }, []);

  useEffect(() => {
    const newColumns = [...baseColumns];
    if (visasTypeTab === "regular") {
      newColumns.splice(3, 0, ...regularColumns);
    } else {
      newColumns.splice(3, 0, ...agencyColumns);
    }
    setColumns(newColumns);

    const visasToFilter = [...visasList];
    const newVisas = visasToFilter.filter((visa) => (visasTypeTab === "regular" ? !visa.belongToAgencyId : !!visa.belongToAgencyId));
    setVisasToDisplay(newVisas);
    clearFilters();
  }, [visasTypeTab, visasList]);

  const resetVisas = () => {
    const visasToFilter = [...visasList];
    const newVisas = visasToFilter.filter((visa) => (visasTypeTab === "regular" ? !visa.belongToAgencyId : !!visa.belongToAgencyId));
    setVisasToDisplay(newVisas);
  };

  const editRow = (itemId) => {
    const visaToFind = visasList.filter((visa) => visa._id === itemId)[0];
    if (visaToFind) setVisaToEdit(visaToFind);
    setIsNewVisaPopupOpen(true);
  };

  const closeVisaEditPopup = () => {
    setVisaToEdit(null);
    setIsNewVisaPopupOpen(false);
  };

  const createExcelFile = (listOfVisa) => {
    const listOfVisasAndExtraServices = [];
    listOfVisa.forEach((visa) => {
      listOfVisasAndExtraServices.push(visa);
      const additionServices = visa.additionServices;
      if (!additionServices) return;
      additionServices.forEach((additionService) => {
        listOfVisasAndExtraServices.push({ ...visa, visaType: additionService.name, visaService: additionService.value });
      });
    });

    const overrideHeaders = {
      dateCreated: "תאריך יצירה",
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      passportNum: "מספר דרכון",
      to: "יעד",
      visaType: "סוג הויזה או שם השירות",
      urgencyLevel: "נוהל",
      visaPrice: "אגרה",
      visaService: "דמי טיפול",
      shovarNumber: "מספר שובר",
      agentFullName: "שם הסוכן"
    };

    const isFilteredByAgency = filterChips && filterChips.find((chip) => chip && chip[0] === "belongToAgencyId");
    let agencyNameAndDateRange = "";
    if (isFilteredByAgency) {
      agencyNameAndDateRange = agenciesList.find((agency) => agency._id === listOfVisa[0].belongToAgencyId)?.agencyName;
    }
    if (searchDates.start && searchDates.end) {
      agencyNameAndDateRange += agencyNameAndDateRange
        ? ` - ${searchDates.start} ~ ${searchDates.end}`
        : `${searchDates.start} ~ ${searchDates.end}`;
    }

    adminService.exportExcelClientSide(listOfVisasAndExtraServices, overrideHeaders, true, agencyNameAndDateRange);
  };

  const clearFilters = () => {
    setSearchKey("");
    setFilters({});
    setSearchDates({});
    setFilterChips([]);
    resetVisas();
  };

  const onFiltersApply = () => {
    const chips = Object.entries(filters);
    if (searchDates.start) chips.push(["searchDates", searchDates.start, searchDates.end]);
    if (searchKey) chips.push(["searchKey", searchKey]); // Not ideal
    setFilterChips(chips);

    onSearch();
  };

  const onSearch = (givenFilters = {}) => {
    const relevantSearchDates = givenFilters.searchDates || searchDates;
    const relevantFilters = givenFilters.filters || filters;
    const relevantSearchKey = givenFilters.searchKey || searchKey;

    if ((relevantSearchDates.start && !relevantSearchDates.end) || (!relevantSearchDates.start && relevantSearchDates.end)) return;
    if (!relevantSearchKey && !relevantSearchDates && !relevantFilters) return;
    const fields = { ...relevantFilters };

    if (fields.managedBy) {
      const idOnly = fields.managedBy._id;
      fields.managedBy = idOnly;
    }

    if (relevantFilters.belongToAgencyId && relevantFilters.belongToAgencyId.length > 0) {
      const onlyIds = relevantFilters.belongToAgencyId.map((el) => el._id);
      fields.belongToAgencyId = onlyIds;
    }
    employeeServices.getVisasBySearch(relevantSearchKey, relevantSearchDates, fields, visasTypeTab).then((res) => {
      if (res.visaList && res.visaList[0]) {
        const searchResults = res.visaList.map((item) => {
          if (item.agentGivenName) return item;
          if (item.firstName) item.agentGivenName = item.firstName;
          if (item.firstName && item.lastName) item.agentGivenName += " " + item.lastName;
          return item;
        });

        setVisasToDisplay(searchResults);
      } else {
        Swal.fire("!Whoops", "לא נמצאו רשומות.", "warning");
        setVisasToDisplay([]);
      }
    });
  };

  const getAgencyNameFromId = (idArr) => {
    if (!idArr) return;
    const agencies = agenciesList.filter((agency) => idArr.findIndex((el) => el._id === agency._id) > -1);
    const agencyNames = agencies.map((ag) => ag.agencyName);
    return agencyNames.join(" ,");
  };

  const getChipLabel = (chip) => {
    switch (chip[0]) {
      case "status":
        return `${"סטטוס"}: ${baseColumns[5].lookup[chip[1]]}`;
      case "to":
        return `${"יעד"}: ${chip[1]}`;
      case "searchDates":
        return `${"תאריכים"}: ${chip[1] || "?"} עד ${chip[2] || "?"}`;
      case "searchKey":
        return `חיפוש חופשי: ${chip[1]}`;
      case "belongToAgencyId":
        return `סוכנות: ${getAgencyNameFromId(chip[1])}`;
      case "managedBy":
        return `מנוהל על ידי: ${chip[1].agentFirstName} ${chip[1].agentLastName}`;
      default:
        return `${chip[0]}: ${chip[1]}`;
    }
  };

  const deleteChip = (filter) => {
    const givenFilters = {};

    switch (filter[0]) {
      case "searchDates":
        setSearchDates({});
        givenFilters.searchDates = {};
        break;
      case "searchKey":
        setSearchKey("");
        givenFilters.searchKey = "";
        break;
      default:
        const newFilters = { ...filters };
        delete newFilters[filter[0]];
        givenFilters.filters = newFilters;
        setFilters(newFilters);
    }

    onSearch(givenFilters);

    const newChips = filterChips.filter((currentChip) => currentChip[0] !== filter[0]);
    setFilterChips(newChips);
  };

  return (
    <>
      <Box className="visas-table-title-con">
        <Box>
          <h2>ויזות</h2>
          <p className="visas-subtitle">מצא את כל המידע על אשרות, המסמכים הנדרשים, המצב בפועל וכו'.</p>
        </Box>
        <Box className="visas-button-con">
          <Button
            variant="outlined"
            className="light-button"
            onClick={() => createExcelFile(visasToDisplay)}
            endIcon={<CloudDownloadOutlined />}
          >
            ייצא קובץ אקסל
            <span style={{ width: "12px" }} />
          </Button>
          <Button variant="contained" onClick={() => setIsNewVisaPopupOpen(true)} className="green-button" endIcon={<AddRounded />}>
            ויזה חדשה
            <span style={{ width: "12px" }} />
          </Button>
        </Box>
      </Box>
      <Box className="visas-tab-con">
        {(role !== "employee" || permission === "b2c") && (
          <Box className={`visas-tab ${visasTypeTab === "regular" ? " visas-tab-active" : ""}`} onClick={() => setVisasTypeTab("regular")}>
            רגילות
          </Box>
        )}

        {(role !== "employee" || permission === "b2b") && (
          <Box className={`visas-tab ${visasTypeTab === "agency" ? " visas-tab-active" : ""}`} onClick={() => setVisasTypeTab("agency")}>
            סוכנויות
          </Box>
        )}
      </Box>
      <Box className="visas-table-con">
        <Box className="visas-search-box">
          <Box className="visas-search-box-top">
            <TextField
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={onSearch}>
                    <Search />
                  </IconButton>
                ),
                placeholder: "חפש לפי תעודת זהות, שובר, שם, דוא״ל..."
              }}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") onSearch();
              }}
            />
            <Button onClick={() => setIsFiltersPopupOpen(true)} className="light-button">
              <FilterListRounded />
            </Button>
            <Button size="small" endIcon={<BackspaceOutlined />} onClick={clearFilters} className="light-button" style={{ width: "150px" }}>
              נקה מסננים
              <span style={{ width: "8px" }} />
            </Button>
          </Box>
          <Box className="visas-search-box-chips">
            {filterChips.map((chip) => (
              <Chip
                size="small"
                onDelete={() => deleteChip(chip)}
                label={getChipLabel(chip)}
                key={chip[0] + chip[1]}
                deleteIcon={<HighlightOff />}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <PrettyTable columns={columns} data={visasToDisplay} agencies={agenciesList} editRow={editRow} />
        </Box>
      </Box>
      <VisaAddPopup
        isOpen={isNewVisaPopupOpen}
        onClose={closeVisaEditPopup}
        visaToEdit={visaToEdit}
        agencies={agenciesList}
        destinations={destinations}
        allItineraries={allItineraries}
        reloadVisas={getVisas}
        role={role}
        employeeList={employeeList}
      />
      <FilterPopup
        isOpen={isFiltersPopupOpen}
        onClose={() => setIsFiltersPopupOpen(false)}
        filters={filters}
        setFilters={setFilters}
        agenciesList={agenciesList}
        destinations={destinations}
        onFiltersApply={onFiltersApply}
        searchDates={searchDates}
        setSearchDates={setSearchDates}
        clearFilters={clearFilters}
        isAgenciesTab={visasTypeTab === "agency"}
        employeeList={employeeList}
        role={role}
      />
    </>
  );
};

export default VisaManager;
