import React, { Component } from "react";
import testimonialImg from "../../assets/images/testimonial1.jpg";
import testimonialImg2 from "../../assets/images/testimonial2.jpg";
import testimonialImg3 from "../../assets/images/testimonial3.jpg";
import testimonialImg4 from "../../assets/images/testimonial4.jpg";

class AboutUsTestimonial extends Component {
  render() {
    var currentTestimony = this.props.currentTestimony;
    var getRightImg;
    if (currentTestimony.imageKey === "1") getRightImg = testimonialImg;
    if (currentTestimony.imageKey === "2") getRightImg = testimonialImg2;
    if (currentTestimony.imageKey === "3") getRightImg = testimonialImg3;
    if (currentTestimony.imageKey === "4") getRightImg = testimonialImg4;
    return (
      <div className="testimonial-con auto0 center">
        <img className="testimonial-img" src={getRightImg} />
        <div className="testimonial-comment">
          <h4 className="center">{currentTestimony.testimony}</h4>
        </div>
        <div className="testimonial-Author">
          <h3 className="center">{currentTestimony.name}</h3>
        </div>
      </div>
    );
  }
}

export default AboutUsTestimonial;
