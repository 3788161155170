const yup = require("yup");
var validationsMsgTxt = require("../assets/translations/he/validationErrs.json");

module.exports = yup.object().shape({
  visaIdOrMobile: yup
    .string()
    .trim()
    .required(validationsMsgTxt.requiredMsg)
    .min(6, validationsMsgTxt.visaIdOrMobileTooShort)
    .max(24, validationsMsgTxt.badVisaIdFormat),
  passportNum: yup
    .string()
    .trim()
    .required(validationsMsgTxt.requiredMsg)
    .min(5, validationsMsgTxt.badFormat)
    .max(24, validationsMsgTxt.badFormat)
});
