import React from "react";
import Slider from "@material-ui/core/Slider";

function BottomSlider(props) {
  return (
    <div className="bottom-slider-con">
      <div className="center">
        <div className="slider-con-progress">
          <div className="inline-block txt-slider">
            <span className="primary-color">{props.stepsAmountFinished}</span>/{props.stepsAmount}
          </div>
          <div className="inline-block slider-progress">
            <Slider
              value={props.stepsAmountFinished}
              aria-labelledby="discrete-slider-custom"
              step={1}
              min={0}
              max={props.stepsAmount}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomSlider;
