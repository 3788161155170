import React, { useEffect, useState } from "react";
import HomeBanner from "./homeBanner";
import OriginDestination from "./originDestination";
import PopularContainer from "./popularDestinations/popularContainer";
import VisaSteps from "./visaSteps";
import HomeFAQ from "./homeFAQ";
import WhoServiceFor from "./whoServiceFor";
import WhyUsHome from "./whyUs/whyUsHome";

import visaService from "../../../Services/visaService";

import "../../../assets/css/home.css";

const B2bHomeContainer = ({ history }) => {
  const [allFromTo, setAllFromTo] = useState(null);
  const [fromOnly, setFromOnly] = useState(null);

  useEffect(() => {
    visaService.getAllFromToOpts().then((res) => {
      if (res["success"]) {
        const allFromToFromRes = res["allFromTo"] || [];

        setAllFromTo(allFromToFromRes);
        setFromOnly(allFromToFromRes.filter((v, i, a) => a.findIndex((t) => t.from === v.from) === i));
      }
    });
  }, []);

  return (
    <div className="mb-40">
      <HomeBanner />
      {allFromTo && fromOnly && (
        <>
          <OriginDestination allFromTo={allFromTo} fromOnly={fromOnly} history={history} />
          <PopularContainer allFromTo={allFromTo} history={history} />
        </>
      )}

      <VisaSteps />
      <HomeFAQ />
      <WhoServiceFor />
      <WhyUsHome />
    </div>
  );
};

export default B2bHomeContainer;
