import React, { Component, Fragment } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import OneByOneManager from "./components/oneByOneQuestionnaire/oneByOneManager";
import InterceptorsComponent from "./components/interceptorsComponent";
import AppContainer from "./components/appContainer";
import DubaiSpecific from "./components/oneByOneQuestionnaire/dubaiSpecific";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import "bootstrap/dist/css/bootstrap.min.css";
import ShowPaymentResult from "./components/oneByOneQuestionnaire/showPaymentResult";
import RedirectForm from "./components/destination/RedirectForm";
import MoroccoForm from "./components/groupForm/MoroccoForm";
import ScrollToTop from "./components/layout/scrollToTop";
import PaymentIframe from "./components/oneByOneQuestionnaire/paymentIframe";
import PayVisaById from "./components/payVisaById/PayVisaById";
import IsraelForm from "./components/groupForm/IsraelForm";

const theme = createTheme({
  palette: {
    primary: { main: "#0074D9" },
    secondary: { main: "#11cb5f" }
  },
  typography: {
    button: {
      textTransform: "none"
    },
    text: {
      textTransform: "none"
    }
  }
});

class App extends Component {
  render() {
    return (
      <Fragment>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <InterceptorsComponent />

            <BrowserRouter>
              <ScrollToTop>
                <div className="rtl-layout">
                  <Switch>
                    <Route exact path="/visa-type/israel/united-arab-emirates/" component={DubaiSpecific} />
                    <Route path="/questionnaire/:from/:to/:visaType" component={OneByOneManager} />
                    <Route path="/payment-result/:tranzilaRes/:tranzillaParams?" component={ShowPaymentResult} />
                    <Route
                      path="/group-form/:organizationName/:destination/:dateOfTrip/:groupId/:groupAgentName"
                      component={RedirectForm}
                    />
                    <Route path="/group-form/:organizationName/:dateOfTrip/:groupId/:groupAgentName" component={MoroccoForm} />
                    <Route path="/group-form-israel/:groupId" component={IsraelForm} />

                    <Route path="/text-payment" component={PaymentIframe} />
                    <Route
                      path="/pay-for-visa-by-id/:visaId/:to/:visaType/:fullName/:mobile/:passportNum/:isPaymentFailedFromFlow?"
                      component={PayVisaById}
                    />

                    <Route path="/" component={AppContainer} />
                  </Switch>
                </div>
              </ScrollToTop>
            </BrowserRouter>
          </Provider>
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

export default App;
