import { SET_LOADER_ON, SET_LOADER_OFF, SET_LANGUAGE } from "./actionTypes";

export const setLanguage = (newLang) => {
  return (dispatch, getstate) => {
    localStorage.setItem("currentLang", newLang.locale);
    localStorage.setItem("layoutDir", newLang.dir);
    localStorage.setItem("langIcon", newLang.icon);
    if (newLang.locale === "he") document.title = "ויזה אלקטרונית";
    else document.title = "Electronic Visa";
    dispatch({ type: SET_LANGUAGE, payload: newLang });
  };
};

export const setLoaderOn = () => {
  return (dispatch, getstate) => {
    dispatch({ type: SET_LOADER_ON });
  };
};

export const setLoaderOff = () => {
  return (dispatch, getstate) => {
    dispatch({ type: SET_LOADER_OFF });
  };
};
