import visaSchema from "../../schemas/visaSchema";
import validationBuilder from "./validationBuilder";

const validationFunctions = {
  validateOneField: (question, val) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (question.type === "radioBtns" || question.type === "radioCircles" || question.type === "autocomplete") {
          return resolve(val);
        }
        if (question === "initEmail") {
          question = {
            key: "email",
            validation: {
              validationType: "txtType",
              trim: true,
              email: true,
              lowercase: true,
              min: "5",
              max: "40"
            }
          };
        }
        var key = question.key;

        // Phone must start with 05 or 97205
        if (key === "phoneNumber") {
          if (!/^(05|97205)/.test(val)) {
            return reject({ message: "המספר חייב להתחיל ב-05" });
          } else if (val.length < 10) {
            return reject({ message: "חייב להיות באורך של 10 מספרים" });
          }
        }

        if (question.validation.validationType === "fileType") return resolve({ [question.key]: val });

        var getSchema = validationBuilder(question.key, question.validation);
        var validatedData;

        // if (getSchema._nodes.includes(key)) {
        var yupObject = {};
        yupObject[key] = val;
        validatedData = await getSchema.validateAt(key, yupObject);
        // }

        return resolve(validatedData);
      } catch (validationErr) {
        return reject(validationErr);
      }
    });
  },

  validateSchema: async (schema, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const validatedData = await schema.validate(data, { abortEarly: false });
        return resolve(validatedData);
      } catch (validationErrors) {
        const getErrs = validationErrors.inner.map((item) => {
          return { key: [item.params.path], val: item.message };
        });
        const errsObj = {};
        for (const currentErr of getErrs) {
          if (errsObj[currentErr.key]) continue;
          errsObj[currentErr.key] = errsObj[currentErr.key] = currentErr.val;
        }
        return reject(errsObj);
      }
    });
  },

  /// currently not in use -> maybe i will make it a validator for an array ///
  validateObject: (fieldsObj) => {
    return new Promise(async (resolve, reject) => {
      try {
        // MAybe i will change this to also return the validated data.
        for (const key in fieldsObj) {
          var validatedData;
          var getSchema = visaSchema();
          if (getSchema._nodes.includes(key)) {
            var yupObject = {};
            yupObject[key] = fieldsObj[key];
            validatedData = await getSchema.validateAt(key, yupObject);
          } else {
            // the schema is not updated and does not know about this field... let it continue if not empty
          }
        }

        return resolve();
      } catch (validationErr) {
        return reject(validationErr);
      }
    });
  }
};

export default validationFunctions;
