import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "./Header";
import TakeWebCamPicture from "./TakeWebCamPicture";
import { isValidEmail, isValidPhone, isValidName, isValidYear } from "../../Services/validation/validationUtiles";
import passportIconPng from "../../assets/images/passport_icon.png";
import adminServices from "../../Services/adminService";
import visaService from "../../Services/visaService";
import "../../assets/css/groupForm.css";
import config from "../../config";
import { photoPopUpHtml } from "./formUtils";
import { isFileImage, isObjectNotEmpty, capitalizeFirstLetter } from "../../utils";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

const formInputs = [
  { label: "Email", name: "email" },
  { label: "repeatEmail", name: 'repeatEmail'},
  { label: "Phone", name: "phone" },
  { label: "Entry Date", name: "entryDate" },
  { label: "Address", name: "address" },
  { label: "Father's Full Name", name: "fatherFullName" },
  { label: "Mothers's Full Name", name: "motherFullName" },
  { label: "Company Name", name: "companyName" },
  { label: "Work Address", name: "workAddress" },
  { label: "Work Email", name: "workEmail" },
  { label: "Work Phone", name: "workPhone" },
  { label: "Occupation", name: "occupation" },
  { label: "Year of Visit", name: "yearOfVisit" }
];

const defaultFormData = {
  email: "",
  repeatEmail: "",
  phone: "",
  entryDate: "",
  address: "",
  fatherFullName: "",
  motherFullName: "",
  companyName: "",
  workAddress: "",
  workEmail: "",
  workPhone: "",
  occupation: "",
  yearOfVisit: "",
  pictureOfPassport: null
};

function IsraelForm() {
  let { groupId } = useParams();
  const destination = "israel";

  const [formData, setFormData] = useState(defaultFormData);
  const [formatErrors, setFormatErrors] = useState({});
  const [takePictureType, setTakePictureType] = useState(null);
  const [hasVisited, setHasVisited] = useState("false"); // string for MUI radio
  const formQuestions = useRef();

  const fileInputs = [
    {
      label: "Passport Image",
      icon: passportIconPng,
      name: "pictureOfPassport",
      ref: useRef()
    }
  ];

  const onChange = ({ target: { name, value } }) => {
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[name];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [name]: value });
  };

  const onChangeRadio = (e) => {
    setHasVisited(e.target.value);
    setFormData({ ...formData, yearOfVisit: "" });
  };

  const onChangeFile = ({ target: { name, files } }) => {
    if (!isFileImage(files[0])) {
      Swal.fire("Oops,", "Only images or PDF files can be uploaded", "error");
      return;
    }
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[name];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [name]: files[0] });
  };

  const savePictureFromCamera = (takePictureType, imgSrc) => {
    setFormatErrors((prevFormatErrors) => {
      delete prevFormatErrors[takePictureType];
      return { ...prevFormatErrors };
    });
    setFormData({ ...formData, [takePictureType]: imgSrc });
  };

  const onFileInputClick = async (name, inputRef) => {
    const { isConfirmed } = await Swal.fire({
      html: photoPopUpHtml(name + "En"),
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showCloseButton: true
    });
    if (!isConfirmed) return;
    if (!config.isMobile) {
      const userResponse = await Swal.fire({
        icon: "question",
        text: "Would you like to take a picture using your computer/laptop camera or upload an image file from the computer?",
        confirmButtonText: "Take Picture",
        cancelButtonText: "Upload Picture",
        showCancelButton: true,
        showCloseButton: true
      });

      if (userResponse.value) {
        setTakePictureType(name);
      } else if (userResponse.dismiss === "cancel") {
        inputRef.current.click();
      }
    } else {
      inputRef.current.click();
    }
  };

  const validateForm = () => {
    const formatErrors = {};

    if (!isValidEmail(formData.email)) {
      formatErrors.email = "Email is invalid";
    }

    if (formData.email !== formData.repeatEmail){
      formatErrors.repeatEmail = "Emails do not match"
    }

    if (!isValidPhone(formData.phone)) {
      formatErrors.phone = "Phone number is invalid";
    }

    if (!isValidName(formData.fatherFullName)) {
      formatErrors.fatherFullName = "Father name is invalid";
    }

    if (!isValidName(formData.motherFullName)) {
      formatErrors.motherFullName = "Mother name is invalid";
    }

    if (hasVisited === "true" && !isValidYear(formData.yearOfVisit)) {
      formatErrors.yearOfVisit = "Year is invalid";
    }

    formInputs.forEach((field) => {
      if (field.name === "yearOfVisit" && hasVisited === "false") return;
      if (!formData[field.name]) {
        formatErrors[field.name] = "Required field";
      }
    });

    if (!formData.pictureOfPassport) {
      formatErrors.pictureOfPassport = true;
    }

    setFormatErrors(formatErrors);
    return formatErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formatErrors = validateForm();
    if (isObjectNotEmpty(formatErrors)) {
      formQuestions.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      return;
    }

    const form = new FormData();
    for (const file in formData) {
      form.append(file, formData[file]);
    }
    form.append("groupId", groupId);

    const addParticipantRes = await adminServices.addParticipantToGroup(form);
    const newGroupVisaRes = await visaService.createNewGroupVisa({
      visaData: {
        ...formData,
        destination: capitalizeFirstLetter(destination),
        pictureOfPassport: addParticipantRes.data.pictureOfPassportFileName
      }
    });

    if (addParticipantRes?.success && newGroupVisaRes?.success) {
      setFormData(defaultFormData);
      Swal.fire("Success", "The form has been sent successfully", "success");
    }
  };

  return (
    <div>
      <Header destination={destination} lang={'en'}/>

      <form className="group-form-form" onSubmit={handleSubmit} ref={formQuestions}>
        <h2 className="group-form-title">Everything is almost ready for your arrival</h2>

        <div className="group-form-inputs-container text-english">
          {formInputs.map(({ label, name }) => (
            <label
              key={name}
              className={`group-form-input-label text-english ${name === "yearOfVisit" && hasVisited === "false" ? "hidden" : ""}`}
            >
              {label}:
              <input
                className={`group-form-input text-english ${name === "yearOfVisit" && hasVisited === "false" ? "hidden" : ""}`}
                value={formData[name]}
                name={name}
                type={name === "entryDate" ? "date" : "text"}
                placeholder={label}
                onChange={onChange}
              />
              {formatErrors[name] && <p className="text-format-error">* {formatErrors[name]}</p>}
            </label>
          ))}
          <div>
            <label className="group-form-radio-label text-english">
              Have You Visited Israel Before?
              <RadioGroup value={hasVisited} row onChange={onChangeRadio}>
                <FormControlLabel control={<Radio value="true" color="primary" />} label="Yes" />
                <FormControlLabel control={<Radio value="false" color="primary" />} label="No" />
              </RadioGroup>
            </label>
          </div>
        </div>

        <div className="group-form-inputs-container-center mb-20 text-english">
          {fileInputs.map(({ label, icon, name, ref }) => (
            <div key={name} className="group-form-file-input-container">
              <img src={icon} alt="icon" />

              <span className="group-form-file-input-text">
                <p className="group-form-file-input-text-label text-english">{label}</p>
                {formData[name] ? (
                  <p className="group-form-file-input-text-success text-english">Document attached</p>
                ) : (
                  <p className={"group-form-file-input-text-falsy text-english" + (formatErrors[name] ? "file-input-error" : "")}>
                    No document attached
                  </p>
                )}
              </span>

              <button
                className="group-form-file-input-button"
                type="button"
                onClick={() => {
                  onFileInputClick(name, ref);
                }}
              >
                {formData[name] ? <>&#10004;</> : "Upload"}
              </button>

              <input hidden ref={ref} name={name} type="file" onChange={onChangeFile} />
            </div>
          ))}
        </div>
        <button className="submit-button" type="submit" value="Submit">
          Submit
        </button>
      </form>

      <TakeWebCamPicture
        takePictureType={takePictureType}
        setTakePictureType={setTakePictureType}
        savePictureFromCamera={savePictureFromCamera}
      />
    </div>
  );
}

export default IsraelForm;
