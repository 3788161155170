const yup = require("yup");
var validationsMsgTxt = require("../assets/translations/he/validationErrs.json");
var stringNoNumbersRegex = /^[a-zA-Z\-.']+$/;

export default function () {
  return yup.object().shape({
    firstName: noNumString(),
    lastName: noNumString(),
    email: yup.string().lowercase().email(validationsMsgTxt.badEmailFormatMsg).required(validationsMsgTxt.requiredMsg),
    passportNum: yup.string().trim().required(validationsMsgTxt.requiredMsg)
  });
}

//  // for password...
//  passwordConf: yup.string().oneOf([yup.ref('password')], "Password must be the same").required()

function noNumString() {
  return yup
    .string()
    .min(2, validationsMsgTxt.minLength2Msg)
    .trim()
    .matches(stringNoNumbersRegex, validationsMsgTxt.numbersNotAllowed)
    .required(validationsMsgTxt.requiredMsg);
}
