import React, { Component, Fragment } from "react";
import agentsService from "../../../Services/agentsService";
import { connect } from "react-redux";
import { setVisaForEdit } from "../../../Actions/loggedinActions";
import NewVisaPopup from "./newVisaPopup";
import { Button } from "@material-ui/core";
import MaterialTableComponent from "../../widgets/materialTable";
import Swal from "sweetalert2";
import VideoManager from "../../videoPopup/VideoManager";
import employeeServices from "../../../Services/employeeService";

class AgentProfile extends Component {
  state = {
    visasList: [],
    isDataLoaded: false,
    isNewVisaPopupOpen: false,
    isShovarRequired: false,

    columns: [
      { title: "שם מלא", field: "agentGivenName" }, //, editable: "onAdd"
      { title: "מספר דרכון", field: "passportNum", editable: "never" },
      { title: 'דוא"ל', field: "email", editable: "never" },

      {
        title: "סטטוס",
        field: "status",
        lookup: {
          AGENT_PAID_AND_FILLS: "ממתין לטיפולך",
          AGENT_PAID_CLIENT_FILLS: "ממתין לטיפול הלקוח שלך",
          WAITING_PROCESSOR_PAID: "אנו מטפלים בבקשה",
          WAITING_PROCESSOR_NOT_PAID: " אנו מטפלים בבקשה לא שולם",
          WAITING_IN_OFFICE: "נמצא במשרד",
          GOT_INTO_EMBASSY: "נכנס לקונסוליה",
          WAITING_FOR_DOCUMENTS: "ממתינים למסמכים נוספים",
          ACCES_GRANTED: "קיבל אשרה",
          WITHOUT_TREATMENT: "ללא טיפול",
          DENIED: "סירוב",
          REFUND: "החזר כספית"
        }
      },
      { title: "מוצא", field: "from" },
      { title: "יעד", field: "to" }
    ]
  };

  constructor() {
    super();
    this.state.fullName = localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");
    this.state.emailLogin = localStorage.getItem("email");
  }

  componentDidMount() {
    agentsService.getAgentVisas().then((res) => {
      if (res["success"]) {
        var visasList = res["visaList"].map((item) => {
          if (item.agentGivenName) return item;
          if (item.firstName) item.agentGivenName = item.firstName;
          if (item.firstName && item.lastName) item.agentGivenName += " " + item.lastName;
          return item;
        });

        this.setState({ visasList }, () => {
          this.setState({ isDataLoaded: true });
        });
      }
    });

    agentsService.getAllFromToAndPrices().then((res) => {
      if (res["success"]) {
        var allFromTo = res["allFromTo"];
        var fromOnly = allFromTo.filter((v, i, a) => a.findIndex((t) => t.from === v.from) === i).map((item) => item.from);
        this.setState({ fromOnly, allFromTo, discount: res["discount"] });
      }
    });

    agentsService.getAgencyById().then((res) => {
      if (res.success) {
        const dataFromDB = res.agenciesList[0];
        const shovarStatus = dataFromDB.isShovarRequired;
        this.setState({ isShovarRequired: shovarStatus });
      }
    });
  }

  render() {
    return (
      <div className="relative mt-50">
        <div className="mb-40">
          <NewVisaPopup
            isShovarRequired={this.state.isShovarRequired}
            fromOnly={this.state.fromOnly}
            allFromTo={this.state.allFromTo}
            isOpen={this.state.isNewVisaPopupOpen}
            close={() => {
              this.setState({ isNewVisaPopupOpen: false });
            }}
            createNewVisa={this.createNewVisa}
          />
          <div className="right">
            <h3>
              <b>שלום,</b> <b className="primary-color">{this.state.fullName}</b>
            </h3>
          </div>

          <div
            className="position-new-visa-btn"
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "500px",
              marginTop: "20px"
            }}
          >
            <div className="mt-20">
              <VideoManager
                title="Agents Tutorial 2"
                btn_title="סרטון לקוח ממלא"
                btn_logo="▶"
                video_link="https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/VIDEO1.mp4"
              />
            </div>
            <div className="mt-20">
              <VideoManager
                title="Agents Tutorial"
                btn_title="סרטון סוכן ממלא"
                btn_logo="▶"
                video_link="https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/VIDEO2.mp4"
              />
            </div>
            <Button color="primary" variant="contained" onClick={this.initNewVisaPopup} style={{ height: "50px", marginTop: "20px" }}>
              ויזה חדשה
            </Button>
          </div>
        </div>

        <br />
        <hr />
        {this.state.isDataLoaded ? (
          <Fragment>
            {this.state.visasList[0] ? (
              <div className="mt-50">
                <MaterialTableComponent
                  options={{ pageSizeOptions: [5, 10, 20, 100] }}
                  data={this.state.visasList}
                  columns={this.state.columns}
                  redirectToEditVisa={this.redirectToEditVisa}
                  tableTitle="הויזות שלך"
                  allowedDelete={false}
                  disableAdding={true}
                />
              </div>
            ) : (
              <div className="mt-50">
                <h2 className="center">
                  <b>עדיין לא יצרת ויזות. לחץ על 'ויזה חדשה' בחלק העליון של העמוד על מנת לייצר ויזה ללקוח</b>
                </h2>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="mt-50">
            <h2 className="center">
              <b>טוען את הויזות שלך...</b>
            </h2>
          </div>
        )}
      </div>
    );
  }

  redirectToEditVisa = async (currentVisa, event) => {
    currentVisa.visaId = currentVisa._id;

    const serverRes = await agentsService.getAgentSpecificVisa(currentVisa._id);
    if (serverRes.success) {
      const visaData = serverRes.visaData;
      this.props.setVisaForEdit(visaData);
      var buildLink = `/questionnaire/${currentVisa.from}/${currentVisa.to}/${currentVisa.visaType}`;
      this.props.history.push(buildLink);
    }
  };

  initNewVisaPopup = () => {
    this.setState({ isNewVisaPopupOpen: true });
  };

  createNewVisa = (data) => {
    agentsService.initNewAgentVisa(data).then((res) => {
      if (res["success"]) {
        this.setState({ isNewVisaPopupOpen: false });

        var visaData = res["newAgentVisa"];
        var visasList = [...this.state.visasList];
        visasList.unshift(visaData);

        if (data.whoFills === "agentFills") {
          Swal.fire({
            title: "נוצרה ויזה חדשה בהצלחה",
            text: "תרצה להתחיל במילוי המידע של הלקוח?",
            icon: "success",
            showCancelButton: true
          }).then((result) => {
            if (result.value) {
              visaData.visaId = visaData._id;
              this.props.setVisaForEdit(visaData);
              var buildLink = `/questionnaire/${visaData.from}/${visaData.to}/${visaData.visaType}`;
              this.props.history.push(buildLink);
            } else {
              this.refreshTable(visasList);
            }
          });
        } else {
          this.refreshTable(visasList);
          Swal.fire("נוצרה ויזה חדשה בהצלחה", "", "success");
        }
      } else if (res.badEmail) {
        Swal.fire('דוא"ל שהוזן לא תקין', "", "warning");
      } else if (res.badPassportNum) {
        Swal.fire("דרכון לא חוקי", "", "warning");
      }
    });
  };

  refreshTable = (newList) => {
    this.setState({ isDataLoaded: false }, () => {
      this.setState({ visasList: newList, isDataLoaded: true });
    });
  };
}

const mapDispatchToProps = (dispatch, userLoginData) => {
  return {
    setVisaForEdit: (visaData) => dispatch(setVisaForEdit(visaData))
  };
};

export default connect(null, mapDispatchToProps)(AgentProfile);
