import React, { Component, Fragment } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NavLinksOffline from "./navLinks/navOfflineLinks";
import NavLinksOnline from "./navLinks/navOnlineLinks";
import SidebarMenu from "./sidebarMenu";
import logoWhite from "../../assets/images/logo-white.png";
import b2cLogoBlue from "../../assets/images/logo-b2c-blue.svg"
import "../../assets/css/layout/nav.css";
import config from "../../config";

const whiteLogoSrc = config.isWebsiteB2C ? b2cLogoBlue : logoWhite;

class NavBar extends Component {
  render() {
    const correctLinks = this.props.isLoggedIn ? <NavLinksOnline /> : <NavLinksOffline />;

    return (
      <div className={"nav-con" + (config.isWebsiteB2C ? " b2c-background-color" : "")}>
        <div className="container">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="relative">
                <div className={config.isWebsiteB2C ? "horizontal-menu" : ""}>

                  <ul className={`inline-block logo-con${this.props.isDubai ? " strech-full" : ""}`}>
                    <Link to={!this.props.isDubai ? "/" : "/visa-type/israel/united-arab-emirates"}>
                      <img alt="Visa logo" src={whiteLogoSrc} />
                    </Link>
                  </ul>

                  {this.props.isDubai ? null : (
                    <Fragment>
                      <div className="inline-block nav-links-con">
                        <ul className="inline-block">{correctLinks}</ul>
                        {/* <ul className="inline-block">
                          <ListItem button component="li">
                            <Button
                              to="/"
                              component={Link}
                              size="small"
                              className="background-success color-white"
                              onClick={this.props.closeDrawer}
                            >
                              קבל ויזה
                            </Button>
                          </ListItem>
                        </ul> */}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {this.props.isDubai ? null : <SidebarMenu correctLinks={correctLinks} isLoggedIn={this.props.isLoggedIn} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loggedinReducer.isLoggedIn
  };
};

export default connect(mapStateToProps)(NavBar);
