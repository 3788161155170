import React, { useEffect, useState } from "react";
import B2CPopularCard from "./b2cPopularCard";

const B2CPopularContainer = (props) => {
  const [populars, setPopulars] = useState([]);

  useEffect(() => {
    let createPopulars = props.allFromTo.sort((a, b) => {
      if (!a.importanceLevel) return 1;
      if (!b.importanceLevel) return -1;
      return a.importanceLevel < b.importanceLevel ? 1 : -1;
    });

    createPopulars = createPopulars.filter((item) => item.importanceLevel);
    createPopulars = createPopulars.filter((v, i, a) => a.findIndex((t) => t.to === v.to) === i);

    if (createPopulars.length > 8) createPopulars = createPopulars.slice(0, 8);

    setPopulars((createPopulars && createPopulars.slice(0, 3)) || []);
  }, []);

  return (
    <div className="container">
      <div className="home-width-90 auto0 mt-70">
        <h3 className="primary-color center mb-20 heb-bold">
          <b>המדינות הפופולריות</b>
        </h3>

        <div className="width100 flex b2c-popularCardContainer flex-reg">
          {populars.map((curPopular, index) => (
            <B2CPopularCard key={curPopular.to} cardData={curPopular} history={props.history} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default B2CPopularContainer;
