import { SET_LOGGED_IN, LOGOUT, SET_VISA_FOR_EDIT } from "./actionTypes";

export const setAgentLoggedIn = (userDataToSet) => {
  return (dispatch, getstate) => {
    localStorage.setItem("token", userDataToSet["token"]);
    localStorage.setItem("email", userDataToSet["email"]);
    localStorage.setItem("lastName", userDataToSet["lastName"]);
    localStorage.setItem("firstName", userDataToSet["firstName"]);
    localStorage.setItem("isLoggedin", "true");

    const role = userDataToSet.role;
    const permission = userDataToSet.permission;
    if (role) localStorage.setItem("role", role);
    if (permission) localStorage.setItem("permission", permission);
    dispatch({ type: SET_LOGGED_IN });
  };
};

export const setLoggedOut = () => {
  return (dispatch, getstate) => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("lastName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("isLoggedin");
    localStorage.removeItem("role");
    localStorage.removeItem("permission");

    dispatch({ type: LOGOUT });
  };
};

export const setVisaForEdit = (visaToSet) => {
  return (dispatch, getstate) => {
    dispatch({ type: SET_VISA_FOR_EDIT, payload: visaToSet });
  };
};

// export const updateStrengthSlider = (sliderStrength, clientNextStep) => ({
//   type: UPDATE_STRENGTH_SLIDER,
//   payload: { sliderStrength, clientNextStep }
// });

// export const setNewAntibodies = (newAntibodies, PRA) => {
//   return (dispatch, getstate) => {
//     var hasValidAntibodies = false;
//     if (newAntibodies && newAntibodies.data) {
//       for (const key in newAntibodies.data) {
//         if (newAntibodies.data[key].length) {
//           hasValidAntibodies = true;
//           break;
//         }
//       }
//     }

//     dispatch({ type: NEW_ANTIBODIES, payload: { newAntibodies, PRA, shouldUpdateSlider: hasValidAntibodies } });
//   };
// };

export const getClientExtendedData = () => {
  return (dispatch, getstate) => {};
};

export const updateUserExtendedData = (updates, isFields) => {
  return (dispatch, getstate) => {
    // dispatch({ type: UPDATE_CLI_EXTENDED, payload: { updates, isFields } });
  };
};

export const setNewOrdersForPayment = (idsForOrders) => {
  return (dispatch, getstate) => {
    // dispatch({ type: SET_ORDERS_FOR_PAYMENT, payload: idsForOrders });
  };
};
