import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";

export default class ContactForm extends React.Component {
  state = {
    fields: {},
    errors: {}
  };

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "Cannot be empty";
    }

    // if (typeof fields["firstName"] !== "undefined") {
    //   if (!fields["firstName"].match(/^[a-zA-Z]+$/)) {
    //     formIsValid = false;
    //     errors["firstName"] = "Only letters";
    //   }
    // }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "Cannot be empty";
    }

    // if (typeof fields["lastName"] !== "undefined") {
    //   if (!fields["lastName"].match(/^[a-zA-Z]+$/)) {
    //     formIsValid = false;
    //     errors["lastName"] = "Only letters";
    //   }
    // }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Cannot be empty";
    }

    if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;

    var newState = { fields };
    if (this.state.errors[field]) {
      var errors = this.state.errors;
      delete errors[field];
      newState["errors"] = errors;
    }
    this.setState(newState);
  }

  onAddressFormSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.props.onSubmit(this.state.fields, () => {
        this.setState({ fields: {} });
      });
    } else {
      Swal.fire("", "", "warning");
    }
  };

  render() {
    return (
      <form onSubmit={this.onAddressFormSubmit.bind(this)} className="mb-60">
        <TextField
          label="שם פרטי"
          className="field-strech"
          error={this.state.errors["firstName"] ? true : false}
          onChange={this.handleChange.bind(this, "firstName")}
          value={this.state.fields["firstName"] || ""}
        />
        <div className="mt-10">
          <TextField
            label="שם משפחה"
            className="field-strech"
            error={this.state.errors["lastName"] ? true : false}
            onChange={this.handleChange.bind(this, "lastName")}
            value={this.state.fields["lastName"] || ""}
          />
        </div>
        <div className="mt-10">
          <TextField
            label="דואר אלקטרוני"
            className="field-strech"
            error={this.state.errors["email"] ? true : false}
            onChange={this.handleChange.bind(this, "email")}
            value={this.state.fields["email"] || ""}
          />
        </div>
        <div className="mt-10">
          <TextField
            label="נושא"
            className="field-strech"
            error={this.state.errors["subject"] ? true : false}
            onChange={this.handleChange.bind(this, "subject")}
            value={this.state.fields["subject"] || ""}
          />
        </div>
        <div className="mt-10">
          <TextField
            label="כתוב לנו הודעה"
            multiline
            rows="3"
            className="field-strech"
            error={this.state.errors["message"] ? true : false}
            onChange={this.handleChange.bind(this, "message")}
            value={this.state.fields["message"] || ""}
          />
        </div>

        <div className="mt-30 right">
          <Button type="submit" color="primary" variant="contained">
            שלח
          </Button>
        </div>
      </form>
    );
  }
}
