import React, { Component } from "react";
import { Link } from "react-router-dom";
import agentsService from "../../../Services/agentsService";
import { connect } from "react-redux";
import { setAgentLoggedIn } from "../../../Actions/loggedinActions";
import { setLoaderOn } from "../../../Actions/appSettingActions";
import { Button, FormGroup, FormControlLabel, Checkbox, TextField, Card, CardContent } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";
import config from "../../../config";
import Swal from "sweetalert2";

class Login extends Component {
  state = {
    email: "",
    password: "",
    shouldRememberEmail: true,
    showConfMsg: false
  };

  constructor() {
    super();
    var emailForLogin = localStorage.getItem("emailForLogin");
    if (emailForLogin) this.state.email = emailForLogin;
    this.reRef = React.createRef();
  }

  render() {
    return (
      <div className="mt-50">
        <ReCAPTCHA sitekey={config.reCaptchaKey} size="invisible" ref={this.reRef} />

        <div className="card-width auto0">
          <Card>
            <CardContent className="login-card">
              <h4 className="right primary-color mb-40">התחבר למערכת</h4>
              <form>
                <div>
                  <TextField
                    required
                    label="אימייל"
                    className="field-strech"
                    type="email"
                    autoComplete="current-email"
                    id="email"
                    onChange={this.handleChange}
                    value={this.state.email || ""}
                  />
                </div>
                <div className="mb-40 mt-20">
                  <TextField
                    required
                    className="field-strech"
                    label="סיסמה"
                    type="password"
                    id="password"
                    onChange={this.handleChange}
                    onKeyPress={(e) => {
                      this.handleKeyPress(e.key);
                    }}
                  />
                </div>
                <div className="d-sm-flex justify-content-between align-items-center">
                  <FormGroup>
                    <FormControlLabel
                      className="remember-me-wrapper"
                      control={<Checkbox value="checkedA" color="primary" />}
                      label="זכור אותי"
                      onChange={this.toggleRememeberMe}
                      checked={this.state.shouldRememberEmail}
                    />
                  </FormGroup>
                  <span className="d-inline-block">
                    <Link to="/agents/forgot-password">לא זוכר את הסיסמה?</Link>
                  </span>
                </div>
              </form>

              <div className="right mt-20">
                <Button onClick={this.handleSubmit} color="primary" variant="contained" size="small">
                  התחבר
                </Button>
              </div>
              {this.state.showConfMsg ? (
                <div className="mt-20">
                  <Alert severity="warning">
                    לקוח יקר עליך לאשר את האימייל שלך על מנת להמשיך
                    <br />
                    <a href="#" onClick={this.resendEmailConf}>
                      במידה ולא קבלת אימייל מאיתנו ניתן ללחוץ כאן לשליחה נוספת
                    </a>
                  </Alert>
                </div>
              ) : null}
              {/* <div className="right mt-20">
                אין לך משתמש עדיין? <Link to="/agents/register">לחץ כאן ליצירת משתמש</Link>
              </div> */}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  toggleRememeberMe = (e) => {
    this.setState({ shouldRememberEmail: e.target.checked });
  };

  resendEmailConf = (e) => {
    e.preventDefault();
    if (!this.state.email) return;
    agentsService.resendEmailConf(this.state.email).then((data) => {
      if (data.success) {
        Swal.fire("", "אימייל נוסף לאישור נשלח", "success");
      } else if (data.isTooManyAttempts) {
        Swal.fire("", "ניסית לשלוח מייל עם אישור יותר מידי פעמים. ניתן לנסות שוב בעוד כמה שעות או להתקשר אלינו", "warning");
      } else if (data.err) {
        Swal.fire("", "משהו לא הסתדר. בבקשה נסה שוב מאוחר יותר", "warning");
      }
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      showConfMsg: false
    });
  };

  handleKeyPress = function (keyClicked) {
    if (keyClicked === "Enter") this.handleSubmit();
  };

  handleSubmit = async (e) => {
    if (!this.state.password || !this.state.email) {
      Swal.fire("כל השדות הינם חובה", "האימייל והסיסמה", "warning");
      return;
    }

    if (this.state.email.length < 4 || !this.state.email.includes("@")) {
      Swal.fire("", 'דוא"ל לא תקין', "warning");
      return;
    }

    if (this.state.password.length < 8) {
      Swal.fire("אימייל או סיסמה לא נכונים", "בבקשה נסה שנית", "warning");
      return;
    }

    var captchaToken = null;
    this.props.setLoaderOn();
    captchaToken = await this.reRef.current.executeAsync();
    this.reRef.current.reset();

    agentsService.agentLogin(this.state.email, this.state.password, captchaToken).then((res) => {
      if (res["success"]) {
        const role = res["role"];
        const permission = res["permission"];
        if (this.state.shouldRememberEmail) localStorage.setItem("emailForLogin", this.state.email);
        else localStorage.removeItem("emailForLogin");

        this.props.setAgentLoggedIn(res);

        if (this.isSpecialUser(role)) return this.props.history.push("/admin/agencies");
        if (role === "employee" && permission === "b2c") return this.props.history.push("/admin/visaManager");
        if (role === "employee" && permission === "b2b") return this.props.history.push("/admin/agencies");
        return this.props.history.push("/agents/profile");
      } else if (!res["userExist"]) {
        Swal.fire("אימייל או סיסמה לא נכונים", "בבקשה נסה שנית", "warning");
      } else if (!res["isEmailConfirmed"]) {
        this.setState({ showConfMsg: true });
      }
    });
  };

  isSpecialUser = function (role) {
    if (role === "admin" || role === "salesman" || role === "salesManager") return true;
    return false;
  };
}

const mapDispatchToProps = (dispatch, userLoginData) => {
  return {
    setAgentLoggedIn: (userLoginData) => dispatch(setAgentLoggedIn(userLoginData)),
    setLoaderOn: () => dispatch(setLoaderOn())
  };
};

export default connect(null, mapDispatchToProps)(Login);
