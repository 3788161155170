import React, { Component, Fragment } from "react";
import { Card, CardContent, FormControlLabel, TextField, Button, CardHeader } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import adminService from "../../../Services/adminService";
import AddNewFromTo from "./addNewFromTo";
import ExistingFromToOptFields from "./existingFromToOptFields";
import "../../../assets/css/fromToManager.css";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import employeeServices from "../../../Services/employeeService";

class FromToManager extends Component {
  state = {
    allFromTo: [],
    allPossibleFields: [],
    fromOnly: [],
    toOnly: [],
    visaTypeOnly: [],
    importanceLevel: "",
    visaPrice: "",
    visaService: "",
    visaPriceB2C: "",
    visaServiceB2C: "",
    visaTitle: "",
    stayAllowance: "",
    processTime: "",
    visaEntries: "",
    displayNameDestination: "",
    displayNameOrigin: "Israel / ישראל",
    fromSelected: null,
    toSelected: null,
    visaTypeSelected: null,
    selectedFromToTypeOpt: null,
    isOpen: false,
    freeTxt: "Insert html content for a new 'fromTo'"
  };

  componentDidMount() {
    employeeServices.getAllFromToOptsAndFields().then((res) => {
      if (res["success"]) {
        var allFromTo = res["allFromTo"];
        var allPossibleFields = res["allFields"].map((item) => item.key);
        var fromOnly = allFromTo.filter((v, i, a) => a.findIndex((t) => t.from === v.from) === i).map((item) => item.from);

        this.setState({ fromOnly, allFromTo, allPossibleFields });
      }
    });
  }

  render() {
    return (
      <div className="center ltr-layout mb-30 relative">
        <AddNewFromTo
          isOpen={this.state.isOpen}
          close={() => {
            this.setState({ isOpen: false });
          }}
          newFromTo={this.newFromTo}
        />
        <div className="new-from-to-btn">
          <Button
            onClick={() => {
              this.setState({ isOpen: true });
            }}
            color="primary"
            variant="contained"
          >
            New
          </Button>
        </div>
        <Card>
          <CardContent>
            <h4 className="yellow-color">From ~ To</h4>

            <div className="mt-50">
              <div className="width33 primary-color">
                <b>From</b>
                <div className="mt-20">
                  <Autocomplete
                    className="auto0"
                    // InputLabelProps={{ shrink: true }} // TODO - check this option if it can help
                    options={this.state.fromOnly}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="from" variant="outlined" />}
                    onChange={(event, newInputValue) => {
                      this.handleVisaChange({ target: { id: "fromSelected", value: newInputValue } });
                    }}
                  />
                </div>
              </div>
              <div className="width33 primary-color">
                <b>To</b>
                <div className="mt-20">
                  <Autocomplete
                    value={this.state.toSelected}
                    disabled={this.state.fromSelected ? false : true}
                    className="auto0"
                    options={this.state.toOnly}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="to" variant="outlined" />}
                    onChange={(event, newInputValue) => {
                      this.handleVisaChange({ target: { id: "toSelected", value: newInputValue } });
                    }}
                  />
                </div>
              </div>
              <div className="width33 primary-color">
                <b>visaType</b>
                <div className="mt-20">
                  <Autocomplete
                    value={this.state.visaTypeSelected}
                    disabled={this.state.toSelected ? false : true}
                    className="auto0"
                    options={this.state.visaTypeOnly}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="visa type" variant="outlined" />}
                    onChange={(event, newInputValue) => {
                      this.handleVisaChange({ target: { id: "visaTypeSelected", value: newInputValue } });
                    }}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {this.state.selectedFromToTypeOpt ? (
          <Fragment>
            <div className="delete-con-visa-opt">
              <button
                onClick={() => {
                  this.deleteVisaTypeOpt();
                }}
                className="warn-color"
              >
                <i className="material-icons">delete</i>
              </button>

              <div className="mt-70">
                <button
                  onClick={() => {
                    this.toggleIsVisaOptShown();
                  }}
                  className={`primary-color${this.state.selectedFromToTypeOpt.isHiddenVisaOpt ? " bg-light-blue" : ""}`}
                >
                  <i className="material-icons">ac_unit</i>
                </button>
              </div>
            </div>

            <div className="toggle-file-visa">
              <button
                onClick={() => {
                  this.toggleDownloadVisa(false);
                }}
                className={`${!this.state.selectedFromToTypeOpt.downloadVisa ? "background-success color-white" : ""}`}
              >
                <i className="material-icons">cloud_queue</i>
              </button>

              <button
                onClick={() => {
                  this.toggleDownloadVisa(true);
                }}
                className={`${this.state.selectedFromToTypeOpt.downloadVisa ? "background-success color-white" : ""}`}
              >
                <i className="material-icons">assignment_returned</i>
              </button>
            </div>

            <Fragment>
              <ExistingFromToOptFields
                selectedFromToTypeOpt={this.state.selectedFromToTypeOpt}
                allPossibleFields={this.state.allPossibleFields}
                handleFinalChanges={this.handleFinalChanges}
                attachFileForDownload={this.attachFileForDownload}
              />
              <div className="primary-color mt-30">
                <div className="inline-block mr-10">
                  <h5 className="center">Importance Level</h5>
                  <TextField id="importanceLevel" value={this.state.importanceLevel} onChange={this.handleOpenTxts} type="number" />
                </div>

                <div className="inline-block mr-10">
                  <h5 className="center">($)Visa Price</h5>
                  <TextField id="visaPrice" value={this.state.visaPrice} onChange={this.handleOpenTxts} type="number" />
                </div>

                <div className="inline-block mr-10">
                  <h5 className="center">($)Visa Service</h5>
                  <TextField id="visaService" value={this.state.visaService} onChange={this.handleOpenTxts} type="number" />
                </div>

                <div className="mt-50">
                  <div className="inline-block mr-10">
                    <h5 className="center">($) B2C - Visa Price</h5>
                    <TextField id="visaPriceB2C" value={this.state.visaPriceB2C} onChange={this.handleOpenTxts} type="number" />
                  </div>

                  <div className="inline-block mr-10">
                    <h5 className="center">($) B2C - Visa Service</h5>
                    <TextField id="visaServiceB2C" value={this.state.visaServiceB2C} onChange={this.handleOpenTxts} type="number" />
                  </div>
                </div>

                <div className="mt-50">
                  <div className="inline-block mr-10">
                    <h5 className="center">Visa Title</h5>
                    <TextField id="visaTitle" value={this.state.visaTitle} onChange={this.handleOpenTxts} />
                  </div>

                  <div className="inline-block mr-10">
                    <h5 className="center">Stay allowance(days)</h5>
                    <TextField id="stayAllowance" value={this.state.stayAllowance} onChange={this.handleOpenTxts} />
                  </div>

                  <div className="inline-block mr-10">
                    <h5 className="center">Process Time</h5>
                    <TextField id="processTime" value={this.state.processTime} onChange={this.handleOpenTxts} />
                  </div>
                </div>

                <div className="mt-50">
                  <div className="inline-block mr-10">
                    <h5 className="center">Amount of entries</h5>
                    <TextField id="visaEntries" value={this.state.visaEntries} onChange={this.handleOpenTxts} />
                  </div>

                  <div className="inline-block mr-10">
                    <h5 className="center">Display name Origin</h5>
                    <TextField id="displayNameOrigin" value={this.state.displayNameOrigin} onChange={this.handleOpenTxts} />
                  </div>

                  <div className="inline-block mr-10">
                    <h5 className="center">Display name destination</h5>
                    <TextField id="displayNameDestination" value={this.state.displayNameDestination} onChange={this.handleOpenTxts} />
                  </div>
                </div>

                <div className="mt-50">
                  <Editor
                    apiKey="mdyv1tvpdrpgk5qhtw8mkul15ant6kbb99qzxb83dxal9jeh"
                    value={this.state.freeTxt}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount"
                      ],
                      toolbar:
                        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons"
                    }}
                    onEditorChange={this.handleEditorChange}
                  />
                </div>
              </div>
            </Fragment>
          </Fragment>
        ) : null}
      </div>
    );
  }

  handleEditorChange = (freeTxt, editor) => {
    this.setState({ freeTxt });
  };

  toggleDownloadVisa = (isOn) => {
    var selectedFromToTypeOpt = { ...this.state.selectedFromToTypeOpt };
    selectedFromToTypeOpt.downloadVisa = isOn;
    this.setState({ selectedFromToTypeOpt });
  };

  deleteVisaTypeOpt = () => {
    Swal.fire({
      title: "Do you wish to delete the current opt?",
      text: "",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        const { fromSelected, toSelected, visaTypeSelected } = this.state;
        adminService.deleteFromToVisaTypeOpt(fromSelected, toSelected, visaTypeSelected).then((res) => {
          if (res["success"]) {
            Swal.fire("Done", "", "success").then(() => window.location.reload());
          }
        });
      }
    });
  };

  toggleIsVisaOptShown = () => {
    const title = this.state.selectedFromToTypeOpt.isHiddenVisaOpt ? "להחזיר ויזה לתצוגת לקוחות?" : "להסתיר ויזה מלקוחות?";
    Swal.fire({
      title,
      icon: "info",
      text: "",
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        const selectedFromToTypeOpt = { ...this.state.selectedFromToTypeOpt };
        selectedFromToTypeOpt.isHiddenVisaOpt = !selectedFromToTypeOpt.isHiddenVisaOpt;
        this.setState({ selectedFromToTypeOpt });
      }
    });
  };

  handleVisaChange = (e) => {
    const newState = {
      [e.target.id]: e.target.value,
      importanceLevel: "",
      visaPrice: "",
      visaService: "",
      visaPriceB2C: "",
      visaServiceB2C: ""
    };
    if (e.target.id === "fromSelected") {
      if (e.target.value) {
        // i need to filter toOnly
        var toOnly = this.state.allFromTo
          .filter((item) => item.from === e.target.value)
          .map((item) => item.to)
          .filter((item, pos, self) => self.indexOf(item) == pos);

        newState["toOnly"] = toOnly;
      } else {
        newState["toSelected"] = "";
        newState["visaTypeSelected"] = "";
      }
      newState["selectedFromToTypeOpt"] = null;
    } else if (e.target.id === "toSelected") {
      if (e.target.value) {
        var visaTypeOnly = this.state.allFromTo
          .filter((item) => item.to === e.target.value && item.from === this.state.fromSelected)
          .map((item) => item.visaType);
        newState["visaTypeOnly"] = visaTypeOnly;
      } else {
        newState["visaTypeSelected"] = "";
      }
      newState["selectedFromToTypeOpt"] = null;
    } else if (e.target.id === "visaTypeSelected") {
      if (e.target.value) {
        // get actual selected opt
        var selectedFromToTypeOpt = this.state.allFromTo.filter(
          (item) => item.from === this.state.fromSelected && item.to === this.state.toSelected && item.visaType === e.target.value
        );
        newState["selectedFromToTypeOpt"] = selectedFromToTypeOpt[0];
        newState["importanceLevel"] = selectedFromToTypeOpt[0].importanceLevel || "";
        newState["visaPrice"] = selectedFromToTypeOpt[0].visaPrice || "";
        newState["visaService"] = selectedFromToTypeOpt[0].visaService || "";
        newState["visaPriceB2C"] = selectedFromToTypeOpt[0].visaPriceB2C || "";
        newState["visaServiceB2C"] = selectedFromToTypeOpt[0].visaServiceB2C || "";
        newState["visaTitle"] = selectedFromToTypeOpt[0].visaTitle || "";
        newState["stayAllowance"] = selectedFromToTypeOpt[0].stayAllowance || "";
        newState["processTime"] = selectedFromToTypeOpt[0].processTime || "";
        newState["visaEntries"] = selectedFromToTypeOpt[0].visaEntries || "";
        newState["displayNameOrigin"] = selectedFromToTypeOpt[0].displayNameOrigin || "Israel / ישראל";
        newState["displayNameDestination"] = selectedFromToTypeOpt[0].displayNameDestination || "";
        newState["freeTxt"] = selectedFromToTypeOpt[0].freeTxt || "Insert html content for a new 'fromTo'";
      } else {
        newState["selectedFromToTypeOpt"] = null;
      }
    }

    this.setState(newState);
  };

  handleOpenTxts = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  newFromTo = (from, to, visaType) => {
    this.setState({ isOpen: false });
    for (const fromTo of this.state.allFromTo) {
      if (fromTo.from === from && fromTo.to === to && fromTo.visaType === visaType) {
        Swal.fire("", "You are trying to add an option that exists already.", "warning");
        return;
      }
    }
    // a new valid option
    adminService.createNewFromToOpt(from, to, visaType).then((res) => {
      if (res["success"]) {
        Swal.fire("", "New 'from' ~ 'to' option was added", "success");
      }
    });
  };

  attachFileForDownload = (file) => {
    adminService
      .attachFileForDownload(
        this.state.selectedFromToTypeOpt.from,
        this.state.selectedFromToTypeOpt.to,
        this.state.selectedFromToTypeOpt.visaType,
        file
      )
      .then((res) => {
        if (res["success"]) {
          Swal.fire("success", "", "success");
        }
      });
  };

  handleFinalChanges = (includedFieldsCopy) => {
    adminService
      .updateFromToVisaTypeFields({
        from: this.state.selectedFromToTypeOpt.from,
        to: this.state.selectedFromToTypeOpt.to,
        visaType: this.state.selectedFromToTypeOpt.visaType,
        fields: includedFieldsCopy,
        importanceLevel: this.state.importanceLevel,
        visaPrice: this.state.visaPrice,
        visaService: this.state.visaService,
        visaPriceB2C: this.state.visaPriceB2C,
        visaServiceB2C: this.state.visaServiceB2C,
        visaTitle: this.state.visaTitle,
        stayAllowance: this.state.stayAllowance,
        processTime: this.state.processTime,
        visaEntries: this.state.visaEntries,
        displayNameOrigin: this.state.displayNameOrigin,
        displayNameDestination: this.state.displayNameDestination,
        downloadVisa: this.state.selectedFromToTypeOpt.downloadVisa,
        isHiddenVisaOpt: this.state.selectedFromToTypeOpt.isHiddenVisaOpt,
        freeTxt: this.state.freeTxt
      })
      .then((res) => {
        if (res["success"]) {
          var allFromTo = [...this.state.allFromTo];
          var selectedFromToTypeOpt = { ...this.state.selectedFromToTypeOpt };
          selectedFromToTypeOpt.fields = includedFieldsCopy;

          for (let i = 0; i < allFromTo.length; i++) {
            if (
              allFromTo[i].from === this.state.selectedFromToTypeOpt.from &&
              allFromTo[i].to === this.state.selectedFromToTypeOpt.to &&
              allFromTo[i].visaType === this.state.selectedFromToTypeOpt.visaType
            ) {
              allFromTo[i].fields = includedFieldsCopy;
              break;
            }
          }

          this.setState({ allFromTo, selectedFromToTypeOpt });
          Swal.fire("", "Updated 'from' ~ 'to' fields", "success");
        }
      });
  };
}

export default FromToManager;
