import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MaterialTableComponent from "../../widgets/materialTable";
import adminService from "../../../Services/adminService";
import Swal from "sweetalert2";
import employeeServices from "../../../Services/employeeService";

class AgenciesManager extends Component {
  state = {
    searchKey: "",
    isFilterSearch: false,
    reloadingTable: false,
    agenciesList: [],
    amountOfUsersToReturn: 30,
    columns: [
      { title: "שם סוכנות", field: "agencyName" }, //, editable: "onAdd"

      {
        title: "סטטוס",
        field: "agencyStatus",
        lookup: {
          active: "פעיל",
          suspended: "מושהה"
        }
      },
      {
        title: "שובר",
        field: "isShovarRequired",
        lookup: {
          true: "כן",
          false: "לא"
        }
      },
      { title: "שם ראשי", field: "agencyContact" },
      { title: "מספר ראשי", field: "agencyMobile" },
      { title: "מייל ראשי", field: "agencyEmail" },
      { title: "אחוז רווח", field: "discount" }
    ],
    showSearchNotFoundMsg: false,
    allowedDelete: false
  };

  constructor() {
    super();
    if (localStorage.getItem("role") === "admin" || localStorage.getItem("role") === "processor") this.state.allowedDelete = true;
  }

  componentDidMount() {
    employeeServices.getAgenciesBySearchKey(this.state.searchKey).then((res) => {
      if (res["success"]) {
        var dataFromDB = res["agenciesList"];
        if (!dataFromDB || !dataFromDB[0]) {
          this.setState({ showSearchNotFoundMsg: true, agenciesList: [] }, () => {
            this.setState({ reloadingTable: false });
          });
          return;
        }
        dataFromDB = dataFromDB.map((item) => {
          item.isEmailConfirmed = item.isEmailConfirmed ? 1 : 0;
          return item;
        });
        this.setState({ agenciesList: dataFromDB }, () => {
          this.setState({ reloadingTable: false });
        });
      }
    });
  }

  render() {
    return (
      <div className="container pb-70 rtl-layout right">
        <h2 className="yellow-color">ניהול סוכנויות</h2>
        <div className="row">
          <div className="col-sm-12 mb-20">
            <TextField
              required
              variant="outlined"
              label="חיפוש לפי שם סוכנות"
              className="field-strech"
              id="searchKey"
              value={this.state.searchKey}
              onChange={this.handleSearchKeyChanged}
              onKeyPress={(e) => {
                this.handleKeyPress(e.key, this.handleSubmitSeacrh);
              }}
            />
            <div className="mt-20">
              <Button onClick={this.handleSubmitSeacrh} color="primary" variant="contained" size="small">
                חפש
              </Button>
            </div>
          </div>
        </div>
        <div className="row mt-50">
          <div className="user-table-con col-sm-12">
            {this.state.showSearchNotFoundMsg ? (
              <h4 className="primary-color mb-20">
                לא ניתן היה למצוא סוכנויות לפי מילת החיפוש שהוזנה:
                <br />
                (Key: {this.state.searchKey})
              </h4>
            ) : null}
            {this.state.reloadingTable ? (
              <h4 className="primary-color">טוען מידע...</h4>
            ) : (
              <div className="relative">
                <MaterialTableComponent
                  options={{ pageSizeOptions: [5, 10, 20, 100] }}
                  data={this.state.agenciesList}
                  columns={this.state.columns}
                  onDataChanged={this.handleDataChange}
                  redirectToSpecificUser={this.redirectToSpecificUser}
                  tableTitle="טבלת סוכנויות"
                  allowedDelete={this.state.allowedDelete}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleKeyPress = function (keyClicked, functionToTrigger) {
    if (keyClicked === "Enter") functionToTrigger();
  };

  handleSearchKeyChanged = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      showSearchNotFoundMsg: false
    });
  };

  handleDataChange = (dataToModify, action) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "האם אתה בטוח?",
        text: "אתה הולך לעשות את הפעולה הבאה על המשתמש: " + action,
        icon: "warning",
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          adminService.handleAgencyChanges(dataToModify, action).then((res) => {
            if (res["success"]) {
              if (res["newId"]) resolve(res["newId"]);
              else resolve();
              Swal.fire(`${action} - בוצע`, "", "success");
            } else {
              if (res["isExistingUser"]) {
                Swal.fire(`${action} - לא בוצע`, "קיים כבר משתמש", "warning");
              }
              reject();
            }
          });
        } else {
          reject();
        }
      });
    });
  };

  redirectToSpecificUser = (agencyData, e) => {
    if (e.ctrlKey) window.open("/admin/specific-agency/" + agencyData._id + "/" + agencyData.agencyName, "_blank");
    else this.props.history.push("/admin/specific-agency/" + agencyData._id + "/" + agencyData.agencyName);
  };

  handleSubmitSeacrh = (e) => {
    if (e) e.preventDefault();

    if (this.state.searchKey.length < 3) {
      if (isNaN(this.state.searchKey)) {
        Swal.fire("", "מינימום שלוש אותיות לחיפוש", "warning");
        return;
      }
    }

    this.setState({ showSearchNotFoundMsg: false, reloadingTable: true }, () => {
      employeeServices.getAgenciesBySearchKey(this.state.searchKey).then((res) => {
        if (res["success"]) {
          var dataFromDB = res["agenciesList"];
          if (!dataFromDB || !dataFromDB[0]) {
            this.setState({ showSearchNotFoundMsg: true, agenciesList: [] }, () => {
              this.setState({ reloadingTable: false });
            });
            return;
          }
          dataFromDB = dataFromDB.map((item) => {
            item.isEmailConfirmed = item.isEmailConfirmed ? 1 : 0;
            return item;
          });
          this.setState({ agenciesList: dataFromDB }, () => {
            this.setState({ reloadingTable: false });
          });
        }
      });
    });
  };
}

export default AgenciesManager;
