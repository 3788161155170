// Note: This table is built to display the visas, without much abstraction.

import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  IconButton,
  Box,
  Typography,
  TextField,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  MoreVert,
  MoreHoriz,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@material-ui/icons/";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#E3EFFD",
    color: "#004598",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    border: "5px solid #F3F9FF",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    border: 0,
  },
});

const PrettyTable = (props) => {
  const { columns, data, agencies, editRow } = props;
  const [formattedData, setFormattedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const dataToFormat = structuredClone(data); // stupid array of objects needs deep cloning or things mutate!
    const newData = dataToFormat.map((item) => {
      if (item.dateCreated)
        item.dateCreated = moment(item.dateCreated).format("DD[.]MM[.]YYYY");
      if (item.status) {
        const { lookup } = columns.filter((column) => column.lookup)[0];
        item.status = lookup[item.status];
      }
      if (item.belongToAgencyId) {
        const agency = agencies.filter(
          (agency) => agency._id === item.belongToAgencyId
        )[0];
        if (agency) item.belongToAgencyId = agency.agencyName;
        else item.belongToAgencyId = "אין שם סוכנות";
      }
      if (!item.belongToAgencyId && !item.createdByProcessorName) item.createdByProcessorName = "לקוח פרטי";
      if (!item.agentFullName) item.agentFullName = "לא משוייך לסוכן";
      return item;
    });
    
    setFormattedData(newData);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPage(0);
  }, [data, columns, agencies]);

  useEffect(() => {
    const total = Math.ceil(formattedData.length / rowsPerPage);
    const all = [];
    for (let i = 0; i < total; i++) {
      all.push(i);
    }
    setTotalPages(all);
  }, [rowsPerPage, formattedData]);

  useEffect(() => {
    if (totalPages[totalPages.length - 1] > 6) {
      const lastPages = totalPages.slice(totalPages.length - 3);
      setPaginationLinks([1, 2, 3, "break", ...lastPages.map((p) => p + 1)]);
    } else setPaginationLinks(totalPages.map((p) => p + 1));
  }, [totalPages]);

  const handleChangePage = (event, newPage) => {
    if (newPage < 0 || newPage > totalPages[totalPages.length - 1]) return;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <StyledTableCell key={column.field + i}>
                {column.title}
              </StyledTableCell>
            ))}
            <StyledTableCell>&nbsp;</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((dataItem) => (
              <StyledTableRow key={dataItem._id}>
                {columns.map((column, i) => (
                  <StyledTableCell key={dataItem._id + column.field + i}>
                    {dataItem[column.field]}
                  </StyledTableCell>
                ))}
                <StyledTableCell>
                  <IconButton
                    size="small"
                    onClick={() => editRow(dataItem._id)}
                  >
                    <MoreVert />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          direction: "rtl",
          justifyContent: "space-between",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            direction: "rtl",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography>רשומות בעמוד:</Typography>
          <TextField
            variant="outlined"
            select
            onChange={handleChangeRowsPerPage}
            size="small"
            value={rowsPerPage}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </TextField>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              direction: "ltr",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Tooltip arrow placement="top" title="לעמוד הקודם">
              <IconButton onClick={(e) => handleChangePage(e, page - 1)}>
                <KeyboardArrowLeftRounded />
              </IconButton>
            </Tooltip>
            {paginationLinks.map((number) =>
              number === "break" ? (
                <MoreHoriz key={number} />
              ) : (
                <Box
                  onClick={(e) => handleChangePage(e, number - 1)}
                  sx={{
                    cursor: "pointer",
                    color: page + 1 === number ? "#007AFF" : "black",
                  }}
                  key={"page" + number}
                >
                  {number}
                </Box>
              )
            )}
            <Tooltip arrow placement="top" title="לעמוד הבא">
              <IconButton onClick={(e) => handleChangePage(e, page + 1)}>
                <KeyboardArrowRightRounded />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography>
            רשומות: {page * rowsPerPage + rowsPerPage} - {page * rowsPerPage + 1} מתוך {formattedData.length}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            direction: "rtl",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Typography>לעבור לעמוד:</Typography>
          <TextField
            variant="outlined"
            select
            onChange={(e) => handleChangePage(e, e.target.value)}
            size="small"
            value={page}
          >
            {totalPages.map((pageNum) => (
              <MenuItem key={pageNum} value={pageNum}>
                {pageNum + 1}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    </TableContainer>
  );
};

export default PrettyTable;
