import React, { Component } from "react";
import { Link } from "react-router-dom";

// Material ui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Swal from "sweetalert2";
// import agentsService from "../../../Services/agentsService";

class Register extends Component {
  state = {
    email: "",
    password: "",
    passwordRepeat: "",
    officeName: "",
    firstName: "",
    mobile: "",
    erredData: {}
  };

  render() {
    return (
      <div className="row mt-50 pb-70">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <Card>
            <CardContent className="login-card">
              <h4 className="right primary-color mb-40">הרשמה למערכת</h4>
              <form>
                <div className="mb-40">
                  <TextField
                    required
                    className="field-strech"
                    label="שם משרד"
                    id="officeName"
                    onChange={this.handleChange}
                    error={this.state.erredData["officeName"] ? true : false}
                    helperText={this.state.erredData["officeName"] ? this.state.erredData["officeName"] : ""}
                  />
                </div>

                <div className="mb-40">
                  <TextField
                    required
                    label="אימייל"
                    className="field-strech"
                    type="email"
                    autoComplete="current-email"
                    id="email"
                    onChange={this.handleChange}
                    error={this.state.erredData["email"] ? true : false}
                    helperText={this.state.erredData["email"] ? this.state.erredData["email"] : ""}
                  />
                </div>
                <div className="mb-40">
                  <TextField
                    required
                    label="שם איש הקשר"
                    className="field-strech"
                    id="firstName"
                    onChange={this.handleChange}
                    error={this.state.erredData["firstName"] ? true : false}
                    helperText={this.state.erredData["firstName"] ? this.state.erredData["firstName"] : ""}
                  />
                </div>
                <div className="mb-40 mt-20">
                  <TextField
                    required
                    className="field-strech"
                    label="מספר טלפון"
                    id="mobile"
                    onChange={this.handleChange}
                    error={this.state.erredData["mobile"] ? true : false}
                    helperText={this.state.erredData["mobile"] ? this.state.erredData["mobile"] : ""}
                  />
                </div>
                <div className="mb-40 mt-20">
                  <TextField
                    required
                    className="field-strech"
                    label="סיסמה"
                    type="password"
                    id="password"
                    onChange={this.handleChange}
                    error={this.state.erredData["password"] ? true : false}
                    helperText={this.state.erredData["password"] ? this.state.erredData["password"] : ""}
                  />
                </div>
                <div className="mb-40 mt-20">
                  <TextField
                    required
                    className="field-strech"
                    label="אישור סיסמה"
                    type="password"
                    id="passwordRepeat"
                    onChange={this.handleChange}
                  />
                </div>
              </form>

              {/* {userLoginMsg} */}
              <div className="right mt-20">
                <Button onClick={this.handleSubmit} color="primary" variant="contained" size="small">
                  הרשמה
                </Button>
              </div>
              <div className="right mt-20">
                כבר יש לך משתמש קיים? <Link to="/agents/login">לחץ כאן להתחברות</Link>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  handleChange = (e) => {
    delete this.state.erredData[e.target.id];
    this.setState({
      [e.target.id]: e.target.value,
      erredData: this.state.erredData
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var getData = this.state;

    if (!getData.password || !getData.passwordRepeat || !getData.officeName || !getData.firstName || !getData.email || !getData.mobile) {
      Swal.fire("", "כל השדות הינם חובה.", "warning");
      return;
    }

    if (getData.password.length < 8) {
      Swal.fire("", "הסיסמה חייבת להיות בעלת 8 תוים לפחות", "warning");
      return;
    }

    if (getData.password !== getData.passwordRepeat) {
      Swal.fire("", "הסיסמה ואישור הסיסמה שהוזנו אינם תואמות", "warning");
      return;
    }

    // var newClientJson = {
    //   email: getData.email,
    //   password: getData.password,
    //   officeName: getData.officeName,
    //   firstName: getData.firstName,
    //   mobile: getData.mobile
    // };

    // ans["validatedData"]["isBtaxClient"] = this.props.isBtaxClient;
    // userService.addPendingUserClient(ans["validatedData"]).then((res) => {
    //   if (res["success"]) {
    //     Swal.fire(userCreatedTitle, userCreatedMsg, "success").then(() => {
    //       this.props.history.push("/login");
    //     });
    //   } else if (res["inPendingList"]) {
    //     Swal.fire(userExistsInPendingListTitle, userExistsInPendingListMsg, "warning");
    //   } else if (res["isEmailTaken"]) {
    //     Swal.fire("", userEmailExistsMsg, "warning");
    //   }
    // });
  };
}

export default Register;
