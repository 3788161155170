export default {
  FILLING: { statusKey: "FILLING", statusMsg: "עליך לסיים למלא את נתוני הויזה. לחץ כאן על מנת לעשות זאת" },
  AGENT_PAID_AND_FILLS: {
    statusKey: "AGENT_PAID_AND_FILLS",
    statusMsg: "עליך לסיים למלא את נתוני הויזה. לחץ כאן על מנת לעשות זאת"
  },
  AGENT_PAID_CLIENT_FILLS: {
    statusKey: "AGENT_PAID_CLIENT_FILLS",
    statusMsg: "עליך לסיים למלא את נתוני הויזה. לחץ כאן על מנת לעשות זאת"
  },
  WAITING_PROCESSOR: { statusKey: "WAITING_PROCESSOR", statusMsg: "הויזה התקבלה על ידינו לטיפול. תשמע מאיתנו בקרוב" }
};
