/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import config from "../config";
import validationService from "../Services/validation/validationService";

export default {
  tryConfEmailToken: (token, password) => {
    return new Promise((resolve) => {
      axios.post(config.confEmailEndpoint, { token, password }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  resetUserPass: (email, captchaToken) => {
    return new Promise((resolve) => {
      axios.post(config.resetUserPassEndpoint, { email, captchaToken }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  resendEmailConf: (email) => {
    return new Promise((resolve) => {
      axios.post(config.resendEmailConfEndpoint, { email }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  changePass: (password, token) => {
    return new Promise((resolve) => {
      axios.post(config.changeUserPassEndpoint, { password, token }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  agentLogin: (email, password, captchaToken) => {
    return new Promise((resolve) => {
      axios.post(config.loginAgentEndpoint, { email, password, captchaToken }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  getAgentVisas: () => {
    return new Promise((resolve) => {
      axios.get(config.getAgentVisasEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  getAgentSpecificVisa: (visaId) => {
    return new Promise((resolve) => {
      axios.get(config.getAgentSpecificVisaEndpoint + "?visaId=" + visaId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllFromToAndPrices: () => {
    return new Promise((resolve) => {
      axios.get(config.getAllFromToAndPricesEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  },

  getAgencyById: async () => {
    const serverRes = await axios.get(config.getAgencyByIdEndpoint);
    if (serverRes.data) return serverRes.data;
  },

  initNewAgentVisa: (data) => {
    return new Promise(async (resolve) => {
      const { from, to, visaType, selectedFile, whoFills, agentGivenName, city, street, houseNum, phoneForMessenger } = data;
      var fd;
      if (selectedFile) {
        fd = new FormData();
        fd.append("file", selectedFile, selectedFile.name);
      }

      let endPoint = selectedFile ? config.initNewAgentVisaEndpoint : config.initNewAgentVisaNoVoucherEndpoint;
      var link = `${endPoint}?from=${from}&to=${to}&visaType=${visaType}&whoFills=${whoFills}${
        data.sendMessenger ? "&sendMessenger=true" : ""
      }`;
      if (agentGivenName) link += "&agentGivenName=" + agentGivenName;
      if (city) link += `&city=${city}&street=${street}&houseNum=${houseNum}&phoneForMessenger=${phoneForMessenger}`;
      if (whoFills === "clientFills") {
        try {
          var emailValidated = await validationService.validateOneField("initEmail", data.clientEmail);
          if (data.clientPassportNum.length < 6) return resolve({ badPassportNum: true });
          link += "&agentInsertsEmail=" + emailValidated + "&agentInsertsPassportNum=" + data.clientPassportNum;
        } catch (validationErr) {
          return resolve({ badEmail: true });
        }
      }

      axios.post(link, fd).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        return resolve({ err: "Problem with the request" });
      });
    });
  }
};
