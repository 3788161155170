import React from "react";
import { Box, Dialog, DialogContent, Grid, IconButton, Typography, TextField, MenuItem, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { HighlightOff } from "@material-ui/icons";
import adminService from "../../../Services/adminService";

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  closeButton: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    textAlign: "center"
  }
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <HighlightOff />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FilterPopup = ({
  filters,
  setFilters,
  isOpen,
  onClose,
  agenciesList,
  destinations,
  onFiltersApply,
  searchDates,
  setSearchDates,
  clearFilters,
  isAgenciesTab,
  role,
  employeeList
}) => {
  const handleChangeFilters = (field, value) => {
    const newFilters = structuredClone(filters);
    newFilters[field] = value;
    setFilters(newFilters);
  };

  const handleChangeFilterDates = (field, date) => {
    const newDates = structuredClone(searchDates);
    newDates[field] = date;
    setSearchDates(newDates);
  };

  const handleSearch = () => {
    // search here
    onFiltersApply();
    onClose();
  };

  const handleClearFilters = () => {
    clearFilters();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>מסננים</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <h5>לפי תאריך</h5>
            <Box className="popup-form-row">
              <TextField
                className="end_picker"
                onChange={(e) => handleChangeFilterDates("end", e.target.value)}
                label="תאריך סיום"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={(searchDates && searchDates.end) || ""}
                fullWidth
              />
              <TextField
                className="start_picker"
                onChange={(e) => handleChangeFilterDates("start", e.target.value)}
                label="תאריך התחלה"
                type="date"
                variant="outlined"
                value={(searchDates && searchDates.start) || ""}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item>
            <h5>לפי סטטוס</h5>
            <TextField
              select
              className="status_selected"
              variant="outlined"
              label="סטטוס הויזה"
              value={filters.status || ""}
              onChange={(e) => handleChangeFilters("status", e.target.value)}
              fullWidth
            >
              {adminService.getAllStatuses().map((stat, i) => {
                return (
                  <MenuItem label={stat.label} value={stat.key} key={stat.key} style={{ direction: "rtl" }}>
                    {stat.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          {isAgenciesTab && (
            <Grid item>
              <h5>לפי סוכנות</h5>
              <Autocomplete
                multiple
                filterSelectedOptions
                options={agenciesList}
                onChange={(e, newVal) => handleChangeFilters("belongToAgencyId", newVal)}
                getOptionLabel={(option) => option?.agencyName ?? ""}
                getOptionSelected={(option, value) => option._id === value._id}
                value={filters.belongToAgencyId || []}
                renderInput={(params) => <TextField {...params} label="סוכנויות" variant="outlined" />}
              />
            </Grid>
          )}
          <Grid item>
            <h5>לפי יעד</h5>
            <Autocomplete
              options={destinations}
              onChange={(e, newVal) => handleChangeFilters("to", newVal)}
              getOptionLabel={(option) => option || ""}
              getOptionSelected={(option) => option}
              value={(filters && filters.to) || ""}
              renderInput={(params) => <TextField {...params} label="מדינות" variant="outlined" />}
            />
          </Grid>
          {role !== "employee" && (
            <Grid item>
              <h5>לפי מנהל</h5>
              <Autocomplete
                options={employeeList}
                onChange={(e, newVal) => handleChangeFilters("managedBy", newVal)}
                getOptionLabel={(option) => (option.agentFirstName ? `${option?.agentFirstName} ${option?.agentLastName}` : "")}
                getOptionSelected={(option) => option}
                value={filters.managedBy || {}}
                renderInput={(params) => <TextField {...params} label="מנוהל על ידי:" variant="outlined" />}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Box className="visas-popup-buttons mt-20">
            <Button variant="text" onClick={handleClearFilters}>
              נקה מסננים
            </Button>
            <Button variant="contained" onClick={handleSearch} className="light-button">
              חיפוש
            </Button>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FilterPopup;
