import { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Webcam from "react-webcam";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  }
}));

const videoConstraints = {
  facingMode: "user"
};

function TakeWebCamPicture({ takePictureType, setTakePictureType, savePictureFromCamera }) {
  const classes = useStyles();

  const [imgFile, setImageFile] = useState({});

  const webcamRef = useRef(null);

  const capture = () => {
    webcamRef.current.getCanvas().toBlob((blob) => {
      const fileName = takePictureType + ".png";
      const type = blob.type;
      const file = new File([blob], fileName, { type });
      setImageFile(file);
    });
  };

  const clearCapture = () => {
    setImageFile({});
  };

  const handleClose = () => {
    setTakePictureType(null);
    clearCapture();
  };

  const save = () => {
    savePictureFromCamera(takePictureType, imgFile);
    setTakePictureType(null);
    clearCapture();
  };

  return (
    <Modal open={Boolean(takePictureType)} className={classes.modal} onClose={handleClose}>
      <div className="webcam">
        {imgFile.name ? (
          <img src={URL.createObjectURL(imgFile)} alt="selfie" />
        ) : (
          <Webcam audio={false} ref={webcamRef} screenshotQuality={1} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />
        )}

        <div className="webcam-btn-container">
          {imgFile.name && (
            <button className="webcam-btn bg-green" onClick={save}>
              Save
            </button>
          )}
          {imgFile.name ? (
            <button className="webcam-btn bg-blue" onClick={clearCapture}>
              Retake
            </button>
          ) : (
            <button className="webcam-btn bg-green" onClick={capture}>
              Capture Photo
            </button>
          )}

          <button className="webcam-btn bg-red" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default TakeWebCamPicture;
