import React, { Component } from "react";
import visaService from "../../../../Services/visaService";
import { Button } from "@material-ui/core";
import config from "../../../../config";

const getUrl = (imageName) => `https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/b2c-homepage/${imageName}.jpg`;

class B2CPopularCard extends Component {
  render() {
    return (
      <div className="b2c-window-con background-white">
        <img src={getUrl(this.props.cardData.to)} alt={`View of ${this.props.cardData.to}`} className="b2c-cardImage" />

        <div className="width100 b2c-popularCardContentContainer">
          <div className="flex b2c-popularCardInformationContainer width100">
            <div className="d-flex justify-center right flex-column">
              <h5 className="center primary-color">
                <b>{visaService.upperCaseCountry(this.props.cardData.to)}</b>
              </h5>
              <p>זמין תוך 48 שעות</p>
            </div>

            <img
              className="b2c-country-flag"
              src={config.baseFlagUrl + this.props.cardData.to.replace(/ /g, "-") + "-flag.png"}
              alt={`${this.props.cardData.to} flag`}
            />
          </div>

          <div>
            <Button onClick={this.redirectUser} className="background-success color-white b2c-btn-search">
              הזמן
            </Button>
          </div>
        </div>
      </div>
    );
  }

  redirectUser = () => {
    this.props.history.push(`/visa-type/israel/${this.props.cardData.to}`);
  };
}

export default B2CPopularCard;
