import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoggedOut } from "../../../Actions/loggedinActions";
import { Button } from "@material-ui/core";

function NavLinksOnline(props) {
  const jsonLinks = [
    { menu_title: "מי אנחנו", path: "/about-us" },
    { menu_title: "צור קשר", path: "/contact-us" },
  ];

  const history = useHistory();
  const role = localStorage.getItem("role");

  return (
    <React.Fragment>
      <div className="inline-block color-white ml-20">
        {role ? (
          <div className="inline-block ml-10">
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={() => history.push("/admin/agencies")}
            >
              מנהל
            </Button>
          </div>
        ) : null}

        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={() => history.push("/agents/profile")}
        >
          סוכנים
        </Button>
      </div>
      {jsonLinks.map((NavLink, index) => (
        <Link
          key={index + "a"}
          className="color-white ml-20"
          to={NavLink.path}
          onClick={() => {
            if (props.closeSideBar) props.closeSideBar();
          }}
        >
          {ReactHtmlParser(NavLink.menu_title)}
        </Link>
      ))}
      <Link
        className="color-white ml-20"
        to="/"
        onClick={() => {
          props.setLoggedOut();
        }}
      >
        התנתק
      </Link>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch, userLoginData) => {
  return {
    setLoggedOut: (userLoginData) => dispatch(setLoggedOut(userLoginData)),
  };
};

export default connect(null, mapDispatchToProps)(NavLinksOnline);
