import React from "react";
import whyusImg from "../../../../assets/images/whyus-img.png";
import WhyUsTxt from "./whyUsTxt";

const WhyUsHome = () => {
  return (
    <div className="container mt-40 center">
      <h3 className="primary-color center heb-bold mb-40">למה אנחנו?</h3>
      <div className="home-width-90 auto0">
        <div className="whyus-img">
          <img src={whyusImg} />
        </div>
        <WhyUsTxt />
      </div>
    </div>
  );
};

export default WhyUsHome;
