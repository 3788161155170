import { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { displayMaritalStatus } from "./groupTableUtils";

const IndiaTable = ({ usersInfo, downloadFile, checkUser }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className="group-info-table">
        <TableHead className="group-info-table-head">
          <TableRow>
            <TableCell />
            <TableCell>#</TableCell>
            <TableCell>שם מלא</TableCell>
            <TableCell>אימייל</TableCell>
            <TableCell>טלפון</TableCell>
            <TableCell>תמונת דַרכּוֹן</TableCell>
            <TableCell>תמונה של הדַרכּוֹן</TableCell>
            <TableCell>הושלם</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersInfo.map((user, index) => (
            <Row key={user._id} userData={user} checkUser={checkUser} index={index} downloadFile={downloadFile} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = ({ userData, index, downloadFile, checkUser }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        <TableCell component="th" scope="row">
          {`${userData.firstName || ""} ${userData.lastName || ""}`}
        </TableCell>
        <TableCell>{userData.email}</TableCell>
        <TableCell>{userData.phone}</TableCell>
        <TableCell>
          <p className="link" onClick={() => downloadFile(userData.pictureOfPersonFileName)}>
            הורד תמונה
          </p>
        </TableCell>
        <TableCell>
          <p className="link" onClick={() => downloadFile(userData.pictureOfPassportFileName)}>
            הורד צילום דַרכּוֹן
          </p>
        </TableCell>
        <TableCell>
          <div className="custom-checkbox" onClick={() => checkUser(userData._id, userData.checked, index)}>
            {userData.checked ? <div className="check">&#10004;</div> : ""}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="user details" width="100%" className="group-info-sub-table">
              <TableHead className="group-info-sub-table-head">
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>כתובת</TableCell>
                  <TableCell>מקום לידה</TableCell>
                  <TableCell>אב</TableCell>
                  <TableCell>אם</TableCell>
                  <TableCell>מצב משפחתי</TableCell>
                  <TableCell>מקצועי</TableCell>
                  <TableCell>עיר כניסה להודו</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell>
                  <Box className="group-info-bold-grey">רחוב ובית:</Box>
                  <Box>{userData.streetAddress}</Box>
                  <Box className="group-info-bold-grey">עיר:</Box>
                  <Box>{userData.currentCity}</Box>
                  </TableCell>
                  <TableCell>
                  <Box className="group-info-bold-grey">עיר לידה:</Box>
                    <Box>{userData.birthCity}</Box>
                    <Box className="group-info-bold-grey">ארץ לידה:</Box>
                    <Box>{userData.birthCountry}</Box>
                  </TableCell>
                  <TableCell>
                    <Box className="group-info-bold-grey">שם:</Box>
                    <Box>{userData.fatherName}</Box>
                    <Box className="group-info-bold-grey">עיר לידה:</Box>
                    <Box>{userData.fatherBirthCity}</Box>
                    <Box className="group-info-bold-grey">ארץ לידה:</Box>
                    <Box>{userData.fatherBirthCountry}</Box>
                  </TableCell>
                  <TableCell>
                    <Box className="group-info-bold-grey">שם:</Box>
                    <Box>{userData.motherName}</Box>
                    <Box className="group-info-bold-grey">עיר לידה:</Box>
                    <Box>{userData.motherBirthCity}</Box>
                    <Box className="group-info-bold-grey">ארץ לידה:</Box>
                    <Box>{userData.motherBirthCountry}</Box>
                  </TableCell>
                  <TableCell>
                    <p>{displayMaritalStatus(userData.maritalStatus)}</p>
                    {userData.spouseName && (
                      <p>
                        <Box className="group-info-bold-grey">שם בן/בת הזוג:</Box>
                        {userData.spouseName}
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box className="group-info-bold-grey">מקצוע:</Box>
                    <Box>{userData.occupation}</Box>
                    <Box className="group-info-bold-grey">מעסיק / חברה:</Box>
                    <Box>{userData.employerName}</Box>
                    <Box className="group-info-bold-grey">כתובת:</Box>
                    <Box>
                      {userData.employerAddress}
                      <br />
                      {userData.employerCity}
                    </Box>
                  </TableCell>
                  <TableCell>{userData.portOfEntry}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default IndiaTable;
