import React from "react";
import service1img from "../../../assets/images/step1_icon.png";
import service2img from "../../../assets/images/step2_icon.png";
import service3img from "../../../assets/images/step3_icon.png";
import service4img from "../../../assets/images/step4_icon.png";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import laptopIcon from "../../../assets/images/laptop-icon.png";
import ticketIcon from "../../../assets/images/ticket.png";
import flightsIcon from "../../../assets/images/flights.png";
import callIcon from "../../../assets/images/call.png";
import config from "../../../config";

const WhoServiceFor = () => {
  return (
    <div className="light-gray-background mt-50 pt-30 pb-50">
      {config.isWebsiteB2C ? (
        <div className="container">
          <div className="primary-color center title-text-b2c mb-40">למי מתאים השירות?</div>
          <div className="home-width-90 auto0">
            <div>
              <div className="who-service-con center">
                <div className="primary-color service-text">סוכני נסיעות</div>
                <img src={laptopIcon} className="service-icons" />
              </div>
              <div className="who-service-con center">
                <div className="primary-color service-text">חברות הייטק</div>
                <img src={ticketIcon} className="service-icons" />
              </div>
              <div className="who-service-con center">
                <div className="primary-color service-text">אנשי עסקים</div>
                <img src={flightsIcon} className="service-icons" />
              </div>
              <div className="who-service-con center">
                <div className="primary-color service-text">מטיילים עצמאיים</div>
                <img src={callIcon} className="service-icons" />
              </div>
            </div>

            <div className="center mt-50">
              <div className="increased-btn-size-b2c inline-block ml-20" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                <Button className="primary-background color-white">
                  <div className="large-text"> ויזה אונלין</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h3 className="primary-color center heb-bold mb-40">למי השירות שלנו מיועד</h3>
          <div className="home-width-90 auto0">
            <div>
              <div className="who-service-con center">
                <h5 className="primary-color center heb-bold">סוכני נסיעות</h5>
                <img src={service1img} />
              </div>
              <div className="who-service-con center">
                <h5 className="primary-color center heb-bold">חברות הייטק</h5>
                <img src={service2img} />
              </div>
              <div className="who-service-con center">
                <h5 className="primary-color center heb-bold">אנשי עסקים</h5>
                <img src={service3img} />
              </div>
              <div className="who-service-con center">
                <h5 className="primary-color center heb-bold">מטיילים עצמאיים</h5>
                <img src={service4img} />
              </div>
            </div>

            <div className="center mt-30">
              <div className="increased-btn-size inline-block ml-20">
                <Button className="primary-background color-white">ויזה אונלין</Button>
              </div>

              <div className="increased-btn-size inline-block mr-20">
                <Link to="/agents/login">
                  <Button className="background-success color-white">כניסת סוכנים</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhoServiceFor;
