import React, { Component } from "react";
import PopularCard from "./popularCard";

class PopularContainer extends Component {
  state = {
    populars: [],
    originalPopulars: [],
    curIndex: 0
  };

  componentDidMount() {
    var populars = this.props.allFromTo.sort((a, b) => {
      if (!a.importanceLevel) return 1;
      if (!b.importanceLevel) return -1;
      return a.importanceLevel < b.importanceLevel ? 1 : -1;
    });

    populars = populars.filter((item) => item.importanceLevel);
    populars = populars.filter((v, i, a) => a.findIndex((t) => t.to === v.to) === i);

    if (populars.length > 8) populars = populars.slice(0, 8);
    this.setState({ originalPopulars: populars, populars: populars.slice(0, 4) });
  }

  render() {
    return (
      <div className="container">
        <div className="home-width-90 auto0 mt-70">
          <h3 className="primary-color center mb-20 heb-bold">
            <b>המדינות הפופולריות</b>
          </h3>

          <div className="center relative">
            <div
              onClick={() => {
                this.onArrowClicked("right");
              }}
              className="right-arrow"
            >
              <i className="material-icons">arrow_forward_ios</i>
            </div>
            {this.state.populars.map((curPopular, index) => {
              return <PopularCard key={curPopular.to} cardData={curPopular} history={this.props.history} />;
            })}
            <div
              onClick={() => {
                this.onArrowClicked("left");
              }}
              className="left-arrow"
            >
              <i className="material-icons">arrow_back_ios</i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onArrowClicked = (side) => {
    var amountOfCardsShown = this.getAmountOfShownCards();

    var curIndex = this.state.curIndex;
    var originalPopulars = this.state.originalPopulars;
    var populars;
    var totalListLength = Math.min(originalPopulars.length, 8);
    if (side === "right") {
      curIndex += 1;
      if (curIndex === totalListLength) curIndex = 0;
    } else {
      curIndex -= 1;
      if (curIndex < 0) curIndex = totalListLength - 1;
    }

    populars = originalPopulars.slice(curIndex, curIndex + amountOfCardsShown);
    if (populars.length < amountOfCardsShown && originalPopulars.length > amountOfCardsShown) {
      var missingItems = amountOfCardsShown - populars.length;
      populars = populars.concat(originalPopulars.slice(0, missingItems));
    }

    this.setState({ populars, curIndex });
  };

  getAmountOfShownCards = () => {
    if (window.innerWidth >= 1200) return 4;
    if (window.innerWidth >= 768) return 3;
    if (window.innerWidth >= 407) return 2;
    return 1;
  };
}

export default PopularContainer;
