import React, { Component } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

class B2CHomeFAQ extends Component {
  render() {
    return (
      <div className="container mt-40">
        <h3 className="primary-color heb-bold center mb-30">שאלות נפוצות</h3>

        <div className="faq-container auto0">
          <div className="inline-block width100 rtl-layout relative">
            <Accordion className="secundary-background padding5">
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<ExpandMore className="primary-color" />}
              >
                <span className="heb-bold primary-color b2c-weight600">אילו סוגי ויזה מונפקים באמצעותכם?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">ויזות אלקטרוניות לדרכונים ישראליים וליותר מ-30 מדינות.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="secundary-background padding5">
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<ExpandMore className="primary-color" />}
              >
                <span className="heb-bold primary-color b2c-weight600">תוך כמה זמן מקבלים ויזה?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">תלוי בסוג הויזה וביעד המבוקש. אנו מתחייבים לפרק הזמן הקצר ביותר ככל האפשר.</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="secundary-background padding5">
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<ExpandMore className="primary-color" />}
              >
                <span className="heb-bold primary-color b2c-weight600">מה קורה אם אני לא מסתדר?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">לחברה מענה טלפוני וצוות מקצועי אשר ישמח לתת מענה ושירות אישי לכל אורך הדרך</div>
              </AccordionDetails>
            </Accordion>
            <Accordion className="secundary-background padding5">
              {/* expanded={this.state.isCheckedList[index].isExtaDataOpen} */}
              <AccordionSummary
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                expandIcon={<ExpandMore className="primary-color" />}
              >
                <span className="heb-bold primary-color b2c-weight600">האם יש שינוי במדיניות הויזות עקב הקורונה?</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inner-accordion">
                  אכן יש מדינות מסוימות ששינו את מדיניות קבלת הויזה, החברה מעודכנת ומעדכנת את כלל לקוחותיה במדיניות החדשה.
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

export default B2CHomeFAQ;
