import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button } from "@material-ui/core";

class OriginDestination extends Component {
  state = { fromSelected: { from: "israel", displayNameOrigin: "Israel / ישראל" }, toOnly: [] };

  componentDidMount() {
    this.handleChange({
      target: {
        id: "fromSelected",
        value: {
          from: "israel",
          displayNameOrigin: "Israel / ישראל"
        }
      }
    });
  }

  render() {
    return (
      <div className="container">
        <div className="home-width-90 auto0 relative align-banner-height">
          <div className="origin-dest-wrapper primary-background">
            <h3 className="color-white center mb-20 heb-bold">חפש את היעד שלך</h3>
            <div className="inline-block origin-dest ml-10">
              <Autocomplete
                // defaultValue={this.state.fromSelected || null}
                value={this.state.fromSelected}
                className="autocomplete-white"
                options={this.props.fromOnly}
                getOptionLabel={(option) => option.displayNameOrigin}
                renderInput={(params) => <TextField {...params} label="מוצא" size="small" variant="outlined" />}
                onChange={(event, newInputValue) => {
                  this.handleChange({ target: { id: "fromSelected", value: newInputValue } });
                }}
              />
            </div>
            <div className="inline-block origin-dest">
              <Autocomplete
                value={this.state.toSelected || null}
                className="autocomplete-white"
                options={this.state.toOnly}
                getOptionLabel={(option) => option.displayNameDestination || option.to}
                disabled={this.state.fromSelected ? false : true}
                renderInput={(params) => <TextField {...params} label="יעד" size="small" variant="outlined" />}
                onChange={(event, newInputValue) => {
                  this.handleChange({ target: { id: "toSelected", value: newInputValue } });
                }}
              />
            </div>
            <div className="inline-block vertical-7">
              <Button className="background-success color-white btn-search" onClick={this.navigateToFromToPage}>
                חפש
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  navigateToFromToPage = () => {
    // this.props.history.push(`/agents/login`);
    if (this.state.fromSelected && this.state.toSelected) {
      this.props.history.push(`/visa-type/${this.state.fromSelected.from}/${this.state.toSelected.to}`);
    }
  };

  handleChange = (e) => {
    var newState = { [e.target.id]: e.target.value };
    if (e.target.id === "fromSelected") {
      if (e.target.value) {
        // i need to filter toOnly
        const toOnly = this.props.allFromTo
          .filter((item) => item.from === e.target.value.from)
          .filter((v, i, a) => a.findIndex((t) => t.to === v.to) === i);

        toOnly.sort((a, b) => (a.to > b.to ? 1 : -1));

        newState["toOnly"] = toOnly;
      } else {
        newState["toSelected"] = "";
      }
    }

    this.setState(newState);
  };
}

export default OriginDestination;
