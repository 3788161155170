import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import config from "../../../config";

function NavLinksOffline(props) {
  const jsonLinks = [
    { menu_title: "בית", path: "/" },
    { menu_title: "מי אנחנו", path: "/about-us" },
    { menu_title: "איתור ויזה", path: "/retrieve-visa" },
    { menu_title: "צור קשר", path: "/contact-us" }
  ];
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="inline-block color-white ml-20">
        {!config.isWebsiteB2C && (
          <Button variant="contained" color="secondary" style={{ color: "white" }} onClick={() => history.push("/agents/login")}>
            סוכנים
          </Button>
        )}
      </div>
      {jsonLinks.map((NavLink, index) => (
        // <Button key="index" component={Link} onClick={props.closeDrawer}>
        <Link
          key={index + "a"}
          className={"ml-20" + (config.isWebsiteB2C ? " primary-color" : " color-white")}
          to={NavLink.path}
          onClick={() => {
            if (props.closeSideBar) props.closeSideBar();
          }}
        >
          {ReactHtmlParser(NavLink.menu_title)}
        </Link>
        // </Button>
      ))}
      {/* <li className="fix-inner-a-line-height">
          <a onClick={props.scrollToHowItWorks}>{props.pageTxt.howItWorks}</a>
        </li> */}
    </React.Fragment>
  );
}

export default NavLinksOffline;
