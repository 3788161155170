//action types
import { SET_LANGUAGE, SET_LOADER_ON, SET_LOADER_OFF } from "../Actions/actionTypes";

//app config
import config from "../config";

const INITIAL_STATE = {
  currentLang: config.defaultLang,
  loaderOn: false,
  visaToEdit: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        currentLang: {
          langKey: action.payload.locale,
          layoutDir: action.payload.dir,
          langIcon: action.payload.icon
        }
      };
    case SET_LOADER_ON:
      return { ...state, loaderOn: true };
    case SET_LOADER_OFF:
      return { ...state, loaderOn: false };

    default:
      return { ...state };
  }
};
