module.exports = {
  minErr: "לפחות %replace% תוים",
  maxErr: "תוים %replace% מקסימום",
  emailErr: 'פורמט דוא"ל לא תקין',
  onlyEngErr: "ניתן להזין רק אותיות באנגלית",
  tooEarlyDateErr: "תאריך חייב להיות אחרי - %replace%",
  tooLateDateErr: "תאריך חייב להיות לפני - %replace%",
  smallNumErr: "מספר חייב להיות גדול מ - %replace%",
  bigNumErr: "מספר חייב להיות קטן מ - %replace%"
};
