import { useParams } from 'react-router-dom'
import MoroccoForm from "../groupForm/MoroccoForm";
import IndiaForm from '../groupForm/IndiaForm';

const RedirectForm = () => {
  const { destination } = useParams();
  
  if (destination && destination.toLowerCase() === 'india') return <IndiaForm />;
  if (destination && destination.toLowerCase() === "morocco")
    return <MoroccoForm />;
  else return <MoroccoForm />;
}

export default RedirectForm