import React, { useEffect, useState } from "react";
import visaService from "../../Services/visaService";
import { Button, Box } from "@material-ui/core";
import b2cLogoBlue from "../../assets/images/logo-b2c-blue.svg";
import "../../assets/css/paymentIframe.css";
import Swal from "sweetalert2";

export default function PayVisaById(props) {
  const { visaId, to, visaType, fullName, mobile, passportNum, isPaymentFailedFromFlow } = props.match.params;

  const [paymentIframeURL, setPaymentIframeURL] = useState("");
  const [isPaymentFailed, setIsPaymentFailed] = useState(isPaymentFailedFromFlow ? true : false);

  useEffect(() => {
    visaService.checkIsVisaPaid(visaId).then((res) => {
      if (!res.success || !res.isVisaFound) {
        Swal.fire("לא יכולנו למצוא את הויזה", "ניתן ליצור איתנו קשר אם יש שאלות נוספות", "warning").then(() => props.history.push("/"));
      } else if (res.isVisaPaid) {
        Swal.fire("תשלום עבור הויזה כבר בוצע", "ניתן ליצור איתנו קשר אם יש שאלות נוספות", "success").then(() => props.history.push("/"));
      }
    });

    window.addEventListener("message", handlePayment);
    return () => window.removeEventListener("message", handlePayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIframeToken = async (paymentType) => {
    setIsPaymentFailed(false);

    // { id, to, visaType, fullName, mobile, paymentType }
    const data = await visaService.getPaymentIframeURL({
      visaId,
      visaType,
      to,
      paymentType,
      fullName,
      mobile,
      passportNum
    });
    if (data) {
      setPaymentIframeURL(data.url);
    }
  };

  const handlePayment = (result) => {
    if (
      result.origin === "https://meshulam.co.il" ||
      result.origin === "https://sandbox.meshulam.co.il" ||
      result.origin === "https://secure.meshulam.co.il"
    ) {
      switch (result.data.action) {
        case "close": {
          document.getElementsByTagName("iframe")[0].style.setProperty("display", "none");
          break;
        }
        case "payment": {
          if (result.data.status === 1) {
            // success
            props.history.push(`/finished-visa/${visaId}/${passportNum}/${fullName}/israel/${to}`);
          } else {
            setIsPaymentFailed(true);
            setPaymentIframeURL("");
          }
          break;
        }
        case "failed_to_load_page": {
          break;
        }
        default: // nothing
          break;
      }
    }
  };

  return (
    <div>
      <div className="payment-logo-container">
        <img className="payment-logo" src={b2cLogoBlue} alt="logo" />
      </div>
      {isPaymentFailed ? (
        <div className="primary-color mb-40">
          <h4 className="center">התשלום נכשל</h4>
          <h5 className="center">יש לנסות בשנית</h5>
        </div>
      ) : null}
      {paymentIframeURL ? (
        <iframe className="mt-10" id="meshulam-iframe" width="100%" height="500px" src={paymentIframeURL} title="Secure payment" />
      ) : (
        <Box sx={{ display: "flex", flexFlow: "column", gap: "2rem", margin: "0 auto", alignItems: "center", marginBottom: "2rem" }}>
          <h3 className="center mb-0">נא לבחור אמצעי תשלום:</h3>
          <Button variant="outlined" onClick={() => getIframeToken("credit")} id="credit-button-pay">
            אשראי
          </Button>
          <Button variant="outlined" onClick={() => getIframeToken("bit")} id="bit-button-pay">
            bit
          </Button>
        </Box>
      )}
    </div>
  );
}
