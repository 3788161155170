import React, { Component } from "react";
import homeBanner from "../../../assets/images/home-banner.jpg";
import homeBannerSM from "../../../assets/images/home-banner-sm.jpg";
import VideoManager from "../../videoPopup/VideoManager";

class HomeBanner extends Component {
  render() {
    return (
      <div className="relative">
        <img className="home-banner-img" src={homeBanner} />
        <img className="home-banner-img-sm" src={homeBannerSM} />

        <div className="banner-text">
          <div className="container primary-color right fs-32">
            <b>הזמן שלך יקר מקליקים ומנפיקים ויזה אונליין</b>

            <div className="mt-20">
              <VideoManager
                title=" ? How to get started"
                btn_logo="▶"
                btn_title="איך מתחילים "
                video_link="https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/Registration.mp4"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeBanner;
