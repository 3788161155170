export default {
  txt: "txt",
  password: "password",
  date: "date",
  number: "number",
  numberWithCommas: "numberWithCommas",
  selectRange: "selectRange",
  selectOptions: "selectOptions",
  textarea: "textarea",
  checkbox: "checkbox",
  radio: "radio",
  /////
  radioBtns: "radioBtns",
  radioCircles: "radioCircles",
  scroller: "scroller",
  agreeToTerms: "agreeToTerms",
  autocomplete: "autocomplete",
  countriesAutocomplete: "countriesAutocomplete",
  msgToClient: "msgToClient",
  uploadFile: "uploadFile",
  payment: "payment",
  termsForPayment: "termsForPayment",
};
